import { EditorTool, selectedVideoFileAtom } from '../atoms/appAtoms';
import { getDefaultStore } from 'jotai/vanilla';
import { videoFileOpenDialogAtom } from '../atoms/dialogAtom';
import { convertImageFrame } from './frame';
import { createElement, getElementTemplate } from './element';
import { Rect } from './shape';
import { ImageElement, frameInfosAtom } from '../atoms/frameAtoms';
import { clamp } from 'lodash';

const store = getDefaultStore();

export const openFile = (evt: Event) => {
  const input = evt.target as HTMLInputElement;

  if (input && input.files && input.files[0]) {
    const file = input.files[0];

    const { type } = file;
    if (type.startsWith('image')) {
      convertImageFrame(file);
    } else if (type.startsWith('video')) {
      store.set(selectedVideoFileAtom, file);
      store.set(videoFileOpenDialogAtom, true);
    }
  }
};

export const estimateGifFileSize = (
  width: number,
  height: number,
  frameCount: number,
  quality = 10, // 1이 최고 품질 (파일 크기 작음), 20이 최저 품질 (파일 크기 큼)
  colorCount = 256
) => {
  if (quality < 1 || quality > 1000) {
    throw new Error('Quality must be between 1 and 1000');
  }

  // 기본 용량 계산
  const headerSize = 13; // GIF 헤더 크기
  const colorTableSize = colorCount * 3; // 색상 테이블 (RGB)
  const frameSize = width * height * 3 + headerSize; // 각 프레임 크기 (추정)

  // 총 스토리지, 최종 용량
  const totalSize = headerSize + colorTableSize + frameSize * frameCount;

  // 품질 보정 (가정: 낮은 품질일수록 비율이 증가하므로)
  const adjustedSize = totalSize * (1 + (1000 - quality) / 1000);

  // MB로 변환
  return adjustedSize;
};

export const handleAttachImageElement = (evt: Event) => {
  const input = evt.target as HTMLInputElement;

  const frameInfos = store.get(frameInfosAtom);

  if (input && input.files && input.files[0]) {
    const file = input.files[0];

    const img = new Image();
    const reader = new FileReader();

    img.onload = () => {
      const { width, height } = frameInfos[0].canvas;

      const widthRate = img.width / width;
      const heightRate = img.height / height;

      let scale = 1;

      if (widthRate > heightRate) {
        const wr = clamp(widthRate, 0.5);
        scale = (width * wr) / img.width;
      } else {
        const hr = clamp(heightRate, 0.5);
        scale = (height * hr) / img.height;
      }

      const boundingRect = [
        width / 2 - (img.width * scale) / 2,
        height / 2 - (img.height * scale) / 2,
        img.width * scale,
        img.height * scale,
      ] as Rect;

      const element = getElementTemplate(EditorTool.Image, boundingRect);

      (element as ImageElement).image = img;

      createElement(element);
    };

    reader.onload = function (e) {
      if (e.target && e.target.result) {
        img.src = e.target.result as string;
      }
    };

    reader.readAsDataURL(file);
  }
};

export const handleAttachImageFile = (file: File) => {
  const frameInfos = store.get(frameInfosAtom);

  const img = new Image();
  const reader = new FileReader();

  img.onload = () => {
    const { width, height } = frameInfos[0].canvas;

    const widthRate = img.width / width;
    const heightRate = img.height / height;

    let scale = 1;

    if (widthRate > heightRate) {
      const wr = clamp(widthRate, 0.5);
      scale = (width * wr) / img.width;
    } else {
      const hr = clamp(heightRate, 0.5);
      scale = (height * hr) / img.height;
    }

    const boundingRect = [
      width / 2 - (img.width * scale) / 2,
      height / 2 - (img.height * scale) / 2,
      img.width * scale,
      img.height * scale,
    ] as Rect;

    const element = getElementTemplate(EditorTool.Image, boundingRect);

    (element as ImageElement).image = img;

    createElement(element);
  };

  reader.onload = function (e) {
    if (e.target && e.target.result) {
      img.src = e.target.result as string;
    }
  };

  reader.readAsDataURL(file);
};
