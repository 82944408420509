import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  EditorTool,
  activeToolAtom,
  isPreviewOpenAtom,
  mainMenuStatusAtom,
} from '../atoms/appAtoms';

import '../styles/OptionDialog.scss';
import { MouseEvent, TouchEvent, useCallback, useRef, useState } from 'react';
import {
  EditorColor,
  EditorFontFamily,
  EditorFontSize,
  selectedStrokeColorAtom,
  selectedFontFamilyAtom,
  selectedFontSizeAtom,
  selectedLineWidthAtom,
  selectedOpacityAtom,
  synchronizedCropScaleAtom,
  selectedRoughnessAtom,
  selectedBowingAtom,
  selectedFillStyleAtom,
  selectedFillWeightAtom,
  selectedHachureAngleAtom,
  selectedHachureGapAtom,
  FillStyle,
  selectedFillColorAtom,
  selectedRoundnessAtom,
  Roundness,
  synchronizedFrameIntervalAtom,
} from '../atoms/optionAtom';
import { frameInfosAtom, selectedElementsAtom } from '../atoms/frameAtoms';
import {
  updateElementBowing,
  updateElementFillColor,
  updateElementFillStyle,
  updateElementFillWeight,
  updateElementHachureAngle,
  updateElementHachureGap,
  updateElementLineWidth,
  updateElementOpacity,
  updateElementRoughness,
  updateElementRoundness,
  updateElementStrokeColor,
} from '../utils/element';
import { Button, Checkbox, Slider } from '@mui/material';
import { Select } from 'semantic-ui-react';
import { uniq } from 'lodash';
import {
  moveToFirstFrame,
  moveToLastFrame,
  moveToNextFrame,
  moveToPrevFrame,
} from '../utils/frame';
import ArtifySelect from '../shared/ArtifySelect';

const OptionDialog = () => {
  const activeTool = useAtomValue(activeToolAtom);

  const mainMenuStatus = useAtomValue(mainMenuStatusAtom);

  const selectedElements = useAtomValue(selectedElementsAtom);

  const [frameInfos, setFrameInfos] = useAtom(frameInfosAtom);

  const [synchronizedPositionInfo, setSynchronizedScaleInfo] = useAtom(
    synchronizedCropScaleAtom
  );

  const [selectedStrokeColor, setSelectedStrokeColor] = useAtom(
    selectedStrokeColorAtom
  );
  const [selectedFillColor, setSelectedFillColor] = useAtom(
    selectedFillColorAtom
  );

  const [selectedLineWidth, setSelectedLineWidth] = useAtom(
    selectedLineWidthAtom
  );

  const [selectedFontFamily, setSelectedFontFamily] = useAtom(
    selectedFontFamilyAtom
  );

  const [selectedFontSize, setSelectedFontSize] = useAtom(selectedFontSizeAtom);

  const [selectedRoughness, setSelectedRoughness] = useAtom(
    selectedRoughnessAtom
  );
  const [selectedBowing, setSelectedBowing] = useAtom(selectedBowingAtom);
  const [selectedFillStyle, setSelectedFillStyle] = useAtom(
    selectedFillStyleAtom
  );
  const [selectedFillWeight, setSelectedFillWeight] = useAtom(
    selectedFillWeightAtom
  );
  const [selectedHachureAngle, setSelectedHachureAngle] = useAtom(
    selectedHachureAngleAtom
  );
  const [selectedHachureGap, setSelectedHachureGap] = useAtom(
    selectedHachureGapAtom
  );
  const [selectedOpacity, setSelectedOpacity] = useAtom(selectedOpacityAtom);

  const [selectedRoundness, setSelectedRoundness] = useAtom(
    selectedRoundnessAtom
  );

  const [synchronizedFrameInterval, setSynchronizedFrameInterval] = useAtom(
    synchronizedFrameIntervalAtom
  );

  const setPreviewOpen = useSetAtom(isPreviewOpenAtom);

  const [isMinimized, setMinimized] = useState<boolean>(false);

  const speedRef = useRef<HTMLSpanElement>(null);
  const timeRef = useRef<HTMLSpanElement>(null);

  const handleClickPreviewOpen = useCallback(
    (evt: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      setPreviewOpen(true);
    },
    []
  );

  // const handleChangeInterval = useCallback(
  //   (_e: any, value: any) => {
  //     setFrameInfos(
  //       frameInfos.map((frameInfo) => ({
  //         ...frameInfo,
  //         delay: Math.pow(value / 1000, 2) * 1000,
  //       }))
  //     );
  //   },
  //   [frameInfos]
  // );

  const handleChangeCropPosition = useCallback(() => {
    setSynchronizedScaleInfo(!synchronizedPositionInfo);
  }, [synchronizedPositionInfo, setSynchronizedScaleInfo]);

  const handleChangeStrokeColor = useCallback(
    (evt: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      const dataType = evt.currentTarget.dataset.type as EditorColor;

      if (selectedElements.length) {
        updateElementStrokeColor(
          selectedElements.map((el) => el.id),
          dataType
        );
      }

      setSelectedStrokeColor(dataType);
    },
    [selectedElements, setSelectedStrokeColor]
  );

  const handleChangeFillColor = useCallback(
    (evt: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      const dataType = evt.currentTarget.dataset.type as EditorColor;

      if (selectedElements.length) {
        updateElementFillColor(
          selectedElements.map((el) => el.id),
          dataType
        );
      }

      setSelectedFillColor(dataType);
    },
    [selectedElements, setSelectedFillColor]
  );

  const handleChangeThickness = useCallback(
    (evt: any, value: any) => {
      evt.preventDefault();

      if (selectedElements.length) {
        updateElementLineWidth(
          selectedElements.map((el) => el.id),
          value
        );
      }

      setSelectedLineWidth(value);
    },
    [selectedElements, setSelectedLineWidth]
  );

  // const handleChangeBorder = useCallback(
  //   (evt: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
  //     evt.preventDefault();

  //     const dataType = evt.currentTarget.dataset.type as EditorLineBorderType;

  //     setSelectedLineBoarder(dataType);
  //   },
  //   [setSelectedLineBoarder]
  // );

  const handleChangeFontFamily = useCallback(
    (evt: any, data: any) => {
      evt.preventDefault();

      setSelectedFontFamily(data.value);
    },
    [setSelectedFontFamily]
  );

  const handleChangeFontSize = useCallback(
    (evt: any, data: any) => {
      evt.preventDefault();
      console.log(data);
      setSelectedFontSize(data.value);
    },
    [setSelectedFontSize]
  );

  // const handleChangeTextAlign = useCallback(
  //   (evt: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
  //     evt.preventDefault();

  //     const dataType = evt.currentTarget.dataset.type as EditorTextAlign;

  //     setSelectedTextAlign(dataType);
  //   },
  //   [setSelectedTextAlign]
  // );

  const handleChangeOpacity = useCallback(
    (evt: any, value: any) => {
      evt.preventDefault();

      setSelectedOpacity(value);

      if (selectedElements.length) {
        updateElementOpacity(
          selectedElements.map((el) => el.id),
          value
        );
      }
    },
    [selectedElements, setSelectedOpacity]
  );

  const handleChangeInterval = useCallback(
    (evt: any, value: any) => {
      evt.stopPropagation();

      if (speedRef.current && timeRef.current) {
        speedRef.current.innerText = `${value} ms`;
        timeRef.current.innerText = `${(frameInfos.length * value) / 1000} s`;
      }

      setFrameInfos(
        frameInfos.map((frameInfo) => ({
          ...frameInfo,
          delay: Math.pow(value / 1000, 2) * 1000,
        }))
      );
    },
    [frameInfos]
  );

  const handleChangeFrameInterval = useCallback(
    (evt: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
      evt.stopPropagation();
      evt.preventDefault();

      if (!synchronizedFrameInterval) {
        handleChangeInterval(null, frameInfos?.[0]?.delay || 50);
      }
      setSynchronizedFrameInterval(!synchronizedFrameInterval);
    },
    [
      frameInfos,
      synchronizedFrameInterval,
      setSynchronizedFrameInterval,
      handleChangeInterval,
    ]
  );

  const handleChangeRoughness = useCallback(
    (evt: any, value: any) => {
      evt.preventDefault();

      setSelectedRoughness(value);

      if (selectedElements.length) {
        updateElementRoughness(
          selectedElements.map((el) => el.id),
          value
        );
      }
    },
    [selectedElements, setSelectedRoughness]
  );

  const handleChangeBowing = useCallback(
    (evt: any, value: any) => {
      evt.preventDefault();

      setSelectedBowing(value);

      if (selectedElements.length) {
        updateElementBowing(
          selectedElements.map((el) => el.id),
          value
        );
      }
    },
    [selectedElements, setSelectedBowing]
  );

  const handleChangeFillWeight = useCallback(
    (evt: any, value: any) => {
      evt.preventDefault();

      setSelectedFillWeight(value);

      if (selectedElements.length) {
        updateElementFillWeight(
          selectedElements.map((el) => el.id),
          value
        );
      }
    },
    [selectedElements, setSelectedFillWeight]
  );

  const handleChangeHachureAngle = useCallback(
    (_e: any, value: any) => {
      setSelectedHachureAngle(value);

      if (selectedElements.length) {
        updateElementHachureAngle(
          selectedElements.map((el) => el.id),
          value
        );
      }
    },
    [selectedElements, setSelectedHachureAngle]
  );

  const handleChangeHachureGap = useCallback(
    (evt: any, value: any) => {
      evt.preventDefault();

      setSelectedHachureGap(value);

      if (selectedElements.length) {
        updateElementHachureGap(
          selectedElements.map((el) => el.id),
          value
        );
      }
    },
    [selectedElements, setSelectedHachureGap]
  );

  const handleChangeFillStyle = useCallback(
    (evt: any, value: any) => {
      evt.preventDefault();

      setSelectedFillStyle(value.value as FillStyle);

      if (selectedElements.length) {
        updateElementFillStyle(
          selectedElements.map((el) => el.id),
          value.value
        );
      }
    },
    [selectedElements, setSelectedFillStyle]
  );

  const handleAdjustLayer = useCallback(
    (evt: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      const type = evt.currentTarget.dataset.type;
      switch (type) {
        case 'First':
          moveToFirstFrame(selectedElements[0].id);
          break;
        case 'Prev':
          moveToPrevFrame(selectedElements[0].id);
          break;
        case 'Next':
          moveToNextFrame(selectedElements[0].id);
          break;
        case 'Last':
          moveToLastFrame(selectedElements[0].id);
          break;
        default:
          break;
      }
    },
    [selectedElements]
  );

  const handleClickRoundness = useCallback(
    (evt: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      const type = evt.currentTarget.dataset.type as Roundness;

      setSelectedRoundness(type);

      if (selectedElements.length) {
        updateElementRoundness(
          selectedElements.map((el) => el.id),
          type
        );
      }
    },
    [selectedElements]
  );

  const selectedElementsTypes = uniq(
    selectedElements.map((element) => element.type)
  );

  if (mainMenuStatus || frameInfos.length === 0) {
    return null;
  }

  if (
    activeTool === EditorTool.Select ||
    activeTool === EditorTool.Image ||
    activeTool === EditorTool.Eraser
  ) {
    return null;
  }

  return (
    <div className='option-wrapper'>
      {!isMinimized && (
        <>
          {activeTool === EditorTool.Crop && (
            <div className='option-item'>
              <div
                className='option-detail'
                onClick={handleChangeCropPosition}
                onTouchEnd={handleChangeCropPosition}
              >
                <Checkbox checked={synchronizedPositionInfo} />
                <span>Per-frame crop setting</span>
              </div>
            </div>
          )}

          {activeTool === EditorTool.Clock && (
            <div className='option-item'>
              <div
                className='option-detail'
                onClick={handleChangeFrameInterval}
                onTouchEnd={handleChangeFrameInterval}
              >
                <Checkbox checked={synchronizedFrameInterval} />
                <span>Synchromized Interval</span>
              </div>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [EditorTool.Text].includes(type)
          ) ||
            [EditorTool.Text].some((type) => activeTool === type)) && (
            <>
              <div className='option-item'>
                <div className='option-title'>Font</div>
                <div className='option-detail'>
                  <ArtifySelect
                    value={selectedFontFamily}
                    onChange={handleChangeFontFamily}
                    options={Object.entries(EditorFontFamily).map(
                      ([key, value]) => ({
                        key,
                        value,
                        text: value,
                      })
                    )}
                  />
                </div>
              </div>
              <div className='option-item'>
                <div className='option-title'>Size</div>
                <div className='option-detail'>
                  <ArtifySelect
                    value={selectedFontSize}
                    onChange={handleChangeFontSize}
                    options={Object.entries(EditorFontSize).map(
                      ([key, value]) => ({
                        key,
                        value,
                        text: value,
                      })
                    )}
                  />
                </div>
              </div>
            </>
          )}

          {(selectedElementsTypes.some((type) =>
            [
              EditorTool.FreeDraw,
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
              EditorTool.Text,
            ].includes(type)
          ) ||
            [
              EditorTool.FreeDraw,
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
              EditorTool.Text,
            ].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>Color</div>
              <div className='option-detail'>
                {Object.entries(EditorColor).map(([key, value]) => (
                  <div key={key} className='btn-color'>
                    <button
                      className={`btn-option ${
                        selectedStrokeColor === value ? 'selected' : ''
                      } ${value === EditorColor.Transparent ? 'transparent' : ''}`}
                      data-type={value}
                      onClick={handleChangeStrokeColor}
                      onTouchEnd={handleChangeStrokeColor}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          backgroundColor: value,
                        }}
                      />
                    </button>
                    {selectedStrokeColor === value && (
                      <img src='./icons/check.svg' alt='' />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].includes(type)
          ) ||
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>Fill Color</div>
              <div className='option-detail'>
                {Object.entries(EditorColor).map(([key, value]) => (
                  <button
                    key={key}
                    className={`btn-option ${
                      selectedFillColor === value ? 'selected' : ''
                    } ${value === EditorColor.Transparent ? 'transparent' : ''}`}
                    data-type={value}
                    onClick={handleChangeFillColor}
                    onTouchEnd={handleChangeFillColor}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: value,
                      }}
                    />
                  </button>
                ))}
              </div>
            </div>
          )}
          {(selectedElementsTypes.some((type) =>
            [EditorTool.Rectangle].includes(type)
          ) ||
            [EditorTool.Rectangle].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>Roundness</div>
              <div className='option-detail'>
                {Object.entries(Roundness).map(([key, value], index) => (
                  <button
                    key={key}
                    className={`btn-option ${
                      selectedRoundness === value ? 'selected' : ''
                    }`}
                    data-type={value}
                    onClick={handleClickRoundness}
                    onTouchEnd={handleClickRoundness}
                  >
                    {index}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* {(selectedElementsTypes.some((type) =>
        [EditorTool.Text].includes(type)
      ) ||
        [EditorTool.Text].some((type) => activeTool === type)) && (
        <>
          <div className='option-item'>
            <div className='option-title'>Font Family</div>
            <div className='option-detail'>
              {Object.entries(EditorFontFamily).map(([key, value], index) => (
                <button
                  key={key}
                  className={`btn-option ${
                    selectedFontFamily === value ? 'selected' : ''
                  } `}
                  data-type={value}
                  onClick={handleChangeFontFamily}
                  onTouchEnd={handleChangeFontFamily}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {index}
                  </div>
                </button>
              ))}
            </div>
          </div>
          <div className='option-item'>
            <div className='option-title'>Font Size</div>
            <div className='option-detail'>
              {Object.entries(EditorFontSize).map(([key, value]) => (
                <button
                  key={key}
                  className={`btn-option ${
                    selectedFontSize === value ? 'selected' : ''
                  } `}
                  data-type={value}
                  onClick={handleChangeFontSize}
                  onTouchEnd={handleChangeFontSize}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {value}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </>
      )} */}

          {(selectedElementsTypes.some((type) =>
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].includes(type)
          ) ||
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>Fill Style</div>
              <div className='option-detail'>
                <Select
                  value={selectedFillStyle}
                  onChange={handleChangeFillStyle}
                  style={{ width: '100%' }}
                  options={Object.entries(FillStyle).map(([key, value]) => ({
                    key,
                    value,
                    text: value,
                  }))}
                />
              </div>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [
              EditorTool.FreeDraw,
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].includes(type)
          ) ||
            [
              EditorTool.FreeDraw,
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>
                <span>Thickness</span>
                <span>{selectedLineWidth} pt</span>
              </div>
              <div className='option-detail'>
                <Slider
                  value={selectedLineWidth}
                  onChange={handleChangeThickness}
                  max={10}
                  min={1}
                  step={1}
                  style={{ width: '100%', color: '#fff' }}
                />
              </div>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [EditorTool.Clock].includes(type)
          ) ||
            [EditorTool.Clock].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>
                <span>Speed</span>
                <span ref={speedRef}>200 ms</span>
              </div>
              <div className='option-detail'>
                <Slider
                  defaultValue={200}
                  onChange={handleChangeInterval}
                  max={1000}
                  min={1}
                  step={1}
                  style={{ width: '100%', color: '#fff' }}
                />
              </div>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [EditorTool.Clock].includes(type)
          ) ||
            [EditorTool.Clock].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>
                <span>Time</span>
                <span ref={timeRef}>{(frameInfos.length * 200) / 1000} s</span>
              </div>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [EditorTool.Clock].includes(type)
          ) ||
            [EditorTool.Clock].some((type) => activeTool === type)) && (
            <div className='option-item' style={{ marginTop: '8px' }}>
              <Button
                variant='outlined'
                className='btn-default'
                fullWidth
                onClick={handleClickPreviewOpen}
              >
                <img src='./icons/play.svg' alt='zoom-out' />
                Preview
              </Button>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].includes(type)
          ) ||
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>Roughness</div>
              <div className='option-detail'>
                <Slider
                  value={selectedRoughness}
                  onChange={handleChangeRoughness}
                  max={10}
                  min={1}
                  step={1}
                  style={{ width: '100%', color: '#fff' }}
                />
              </div>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].includes(type)
          ) ||
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>Bowing</div>
              <div className='option-detail'>
                <Slider
                  value={selectedBowing}
                  onChange={handleChangeBowing}
                  max={10}
                  min={1}
                  step={1}
                  style={{ width: '100%', color: '#fff' }}
                />
              </div>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].includes(type)
          ) ||
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>Fill Weight</div>
              <div className='option-detail'>
                <Slider
                  value={selectedFillWeight}
                  onChange={handleChangeFillWeight}
                  max={10}
                  min={0}
                  step={0.5}
                  style={{ width: '100%', color: '#fff' }}
                />
              </div>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].includes(type)
          ) ||
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>Hachure Angle</div>
              <div className='option-detail'>
                <Slider
                  value={selectedHachureAngle}
                  onChange={handleChangeHachureAngle}
                  max={360}
                  min={0}
                  step={0.1}
                  style={{ width: '100%', color: '#fff' }}
                />
              </div>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].includes(type)
          ) ||
            [
              EditorTool.Rectangle,
              EditorTool.PolyLine,
              EditorTool.Ellipse,
            ].some((type) => activeTool === type)) && (
            <div className='option-item'>
              <div className='option-title'>Hachure Gap</div>
              <div className='option-detail'>
                <Slider
                  value={selectedHachureGap}
                  onChange={handleChangeHachureGap}
                  max={100}
                  min={1}
                  step={1}
                  style={{ width: '100%', color: '#fff' }}
                />
              </div>
            </div>
          )}

          {(selectedElementsTypes.some((type) =>
            [EditorTool.FreeDraw, EditorTool.Text].includes(type)
          ) ||
            [EditorTool.FreeDraw, EditorTool.Text].some(
              (type) => activeTool === type
            )) && (
            <div className='option-item'>
              <div className='option-title'>
                <span>Opacity</span>
                <span>{Math.round(selectedOpacity * 100)} %</span>
              </div>
              <div className='option-detail'>
                <Slider
                  value={selectedOpacity}
                  onChange={handleChangeOpacity}
                  max={1}
                  min={0.0}
                  step={0.01}
                  style={{ width: '100%', color: '#fff' }}
                />
              </div>
            </div>
          )}

          {selectedElements.length === 1 && (
            <div className='option-item'>
              <div className='option-title'>Adjust Layer</div>
              <div className='option-detail'>
                {['First', 'Prev', 'Next', 'Last'].map((value, index) => (
                  <button
                    key={value}
                    className={`btn-option`}
                    data-type={value}
                    onClick={handleAdjustLayer}
                    onTouchEnd={handleAdjustLayer}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {index}
                    </div>
                  </button>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      {isMinimized && (
        <>
          <div className='option-item'>
            <div className='option-title'>Options</div>
          </div>
        </>
      )}
      <div
        className='minimized'
        onClick={() => {
          setMinimized((prev) => !prev);
        }}
      >
        <img src='./icons/chevron-down.svg' alt='' />
      </div>
    </div>
  );
};

export default OptionDialog;
