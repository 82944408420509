import { cloneDeep } from 'lodash';
import { Element, FrameInfo } from '../atoms/frameAtoms';
import { HistoryManager } from './HistoryManager';

export class FrameInfoManager {
  static frameInfo: FrameInfo;
  static historyManager: HistoryManager;

  static init(frameInfo: FrameInfo) {
    this.frameInfo = cloneDeep(frameInfo);
    this.historyManager = new HistoryManager();
  }

  static getFrameInfo = () => {
    return cloneDeep(this.frameInfo);
  };

  static addElement(elements: Element[]) {
    const newElements = cloneDeep(elements);
    newElements.forEach((newElement) => {
      this.frameInfo.elements.push(newElement);
    });

    this.historyManager.addAction({
      action: 'add-multiple',
      elements: newElements,
    });
  }

  static updateElement(elements: Element[]) {
    const previousElements: Element[] = [];
    const updatedElements: Element[] = [];

    const newElements = cloneDeep(elements);

    newElements.forEach((element) => {
      const index = this.frameInfo.elements.findIndex(
        (el) => el.id === element.id
      );

      if (index !== -1) {
        const previousElement = cloneDeep(this.frameInfo.elements[index]);
        this.frameInfo.elements[index] = cloneDeep(element);
        previousElements.push(cloneDeep(previousElement));
        updatedElements.push(cloneDeep(element));
      }
    });

    if (previousElements.length > 0) {
      this.historyManager.addAction({
        action: 'update-multiple',
        elements: updatedElements,
        previousElements: cloneDeep(previousElements),
      });
    }
  }

  static deleteElement(elementIds: string[]) {
    const deletedElements: Element[] = [];
    elementIds.forEach((elementId) => {
      const index = this.frameInfo.elements.findIndex(
        (el) => el.id === elementId
      );
      if (index !== -1) {
        const [element] = this.frameInfo.elements.splice(index, 1);
        deletedElements.push(cloneDeep(element)); // Clone deleted element
      }
    });

    if (deletedElements.length > 0) {
      this.historyManager.addAction({
        action: 'delete-multiple',
        elements: deletedElements,
      });
    }
  }

  static undo() {
    const action = this.historyManager.undo();
    if (action) {
      switch (action.action) {
        case 'add':
          this.frameInfo.elements = this.frameInfo.elements.filter(
            (el) => el.id !== action.element.id
          );
          break;
        case 'add-multiple':
          this.frameInfo.elements = this.frameInfo.elements.filter(
            (el) => !action.elements.some((elem) => elem.id === el.id)
          );
          break;
        case 'update': {
          const index = this.frameInfo.elements.findIndex(
            (el) => el.id === action.element.id
          );
          if (index !== -1 && action.previousElement) {
            this.frameInfo.elements[index] = action.previousElement;
          }
          break;
        }
        case 'update-multiple':
          action.previousElements.forEach((prevElement) => {
            const idx = this.frameInfo.elements.findIndex(
              (el) => el.id === prevElement.id
            );
            if (idx !== -1) {
              this.frameInfo.elements[idx] = prevElement;
            }
          });
          break;
        case 'delete':
          this.frameInfo.elements.push(action.element);
          break;
        case 'delete-multiple':
          action.elements.forEach((element) => {
            this.frameInfo.elements.push(element);
          });
          break;
      }
    }
  }

  static redo() {
    const action = this.historyManager.redo();
    if (action) {
      switch (action.action) {
        case 'add':
          this.frameInfo.elements.push(action.element);
          break;
        case 'add-multiple':
          action.elements.forEach((element) => {
            this.frameInfo.elements.push(element);
          });
          break;
        case 'update': {
          const index = this.frameInfo.elements.findIndex(
            (el) => el.id === action.element.id
          );
          if (index !== -1) {
            this.frameInfo.elements[index] = action.element;
          }
          break;
        }
        case 'update-multiple':
          action.elements.forEach((element) => {
            const idx = this.frameInfo.elements.findIndex(
              (el) => el.id === element.id
            );
            if (idx !== -1) {
              this.frameInfo.elements[idx] = element;
            }
          });
          break;
        case 'delete':
          this.frameInfo.elements = this.frameInfo.elements.filter(
            (el) => el.id !== action.element.id
          );
          break;
        case 'delete-multiple':
          this.frameInfo.elements = this.frameInfo.elements.filter(
            (el) => !action.elements.some((elem) => elem.id === el.id)
          );
          break;
      }
    }
  }
}
