import { useAtomValue } from 'jotai';
import { clamp, inRange } from 'lodash';
import { PointerEvent, useCallback, useEffect, useRef } from 'react';
import { selectedVideoFileAtom } from '../atoms/appAtoms';
import { clearCanvas, drawRoundRect, fillRectCanvas } from '../utils/canvas';

type VideoSectionCanvasProps = {
  start: number;
  end: number;
  video: HTMLVideoElement | null;
  onUpdateSection: (start: number, end: number) => void;
};

const VideoSectionCanvas = (props: VideoSectionCanvasProps) => {
  const { start, end, video, onUpdateSection } = props;

  const videoFile = useAtomValue(selectedVideoFileAtom);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const frameCanvasRef = useRef<HTMLCanvasElement>(null);
  const frameCtxRef = useRef<CanvasRenderingContext2D | null>(null);

  const sectionCanvasRef = useRef<HTMLCanvasElement>(null);
  const sectionCtxRef = useRef<CanvasRenderingContext2D | null>(null);

  const isPointerDownOnStart = useRef<boolean>(false);
  const isPointerDownOnEnd = useRef<boolean>(false);

  const drawFrames = useCallback(() => {
    if (videoFile) {
      const videoElement = document.createElement('video');
      const url = URL.createObjectURL(videoFile);
      videoElement.addEventListener(
        'loadeddata',
        () => {
          if (frameCanvasRef.current && frameCtxRef.current) {
            const rate =
              frameCanvasRef.current.height / videoElement.videoHeight;
            const frameWidth = videoElement.videoWidth * rate;
            const frameCount = frameCanvasRef.current.width / frameWidth;
            const interval = videoElement.duration / frameCount;

            frameCtxRef.current.clearRect(
              0,
              0,
              frameCanvasRef.current.width,
              frameCanvasRef.current.height
            );

            let drawIndex = 0;

            videoElement.currentTime = 0;

            videoElement.addEventListener('seeked', function onSeeked() {
              if (videoElement.currentTime >= videoElement.duration) {
                videoElement.removeEventListener('seeked', onSeeked);
              }

              if (frameCanvasRef.current && frameCtxRef.current) {
                frameCtxRef.current.drawImage(
                  videoElement,
                  0,
                  0,
                  videoElement.videoWidth,
                  videoElement.videoHeight,
                  frameWidth * drawIndex,
                  0,
                  frameWidth,
                  frameCanvasRef.current.height
                );

                drawIndex++;
                videoElement.currentTime += interval; // Move to the next frame
              }
            });

            videoElement.addEventListener('play', () => {
              videoElement.play();
              videoElement.currentTime = 0;
            });
          }
        },
        { once: true }
      );
      videoElement.muted = true;
      videoElement.autoplay = true;
      videoElement.src = url;
    }
  }, [videoFile]);

  const drawSection = useCallback(() => {
    if (sectionCanvasRef.current && sectionCtxRef.current && video) {
      const offsetX = 8;
      const { duration } = video;
      const sx = (start / duration) * (sectionCanvasRef.current.width - 16);
      const ex = (end / duration) * (sectionCanvasRef.current.width - 16);
      const currentTimeX =
        (video.currentTime / duration) * (sectionCanvasRef.current.width - 16);
      // sectionCtxRef.current.clearRect(
      //   0,
      //   0,
      //   sectionCanvasRef.current.width,
      //   sectionCanvasRef.current.height
      // );

      // sectionCtxRef.current.rect(
      //   sx,
      //   3,
      //   ex - sx,
      //   sectionCanvasRef.current.height - 6
      // );

      clearCanvas(sectionCanvasRef.current, sectionCtxRef.current);

      sectionCtxRef.current.strokeStyle = '#fff';
      sectionCtxRef.current.lineWidth = 4;
      drawRoundRect(
        sectionCtxRef.current,
        sx - 6 + offsetX,
        7,
        ex - sx + 12,
        sectionCanvasRef.current.height - 14,
        4
      );

      fillRectCanvas(
        sectionCtxRef.current,
        [sx - 5 + offsetX, 7, 13, sectionCanvasRef.current.height - 14],
        {
          fillStyle: '#fff',
        }
      );

      fillRectCanvas(
        sectionCtxRef.current,
        [ex - 8 + offsetX, 7, 13, sectionCanvasRef.current.height - 14],
        {
          fillStyle: '#fff',
        }
      );

      sectionCtxRef.current.beginPath();

      sectionCtxRef.current.strokeStyle = '#000';
      sectionCtxRef.current.lineCap = 'round';
      sectionCtxRef.current.lineJoin = 'round';
      sectionCtxRef.current.lineWidth = 2;

      sectionCtxRef.current.moveTo(sx + offsetX, 23);
      sectionCtxRef.current.lineTo(
        sx + offsetX,
        sectionCanvasRef.current.height - 23
      );

      sectionCtxRef.current.stroke();

      sectionCtxRef.current.beginPath();

      sectionCtxRef.current.strokeStyle = '#000';
      sectionCtxRef.current.lineCap = 'round';
      sectionCtxRef.current.lineJoin = 'round';
      sectionCtxRef.current.lineWidth = 2;

      sectionCtxRef.current.moveTo(ex + offsetX, 23);
      sectionCtxRef.current.lineTo(
        ex + offsetX,
        sectionCanvasRef.current.height - 23
      );

      sectionCtxRef.current.stroke();

      sectionCtxRef.current.beginPath();

      sectionCtxRef.current.strokeStyle = '#fff';
      sectionCtxRef.current.lineCap = 'round';
      sectionCtxRef.current.lineJoin = 'round';
      sectionCtxRef.current.lineWidth = 8;

      sectionCtxRef.current.moveTo(currentTimeX + offsetX, 4);
      sectionCtxRef.current.lineTo(
        currentTimeX + offsetX,
        sectionCanvasRef.current.height - 4
      );

      sectionCtxRef.current.stroke();
    }
  }, [video, end, start]);

  const handlePointerDown = useCallback(
    (evt: PointerEvent) => {
      const { offsetX } = evt.nativeEvent;

      if (sectionCanvasRef.current && video) {
        const { duration } = video;

        const sx = (start / duration) * (sectionCanvasRef.current?.width || 0);
        const ex = (end / duration) * (sectionCanvasRef.current?.width || 0);

        if (offsetX > sx - 10 && offsetX < sx + 10) {
          isPointerDownOnStart.current = true;
        }

        if (offsetX > ex - 10 && offsetX < ex + 10) {
          isPointerDownOnEnd.current = true;
        }
      }
    },
    [video, end, start]
  );

  const handlePointerMove = useCallback(
    (evt: PointerEvent) => {
      const { offsetX } = evt.nativeEvent;
      if (video) {
        const { duration } = video;

        if (isPointerDownOnStart.current) {
          video.currentTime =
            (offsetX / (sectionCanvasRef.current?.width || 0)) * duration;
          onUpdateSection(
            clamp(
              (offsetX / (sectionCanvasRef.current?.width || 0)) * duration,
              0,
              end
            ),
            end
          );
          return;
        }

        if (isPointerDownOnEnd.current) {
          video.currentTime =
            (offsetX / (sectionCanvasRef.current?.width || 0)) * duration;
          onUpdateSection(
            start,
            clamp(
              (offsetX / (sectionCanvasRef.current?.width || 0)) * duration,
              start,
              duration
            )
          );
          return;
        }
      }

      if (sectionCanvasRef.current && video) {
        const { duration } = video;
        const sx = (start / duration) * (sectionCanvasRef.current?.width || 0);
        const ex = (end / duration) * (sectionCanvasRef.current?.width || 0);
        if (
          inRange(offsetX, sx - 10, sx + 10) ||
          inRange(offsetX, ex - 10, ex + 10)
        ) {
          sectionCanvasRef.current.style.cursor = 'ew-resize';
        } else {
          sectionCanvasRef.current.style.cursor = 'default';
        }
      }
    },
    [onUpdateSection, video, end, start]
  );

  const handlePointerUp = useCallback(() => {
    isPointerDownOnStart.current = false;
    isPointerDownOnEnd.current = false;
  }, []);

  useEffect(() => {
    if (wrapperRef.current && frameCanvasRef.current) {
      const { width, height } = wrapperRef.current.getBoundingClientRect();

      frameCanvasRef.current.width = width
        ? width
        : sectionCanvasRef.current?.width || 0;
      frameCanvasRef.current.height =
        (height ? height : sectionCanvasRef.current?.height || 0) - 16;

      frameCtxRef.current = frameCanvasRef.current.getContext('2d');

      drawFrames();
    }
  }, [drawFrames]);

  useEffect(() => {
    if (wrapperRef.current && sectionCanvasRef.current) {
      const { width, height } = wrapperRef.current.getBoundingClientRect();

      sectionCanvasRef.current.width = width;
      sectionCanvasRef.current.height = height;
      sectionCtxRef.current = sectionCanvasRef.current.getContext('2d');
    }
  }, [drawSection]);

  useEffect(() => {
    drawSection();
  }, [start, end]);

  useEffect(() => {
    const handleTimeUpdateVideo = () => {
      drawSection();
    };

    video?.addEventListener('timeupdate', handleTimeUpdateVideo);

    return () =>
      video?.removeEventListener('timeupdate', handleTimeUpdateVideo);
  }, [video, drawSection]);

  return (
    <div className='select-video-section-canvas-wrapper' ref={wrapperRef}>
      <canvas ref={frameCanvasRef} />
      <canvas
        ref={sectionCanvasRef}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onPointerLeave={handlePointerUp}
      />
    </div>
  );
};

export default VideoSectionCanvas;
