import { useAtom, useSetAtom } from 'jotai';
import { newWorkspaceDialogAtom } from '../atoms/dialogAtom';
import {
  ChangeEvent,
  MouseEvent,
  TouchEvent,
  useCallback,
  useState,
} from 'react';

import '../styles/NewWorkspaceDialog.scss';
import { EditorColor } from '../atoms/optionAtom';
import { generateUUID } from '../utils/uuid';
import { frameInfosAtom } from '../atoms/frameAtoms';
import { getFitImageStatus } from '../utils/canvas';
import { canvasStatusAtom } from '../atoms/canvasAtoms';
import { Input } from 'semantic-ui-react';
import SystemModal from './SystemModal';
import { FrameInfoManager } from '../modules/FrameInfoManager';

const NewWorkspaceDialog = () => {
  const setCanvasStatusInfo = useSetAtom(canvasStatusAtom);
  const setFrameInfos = useSetAtom(frameInfosAtom);
  const [newWorkspaceDialog, setNewWorkspaceDialog] = useAtom(
    newWorkspaceDialogAtom
  );

  const [width, setWidth] = useState<number>(500);
  const [height, setHeight] = useState<number>(300);

  const [count, setCount] = useState<number>(10);

  const [background, setBackground] = useState<EditorColor>(EditorColor.White);

  const handleClickConfirm = useCallback(() => {
    const frameInfos = Array(count)
      .fill(0)
      .map(() => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        if (ctx) {
          ctx.fillStyle = background;
          ctx.fillRect(0, 0, width, height);
        }

        return {
          id: generateUUID(),
          elements: [],
          canvas,
          disabled: false,
          delay: 50,
        };
      });

    const canvasStatus = getFitImageStatus(frameInfos[0].canvas, {
      width: window.innerWidth,
      height: window.innerHeight - 290,
    } as HTMLCanvasElement);

    if (canvasStatus.y < 60) {
      canvasStatus.y = 60;
    }

    FrameInfoManager.init(frameInfos[0]);

    setCanvasStatusInfo(canvasStatus);

    setFrameInfos(frameInfos);

    setNewWorkspaceDialog(false);
  }, [width, height, count, background]);

  const handleClickCancel = useCallback(() => {
    setNewWorkspaceDialog(false);
  }, []);

  const handleChangeWidth = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setWidth(Number(evt.currentTarget.value));
    },
    []
  );

  const handleChangeHeight = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setHeight(Number(evt.currentTarget.value));
    },
    []
  );

  const handleChangeCount = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setCount(Number(evt.currentTarget.value));
    },
    []
  );

  const handleChangeColor = useCallback(
    (evt: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      const dataType = evt.currentTarget.dataset.type as EditorColor;

      setBackground(dataType);
    },
    []
  );

  return (
    <SystemModal
      open={newWorkspaceDialog}
      title='New Workspace'
      onClickConfirm={handleClickConfirm}
      onClickCancel={handleClickCancel}
      onClose={handleClickCancel}
      width={550}
    >
      <div className='workspace-wrapper'>
        <div className='workspace-info'>
          <div>Canvas Size:</div>
          <div>
            <Input
              type='number'
              value={width}
              onChange={handleChangeWidth}
              style={{ width: '150px' }}
            />{' '}
            <span className='multiply'>X</span>
            <Input
              type='number'
              value={height}
              onChange={handleChangeHeight}
              style={{ width: '150px' }}
            />
          </div>
        </div>

        <div className='workspace-info'>
          <div>Number of Frames:</div>
          <Input
            type='number'
            value={count}
            onChange={handleChangeCount}
            style={{ width: '150px' }}
          />
        </div>

        <div className='workspace-info'>
          <div>Background Color:</div>
          <div>
            {Object.entries(EditorColor).map(([key, value]) => (
              <div key={key} className='btn-option-wrapper'>
                <button
                  className={`btn-option ${
                    background === value ? 'selected' : ''
                  } ${value === EditorColor.Transparent ? 'transparent' : ''}`}
                  data-type={value}
                  onClick={handleChangeColor}
                  onTouchEnd={handleChangeColor}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: value,
                    }}
                  />
                </button>
                {background === value && <img src='./icons/check.svg' alt='' />}
              </div>
            ))}
          </div>
        </div>
      </div>
    </SystemModal>
  );
};

export default NewWorkspaceDialog;
