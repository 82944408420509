import { GifReader } from 'omggif';
import { getDefaultStore } from 'jotai';
import { loadingStatusAtom } from '../atoms/appAtoms';
import { generateUUID } from './uuid';
import { Element, frameIndexAtom, frameInfosAtom } from '../atoms/frameAtoms';
import { videoFileOpenDialogAtom } from '../atoms/dialogAtom';
import { canvasStatusAtom } from '../atoms/canvasAtoms';
import { getFitImageStatus } from './canvas';
import { DISTANCE_THRESHOLD, Point, Rect } from './shape';
import { getDistancePoints } from './geometry';
import { FrameInfoManager } from '../modules/FrameInfoManager';

type ConvertVideoFrameProps = {
  file: File;
  start: number;
  end: number;
  fps: number;
};

const store = getDefaultStore();

export const convertVideoFrame = async (props: ConvertVideoFrameProps) => {
  store.set(loadingStatusAtom, true);

  const videoFrames = (await createVideoFrame(props)) as HTMLCanvasElement[];

  const frameInfo = videoFrames.map((frame) => ({
    id: generateUUID(),
    canvas: frame,
    elements: [],
    disabled: false,
    delay: 50,
  }));

  const canvasStatus = getFitImageStatus(frameInfo[0].canvas, {
    width: window.innerWidth,
    height: window.innerHeight - 290,
  } as HTMLCanvasElement);

  if (canvasStatus.y < 60) {
    canvasStatus.y = 60;
  }

  FrameInfoManager.init(frameInfo[0]);

  store.set(canvasStatusAtom, canvasStatus);

  store.set(frameInfosAtom, frameInfo);

  store.set(loadingStatusAtom, false);
  store.set(videoFileOpenDialogAtom, false);
};

const createVideoFrame = async (props: ConvertVideoFrameProps) => {
  const { file, start, end, fps } = props;
  return new Promise((resolve) => {
    const videoElement = document.createElement('video');
    const url = URL.createObjectURL(file);

    videoElement.addEventListener(
      'loadeddata',
      () => {
        resolve(processVideo(videoElement, fps, start, end));
      },
      { once: true }
    );

    videoElement.muted = true;
    videoElement.autoplay = true;

    videoElement.src = url;
  });
};

const processVideo = (
  video: HTMLVideoElement,
  fps: number,
  start: number,
  end: number
) => {
  return new Promise((resolve, reject) => {
    try {
      const loadMessage = document.getElementById('load-message');

      const frames: any = [];
      video.currentTime = start;

      video.addEventListener('seeked', function onSeeked() {
        if (video.currentTime >= end) {
          video.removeEventListener('seeked', onSeeked);
          resolve(frames);
        }

        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        const ctx = canvas.getContext('2d');

        if (canvas && ctx) {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          frames.push(canvas);

          if (loadMessage) {
            loadMessage.innerHTML = `process: ${frames.length} frame...`;
          }

          video.currentTime += 1 / fps; // Move to the next frame
        }
      });

      video.addEventListener('play', () => {
        video.play();
        video.currentTime = start;
      });
    } catch {
      reject();
    }
  });
};

export const convertImageFrame = async (file: File) => {
  store.set(loadingStatusAtom, true);

  const imageFrames = (await createImageFrame(file)) as HTMLCanvasElement[];

  const frameInfo = imageFrames.map((frame) => ({
    id: generateUUID(),
    canvas: frame,
    elements: [],
    disabled: false,
    delay: 50,
  }));

  const canvasStatus = getFitImageStatus(frameInfo[0].canvas, {
    width: window.innerWidth,
    height: window.innerHeight - 300,
  } as HTMLCanvasElement);

  store.set(canvasStatusAtom, canvasStatus);

  store.set(frameInfosAtom, frameInfo);

  store.set(loadingStatusAtom, false);
};

export const createImageFrame = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target?.result as ArrayBuffer;
      resolve(readGif(arrayBuffer));
    };

    reader.onerror = () => {
      reject();
    };

    reader.readAsArrayBuffer(file);
  });
};

const readGif = async (arrayBuffer: ArrayBuffer) => {
  return new Promise((resolve, reject) => {
    try {
      const gifFrames: HTMLCanvasElement[] = [];
      const gif = new GifReader(new Uint8Array(arrayBuffer));

      for (let i = 0; i < gif.numFrames(); i++) {
        const imageData = new Uint8ClampedArray(4 * gif.width * gif.height);
        gif.decodeAndBlitFrameRGBA(i, imageData);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = gif.width;
        canvas.height = gif.height;
        const imgData = new ImageData(imageData, gif.width, gif.height);
        ctx?.putImageData(imgData, 0, 0);
        gifFrames.push(canvas);
      }
      resolve(gifFrames);
    } catch {
      reject();
    }
  });
};

export const updateDisabledValue = (frameIndex: number) => {
  const frameInfos = store.get(frameInfosAtom);

  const updateFrameInfos = [...frameInfos];
  updateFrameInfos[frameIndex].disabled =
    !updateFrameInfos[frameIndex].disabled;

  store.set(frameInfosAtom, updateFrameInfos);
};

export const copyFrameInfo = (frameIndex: number) => {
  const frameInfos = store.get(frameInfosAtom);

  const updateFrameInfos = [...frameInfos];
  const updateFrameInfo = { ...updateFrameInfos[frameIndex] };
  updateFrameInfo.id = generateUUID();

  store.set(frameInfosAtom, [
    ...updateFrameInfos.slice(0, frameIndex),
    updateFrameInfo,
    ...updateFrameInfos.slice(frameIndex),
  ]);
};

export const getFrameCropInfo = () => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  return frameInfos[frameIndex].cropInfo;
};

export const updateCropInfo = (
  cropInfo: Rect | null,
  synchronizedCropScale: boolean
) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  if (synchronizedCropScale) {
    updatedFrameInfos.forEach((frameInfo) => {
      frameInfo.cropInfo = cropInfo;
    });
  }

  updatedFrameInfos[frameIndex] = {
    ...updatedFrameInfos[frameIndex],
    cropInfo: cropInfo,
  };

  if (!cropInfo) return;

  updatedFrameInfos.forEach((frameInfo) => {
    frameInfo.cropInfo = frameInfo.cropInfo
      ? ([...frameInfo.cropInfo.slice(0, 2), ...cropInfo.slice(2, 4)] as Rect)
      : undefined;
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const getHoveredPointOnRect = (point: Point, rect: Rect) => {
  const points = [
    [rect[0], rect[1]],
    [rect[0] + rect[2], rect[1]],
    [rect[0], rect[1] + rect[3]],
    [rect[0] + rect[2], rect[1] + rect[3]],
  ] as Point[];

  for (let i = 0; i < points.length; i++) {
    if (getDistancePoints(point, points[i]) < DISTANCE_THRESHOLD) {
      return {
        index: i,
        point: points[i],
      };
    }
  }

  return null;
};

export const moveToNextFrame = (id: string) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  const currentIndex = updatedFrameInfos[frameIndex].elements.findIndex(
    (item) => item.id === id
  );

  if (currentIndex === -1) {
    return;
  }

  if (currentIndex >= updatedFrameInfos[frameIndex].elements.length - 1) {
    return;
  }

  [
    updatedFrameInfos[frameIndex].elements[currentIndex],
    updatedFrameInfos[frameIndex].elements[currentIndex + 1],
  ] = [
    updatedFrameInfos[frameIndex].elements[currentIndex + 1],
    updatedFrameInfos[frameIndex].elements[currentIndex],
  ];

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const moveToPrevFrame = (id: string) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];
  const currentIndex = updatedFrameInfos[frameIndex].elements.findIndex(
    (item) => item.id === id
  );

  if (currentIndex === -1) {
    return;
  }

  if (currentIndex <= 0) {
    return;
  }

  [
    updatedFrameInfos[frameIndex].elements[currentIndex],
    updatedFrameInfos[frameIndex].elements[currentIndex - 1],
  ] = [
    updatedFrameInfos[frameIndex].elements[currentIndex - 1],
    updatedFrameInfos[frameIndex].elements[currentIndex],
  ];

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const moveToFirstFrame = (id: string) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  const currentIndex = updatedFrameInfos[frameIndex].elements.findIndex(
    (item) => item.id === id
  );

  if (currentIndex === -1) {
    return;
  }

  if (currentIndex <= 0) {
    return;
  }

  const [item] = updatedFrameInfos[frameIndex].elements.splice(currentIndex, 1);
  updatedFrameInfos[frameIndex].elements.unshift(item);

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const moveToLastFrame = (id: string) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  const currentIndex = updatedFrameInfos[frameIndex].elements.findIndex(
    (item) => item.id === id
  );

  if (currentIndex === -1) {
    return;
  }

  if (currentIndex === updatedFrameInfos[frameIndex].elements.length - 1) {
    return;
  }

  const [item] = updatedFrameInfos[frameIndex].elements.splice(currentIndex, 1); // Remove the item from the current position
  updatedFrameInfos[frameIndex].elements.push(item);

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElements = (elements: Element[]) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements = elements;

  store.set(frameInfosAtom, updatedFrameInfos);
};
