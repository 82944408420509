import { useAtom, useAtomValue } from 'jotai';
import '../styles/FrameInfo.scss';
import {
  FrameInfo as FrameInfoType,
  frameIndexAtom,
  frameInfosAtom,
} from '../atoms/frameAtoms';
import { MouseEvent, TouchEvent, useCallback, useEffect, useRef } from 'react';
import { CanvasStatusInfo } from '../atoms/canvasAtoms';
import { clearCanvas, getFitImageStatus } from '../utils/canvas';
import { copyFrameInfo, updateDisabledValue } from '../utils/frame';
import { synchronizedFrameIntervalAtom } from '../atoms/optionAtom';
import { Button, Slider } from '@mui/material';

type FrameInfoProps = {
  index: number;
  frameInfo: FrameInfoType;
};

const FrameInfo = (props: FrameInfoProps) => {
  const { index, frameInfo } = props;

  const [frameInfos, setFrameInfos] = useAtom(frameInfosAtom);

  const [frameIndex, setFrameIndex] = useAtom(frameIndexAtom);
  const synchronizedFrameInterval = useAtomValue(synchronizedFrameIntervalAtom);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);

  const imageInfo = useRef<CanvasStatusInfo>({
    x: 0,
    y: 0,
    scale: 100,
  });

  const handleClickFrameInfo = useCallback(
    (evt: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
      evt.preventDefault();

      setFrameIndex(index);
    },
    [index]
  );

  const draw = useCallback(() => {
    if (canvasRef.current && ctxRef.current) {
      const { cropInfo } = frameInfo;

      clearCanvas(canvasRef.current, ctxRef.current);

      ctxRef.current.drawImage(
        frameInfo.canvas,
        imageInfo.current.x,
        imageInfo.current.y,
        frameInfo.canvas.width * imageInfo.current.scale * 0.01,
        frameInfo.canvas.height * imageInfo.current.scale * 0.01
      );

      if (cropInfo) {
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.width = canvasRef.current.width;
        canvas.height = canvasRef.current.height;

        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.beginPath();
          ctx.globalCompositeOperation = 'source-over';
          ctx.fillStyle = '#2c2c2c';

          ctx.globalAlpha = 0.8;

          ctx.rect(
            imageInfo.current.x,
            imageInfo.current.y - 1,
            frameInfo.canvas.width * imageInfo.current.scale * 0.01,
            frameInfo.canvas.height * imageInfo.current.scale * 0.01 + 2
          );
          ctx.fill();

          ctx.beginPath();

          ctx.globalCompositeOperation = 'destination-out';

          ctx.globalAlpha = 1;
          ctx.rect(
            imageInfo.current.x + cropInfo[0] * imageInfo.current.scale * 0.01,
            imageInfo.current.y + cropInfo[1] * imageInfo.current.scale * 0.01,
            cropInfo[2] * imageInfo.current.scale * 0.01,
            cropInfo[3] * imageInfo.current.scale * 0.01 + 2
          );

          ctx.fill();
          ctx.globalCompositeOperation = 'source-over';
          ctx.strokeStyle = '#ef5350';
          ctx.stroke();

          ctxRef.current.drawImage(canvas, 0, 0);
        }
      }
    }
  }, [frameInfo]);

  const handleClickSkip = useCallback(
    (evt: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      updateDisabledValue(index);
    },
    [index]
  );

  const handleClickCopy = useCallback(
    (evt: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      copyFrameInfo(index);
    },
    [index]
  );

  const handleChangeInterval = useCallback(
    (_e: Event, value: any) => {
      const updatedFrameInfos = [...frameInfos];
      updatedFrameInfos[frameIndex].delay =
        Math.pow(Number(value) / 1000, 2) * 1000;

      setFrameInfos(updatedFrameInfos);
    },
    [frameInfos]
  );

  useEffect(() => {
    if (canvasRef.current) {
      ctxRef.current = canvasRef.current.getContext('2d');
      imageInfo.current = getFitImageStatus(
        frameInfo.canvas,
        canvasRef.current
      );
    }
  }, []);

  useEffect(() => {
    draw();
  }, [draw, frameInfo.cropInfo]);

  useEffect(() => {
    if (frameIndex === index) {
      canvasRef.current?.scrollIntoView();
    }
  }, [frameIndex, index]);

  return (
    <div
      className={`frame-info-wrapper ${index === frameIndex ? 'selected' : ''}`}
      onClick={handleClickFrameInfo}
      onTouchEnd={handleClickFrameInfo}
    >
      <div className='canvas-wrapper'>
        <div className='index-info'>{index + 1}</div>
        <canvas
          className={`${frameInfo.disabled ? 'disabled' : ''}`}
          ref={canvasRef}
          width={168}
          height={115}
        />
      </div>
      {!synchronizedFrameInterval && index === frameIndex && (
        <div>
          <Slider
            defaultValue={Math.sqrt(frameInfo.delay / 1000) * 1000}
            onChange={handleChangeInterval}
            max={1000}
            min={0}
            step={1}
            style={{ width: '100%' }}
          />
        </div>
      )}
      <div
        className={`option-button ${index === frameIndex ? 'selected' : ''}`}
      >
        <Button
          variant='contained'
          color='error'
          onClick={handleClickSkip}
          onTouchEnd={handleClickSkip}
        >
          Skip
        </Button>
        <Button
          variant='contained'
          color='inherit'
          onClick={handleClickCopy}
          onTouchEnd={handleClickCopy}
        >
          Copy
        </Button>
      </div>
    </div>
  );
};

export default FrameInfo;
