import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { clamp } from 'lodash';

import {
  EditorTool,
  activeToolAtom,
  isMyQustionOpenAtom,
  isSelectCropAtom,
} from '../atoms/appAtoms';
import { canvasStatusAtom } from '../atoms/canvasAtoms';

import '../styles/Navigation.scss';
import useKeyEvent from '../hooks/useKeyEvent';
import { handleAttachImageElement, handleAttachImageFile } from '../utils/file';
import { frameInfosAtom, selectedElementsAtom } from '../atoms/frameAtoms';
import { getFitImageStatus } from '../utils/canvas';
import { Button } from '@mui/material';
import { exportGifDialogAtom } from '../atoms/dialogAtom';
import { FrameInfoManager } from '../modules/FrameInfoManager';
import { updateElements } from '../utils/frame';

const ToolbarIcons = [
  { key: EditorTool.Select, iconPath: './icons/select.svg' },
  { key: EditorTool.Crop, iconPath: './icons/crop.svg' },
  { key: EditorTool.FreeDraw, iconPath: './icons/freedraw.svg' },
  // { key: EditorTool.Rectangle, iconPath: "./icons/rect.svg" },
  // { key: EditorTool.PolyLine, iconPath: "./icons/polygon.svg" },
  // { key: EditorTool.Ellipse, iconPath: "./icons/circle.svg" },
  { key: EditorTool.Text, iconPath: './icons/text.svg' },
  { key: EditorTool.Image, iconPath: './icons/image.svg' },
  // {
  //   key: EditorTool.Emoticon,
  //   iconPath: "./icons/emoticon.svg",
  //   disabled: true,
  // },
  { key: EditorTool.Eraser, iconPath: './icons/eraser.svg' },
  { key: EditorTool.Clock, iconPath: './icons/clock.svg' },
];

const Navigation = () => {
  const frameInfos = useAtomValue(frameInfosAtom);
  const setFrameInfos = useSetAtom(frameInfosAtom);
  const [activeTool, setActiveTool] = useAtom(activeToolAtom);
  const [canvasStatus, setCanvasStatus] = useAtom(canvasStatusAtom);
  const setMyQuestionOpenAtom = useSetAtom(isMyQustionOpenAtom);
  const setSelectCropAtom = useSetAtom(isSelectCropAtom);
  const setSelectedElements = useSetAtom(selectedElementsAtom);
  const setExportGifDialogAtom = useSetAtom(exportGifDialogAtom);

  const toolbarRef = useRef<HTMLDivElement>(null);

  const isToolAvailable = useMemo(() => {
    return frameInfos.length > 0;
  }, [frameInfos]);

  const handleClickMainMenu = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      if (!isToolAvailable) return;

      setMyQuestionOpenAtom(true);
    },
    [setFrameInfos, isToolAvailable]
  );

  const handleClickToolbarIcon = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      if (!isToolAvailable) return;

      const dataType = evt.currentTarget.dataset.type as EditorTool;
      setActiveTool(dataType);

      if (dataType === EditorTool.Image) {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = handleAttachImageElement;
        input.click();
      }
      if (dataType !== EditorTool.Crop) {
        setSelectCropAtom(false);
      } else {
        setSelectedElements([]);
      }
    },
    [setActiveTool, setSelectCropAtom, setSelectedElements, isToolAvailable]
  );

  const handleZoomOut = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      if (!isToolAvailable) return;

      if (frameInfos.length > 0) {
        const { width, height } = frameInfos[0].canvas;

        setCanvasStatus({
          ...canvasStatus,
          x: canvasStatus.x + width * 0.05,
          y: canvasStatus.y + height * 0.05,
          scale: clamp(canvasStatus.scale - 10, 10, 3200),
        });
      }
    },
    [canvasStatus, frameInfos, setCanvasStatus, isToolAvailable]
  );

  const handleZoomFit = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      if (!isToolAvailable) return;

      if (frameInfos.length > 0) {
        const canvasStatus = getFitImageStatus(frameInfos[0].canvas, {
          width: window.innerWidth,
          height: window.innerHeight - 290,
        } as HTMLCanvasElement);

        setCanvasStatus({
          ...canvasStatus,
          scale: 100,
        });
      }
    },
    [frameInfos, setCanvasStatus, isToolAvailable]
  );

  const handleZoomIn = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      if (!isToolAvailable) return;

      if (frameInfos.length > 0) {
        const { width, height } = frameInfos[0].canvas;

        setCanvasStatus({
          ...canvasStatus,
          x: canvasStatus.x - width * 0.05,
          y: canvasStatus.y - height * 0.05,
          scale: clamp(canvasStatus.scale + 10, 10, 3200),
        });
      }
    },
    [canvasStatus, frameInfos, setCanvasStatus, isToolAvailable]
  );

  const handleClickExport = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      if (!isToolAvailable) return;

      setExportGifDialogAtom(true);
    },
    [setExportGifDialogAtom, isToolAvailable]
  );

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();

      if (!isToolAvailable) return;

      if (event.key === '1') {
        setActiveTool(EditorTool.Select);
      } else if (event.key === '2') {
        setActiveTool(EditorTool.Crop);
      } else if (event.key === '3') {
        setActiveTool(EditorTool.FreeDraw);
      } else if (event.key === '4') {
        setActiveTool(EditorTool.Text);
      } else if (event.key === '5') {
        setActiveTool(EditorTool.Image);
      } else if (event.key === '6') {
        setActiveTool(EditorTool.Eraser);
      } else if (event.key === '7') {
        setActiveTool(EditorTool.Clock);
      }
    },
    [setActiveTool, isToolAvailable]
  );

  useKeyEvent('1', handleKeyEvent);
  useKeyEvent('2', handleKeyEvent);
  useKeyEvent('3', handleKeyEvent);
  useKeyEvent('4', handleKeyEvent);
  useKeyEvent('5', handleKeyEvent);
  useKeyEvent('6', handleKeyEvent);
  useKeyEvent('7', handleKeyEvent);
  useKeyEvent('8', handleKeyEvent);
  useKeyEvent('9', handleKeyEvent);
  useKeyEvent('0', handleKeyEvent);

  const handleClickUndo = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      if (!isToolAvailable) return;

      FrameInfoManager.undo();
      const frameInfo = FrameInfoManager.getFrameInfo();
      updateElements(frameInfo.elements);
    },
    [isToolAvailable]
  );

  const handleClickRedo = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      if (!isToolAvailable) return;

      FrameInfoManager.redo();
      const frameInfo = FrameInfoManager.getFrameInfo();
      updateElements(frameInfo.elements);
    },
    [isToolAvailable]
  );

  useEffect(() => {
    const handlePasteImage = async (evt: any) => {
      const items = evt.clipboardData.items;

      for (const item of items) {
        // 클립보드 항목이 이미지인지 확인
        console.log(item.kind);
        if (item.kind === 'file' && item.type.startsWith('image/')) {
          const file = item.getAsFile();
          handleAttachImageFile(file);
          // const url = URL.createObjectURL(file);

          // // 이미지 표시 (예: <img> 요소에 src 설정)
          // const img = document.createElement("img");
          // img.src = url;
          // console.log(url);
          // document.body.appendChild(img);
        }
      }
    };
    document.addEventListener('paste', handlePasteImage);

    return () => document.removeEventListener('paste', handlePasteImage);
  }, []);

  useEffect(() => {
    let isDown = false;
    let startX;
    let scrollLeft;

    toolbarRef.current?.addEventListener('mousedown', (e) => {
      isDown = true;
      startX = e.pageX - (toolbarRef.current?.offsetLeft || 0);
      scrollLeft = toolbarRef.current?.scrollLeft;
    });

    toolbarRef.current?.addEventListener('mouseleave', () => {
      isDown = false;
    });

    toolbarRef.current?.addEventListener('mouseup', () => {
      isDown = false;
    });

    toolbarRef.current?.addEventListener('mousemove', (e) => {
      if (!isDown) return; // 마우스가 눌리지 않았을 경우
      e.preventDefault();
      const x = e.pageX - (toolbarRef.current?.offsetLeft || 0); // 현재 위치
      const walk = (x - startX) * 2; // 스크롤 양
      if (toolbarRef.current) {
        toolbarRef.current.scrollLeft = scrollLeft - walk; // 새로운 스크롤 위치 설정
      }
    });

    // 터치 이벤트 지원
    toolbarRef.current?.addEventListener('touchstart', (e) => {
      isDown = true;
      startX = e.touches[0].pageX - (toolbarRef.current?.offsetLeft || 0); // 터치 시작 위치
      scrollLeft = toolbarRef.current?.scrollLeft; // 현재 스크롤 위치
    });

    toolbarRef.current?.addEventListener('touchend', () => {
      isDown = false;
    });

    toolbarRef.current?.addEventListener('touchmove', (e) => {
      if (!isDown) return; // 터치가 눌리지 않았을 경우
      e.preventDefault();
      const x = e.touches[0].pageX - (toolbarRef.current?.offsetLeft || 0); // 현재 위치
      const walk = (x - startX) * 2; // 스크롤 양
      if (toolbarRef.current) {
        toolbarRef.current.scrollLeft = scrollLeft - walk; // 새로운 스크롤 위치 설정
      }
    });
  }, []);

  return (
    <div className='navigation-wrapper' ref={toolbarRef}>
      <div>
        <button
          className='btn-menu'
          onClick={handleClickMainMenu}
          onTouchEnd={handleClickMainMenu}
        >
          <img src='./icons/new.svg' alt='menu' />
        </button>
        <img src='./icons/divider.svg' alt='menu' />
        {ToolbarIcons.map((tool) => (
          <button
            key={tool.key}
            className={`btn-tool ${activeTool === tool.key ? 'selected' : ''}`}
            data-type={tool.key}
            onClick={handleClickToolbarIcon}
            onTouchEnd={handleClickToolbarIcon}
            // disabled={tool.disabled}
          >
            <img src={tool.iconPath} alt={tool.key} />
          </button>
        ))}
      </div>
      <div>
        <button
          className='btn-menu'
          onClick={handleClickUndo}
          onTouchEnd={handleClickUndo}
        >
          <img src='./icons/redo.svg' alt='menu' />
        </button>
        <button
          className='btn-menu'
          onClick={handleClickRedo}
          onTouchEnd={handleClickRedo}
        >
          <img src='./icons/undo.svg' alt='menu' />
        </button>
      </div>
      <div>
        <div className='rate-area'>
          <button
            className='btn-scale'
            onClick={handleZoomOut}
            onTouchEnd={handleZoomOut}
          >
            <img src='./icons/minus.svg' alt='zoom-out' />
          </button>
          <button
            className='btn-scale text-scale'
            onClick={handleZoomFit}
            onTouchEnd={handleZoomFit}
          >{`${parseFloat(canvasStatus.scale.toFixed(2))}%`}</button>
          <button
            className='btn-scale'
            onClick={handleZoomIn}
            onTouchEnd={handleZoomIn}
          >
            <img src='./icons/plus.svg' alt='zoom-in' />
          </button>
        </div>
        <div style={{ minWidth: '130px' }}>
          <Button
            variant='contained'
            className='btn-default btn-export'
            onClick={handleClickExport}
          >
            Export To GIF
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
