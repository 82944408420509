import { atom } from 'jotai';

export enum EditorColor {
  Transparent = 'transparent',
  White = 'white',
  Black = 'black',
  'E72929' = '#E72929',
  'FF6B1F' = '#FF6B1F',
  'FFDF34' = '#FFDF34',
  '4AB906' = '#4AB906',
  '0079FF' = '#0079FF',
  '071952' = '#071952',
  '8B5AF3' = '#8B5AF3',
  'FF76B9' = '#FF76B9',
  'FF00B8' = '#FF00B8',
  '3ABEF9' = '#3ABEF9',
  '45FFCA' = '#45FFCA',
  '401F71' = '#401F71',
}

export enum EditorLineWidth {
  VeryThin = '1',
  Thin = '2',
  Thick = '4',
  VeryThick = '8',
}

export enum EditorLineBorderType {
  Solid = '8',
  Dotted = '9',
  Dashed = '10',
}

export enum EditorFontFamily {
  NanumPenScript = 'NanumPenScript',
  DoHyeon = 'DoHyeon',
  NotoSans = 'NotoSans',
  Stylish = 'Stylish',
}

export enum EditorFontSize {
  VerySmall = '24',
  Small = '36',
  Large = '48',
  VeryLarge = '60',
}

export enum EditorTextAlign {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export enum FillStyle {
  Hachure = 'hachure',
  Solid = 'solid',
  Zigzag = 'zigzag',
  CrossHatch = 'cross-hatch',
  Dots = 'dots',
  Sunburst = 'sunburst',
  Dashed = 'dashed',
  ZigzagLine = 'zigzag-line',
}

export enum StrokeDash {
  Solid = '[]',
  Dotted = '[2,2]',
  Dashed = '[5,3]',
  LongDash = '[10,5]',
  CustomDash = '[10,5,3]',
}

export enum Roundness {
  None = 'None',
  Roundness = 'Roundness',
}

export const synchronizedCropScaleAtom = atom<boolean>(true);

export const synchronizedFrameIntervalAtom = atom<boolean>(true);

export const selectedStrokeColorAtom = atom<EditorColor>(EditorColor.E72929);

export const selectedFillColorAtom = atom<EditorColor>(EditorColor.Transparent);

export const selectedLineWidthAtom = atom<number>(4);

export const selectedRoundnessAtom = atom<Roundness>(Roundness.None);

export const selectedLineBoarderAtom = atom<EditorLineBorderType>(
  EditorLineBorderType.Solid
);

export const selectedFontFamilyAtom = atom<EditorFontFamily>(
  EditorFontFamily.NotoSans
);

export const selectedFontSizeAtom = atom<EditorFontSize>(EditorFontSize.Small);

export const selectedTextAlignAtom = atom<EditorTextAlign>(
  EditorTextAlign.Left
);

export const selectedOpacityAtom = atom<number>(1);

export const selectedRoughnessAtom = atom<number>(1);

export const selectedBowingAtom = atom<number>(1);

export const selectedFillStyleAtom = atom<FillStyle>(FillStyle.Hachure);

export const selectedFillWeightAtom = atom<number>(2);

export const selectedHachureAngleAtom = atom<number>(319);

export const selectedHachureGapAtom = atom<number>(9);

export const selectedStrokeDashAtom = atom<StrokeDash>(StrokeDash.Solid);
