import { useAtom, useAtomValue } from 'jotai';
import { frameInfosAtom } from '../atoms/frameAtoms';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { exportGifDialogAtom } from '../atoms/dialogAtom';
import GIF from 'gif.js';
import { drawElement } from '../utils/canvas';
import SystemModal from './SystemModal';
import { Input } from 'semantic-ui-react';
import { loadingStatusAtom } from '../atoms/appAtoms';
import ArtifySlider from '../shared/ArtifySlider';

const ExportGifDialog = () => {
  const frameInfos = useAtomValue(frameInfosAtom);
  const [exportGifDialog, setExportGifDialog] = useAtom(exportGifDialogAtom);
  const [, setLoadingStatus] = useAtom(loadingStatusAtom);

  const [filename, setFilename] = useState<string>('gif-maker');

  const [width, setWidth] = useState<number>(500);
  const [height, setHeight] = useState<number>(300);

  const [quality, setQuality] = useState<number>(300);

  const [transparent] = useState<boolean>(false);
  // const [expectedSize, setExpectedSize] = useState<number>(0);

  const handleClickConfirm = useCallback(() => {
    setLoadingStatus(true);

    const gif = new GIF({
      workers: 2,
      quality: 1001 - quality,
      workerScript: './gif.worker.js',
    });

    frameInfos.forEach((frameInfo) => {
      const { canvas, cropInfo, delay, disabled, elements } = frameInfo;

      if (!disabled) {
        const cloneCanvas = document.createElement('canvas');
        const cloneCtx = cloneCanvas.getContext('2d');

        cloneCanvas.width = canvas.width;
        cloneCanvas.height = canvas.height;

        if (cloneCtx) {
          if (!transparent) {
            cloneCtx.drawImage(canvas, 0, 0);
          }
          elements.forEach((element) => {
            drawElement(cloneCanvas, cloneCtx, element);
          });
        }

        const frameCanvas = document.createElement('canvas');
        const frameCtx = frameCanvas.getContext('2d');

        frameCanvas.width = width;
        frameCanvas.height = height;

        if (frameCtx) {
          frameCtx.drawImage(
            cloneCanvas,
            cropInfo ? cropInfo[0] : 0,
            cropInfo ? cropInfo[1] : 0,
            cropInfo ? cropInfo[2] : canvas.width,
            cropInfo ? cropInfo[3] : canvas.height,
            0,
            0,
            frameCanvas.width,
            frameCanvas.height
          );
        }

        gif.addFrame(frameCanvas, {
          delay: delay,
        });
      }
    });

    gif.on('finished', (blob: Blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}.gif`;
      document.body.appendChild(a);
      setLoadingStatus(false);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      (window as any).gtag('event', 'gif_export', {
        name: 'export GIF',
      });
    });

    gif.render();

    setExportGifDialog(false);
  }, [frameInfos, width, height, quality, transparent, filename]);

  const handleClickCancel = useCallback(() => {
    setExportGifDialog(false);
  }, []);

  const handleChangeFilename = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setFilename(evt.currentTarget.value);
    },
    []
  );

  const handleChangeWidth = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const ratio = width / height;
      setWidth(Number(evt.currentTarget.value));
      setHeight(Number(evt.currentTarget.value) * ratio);
    },
    [width, height]
  );

  const handleChangeHeight = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const ratio = width / height;
      setHeight(Number(evt.currentTarget.value));
      setWidth(Number(evt.currentTarget.value) / ratio);
    },
    [width, height]
  );

  const handleChangeQuality = useCallback((_e: any, value: any) => {
    console.log(value);
    setQuality(value);
  }, []);

  const handleChangeSize = useCallback(
    (_e: any, value: any) => {
      const width = frameInfos[0].cropInfo?.[2] || frameInfos[0].canvas.width;
      const height = frameInfos[0].cropInfo?.[3] || frameInfos[0].canvas.height;

      const ratio = width / height;

      const nw = width * value;
      const nh = nw / ratio;

      setWidth(Math.round(nw));
      setHeight(Math.round(nh));
    },
    [frameInfos]
  );

  // const handleChangeTransparent = useCallback(() => {
  //   setTransparent(!transparent);
  // }, [transparent]);

  useEffect(() => {
    const { canvas, cropInfo } = frameInfos?.[0] || {};

    if (cropInfo) {
      setWidth(cropInfo?.[2] || 0);
      setHeight(cropInfo?.[3] || 0);
    } else {
      setWidth(canvas?.width);
      setHeight(canvas?.height);
    }
  }, [exportGifDialog]);

  // useEffect(() => {
  //   setExpectedSize(
  //     estimateGifFileSize(width, height, frameInfos?.length || 0, quality)
  //   );
  // }, [width, height, quality, frameInfos]);

  return (
    <SystemModal
      open={exportGifDialog}
      title='Export GIF'
      onClickConfirm={handleClickConfirm}
      onClickCancel={handleClickCancel}
      onClose={handleClickCancel}
      width={600}
    >
      <div className='workspace-wrapper'>
        <div className='workspace-info'>
          <div>Filename:</div>
          <div>
            <Input
              value={filename}
              onChange={handleChangeFilename}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>

      <div className='workspace-wrapper'>
        <div className='workspace-info'>
          <div>Gif Size:</div>
          <div>
            <Input
              type='number'
              value={width}
              onChange={handleChangeWidth}
              style={{ width: '150px' }}
            />{' '}
            <span className='multiply'>X</span>
            <Input
              type='number'
              value={height}
              onChange={handleChangeHeight}
              style={{ width: '150px' }}
            />
          </div>
          <div className='slider'>
            <ArtifySlider
              defaultValue={1}
              step={0.1}
              min={0.1}
              max={3}
              onChange={handleChangeSize}
            />
          </div>
        </div>
      </div>

      <div className='workspace-info'>
        <span>Quality:</span>
        <div style={{ flexGrow: 1 }}>
          <ArtifySlider
            value={quality}
            step={100}
            min={1}
            max={1000}
            onChange={handleChangeQuality}
          />
        </div>
      </div>

      {/* <div className="workspace-info">
        <span>Transparent:</span>
        <div style={{ flexGrow: 1 }}>
          <Checkbox value={transparent} onChange={handleChangeTransparent} />
        </div>
      </div> */}

      {/* <div className='workspace-info'>
        <span>Expected File Size:</span>
        // <span>{Math.floor(expectedSize / 4 / 1000).toLocaleString()} kb</span>
      </div> */}
    </SystemModal>
  );
};

export default ExportGifDialog;
