import { inRange } from 'lodash';
import { Element } from '../atoms/frameAtoms';
import { DISTANCE_THRESHOLD, Polycurve, Rect, getCurveShape } from './shape';
import { EditorTool } from '../atoms/appAtoms';
import { RoughGenerator } from 'roughjs/bin/generator';
import { getElementBoundingRect } from './element';

type Point = [number, number];

type Line = [Point, Point];

type Polyline = Line[];

export type Ellipse = {
  center: Point;
  angle: number;
  halfWidth: number;
  halfHeight: number;
};

export type Curve = [Point, Point, Point, Point];
const DEFAULT_THRESHOLD = 10;

export const inRectRange = (pointer: Point, rect: Rect) => {
  return (
    inRange(pointer[0], rect[0], rect[0] + rect[2]) &&
    inRange(pointer[1], rect[1], rect[1] + rect[3])
  );
};

export const getDistancePoints = (p1: Point, p2: Point) => {
  const dx = p2[0] - p1[0];
  const dy = p2[1] - p1[1];

  return Math.sqrt(dx * dx + dy * dy);
};

export const pointOnPolyline = (
  point: Point,
  polyline: Polyline,
  threshold = DEFAULT_THRESHOLD
) => {
  return polyline.some((line) => pointOnLine(point, line, threshold));
};

export const pointOnLine = (
  point: Point,
  line: Line,
  threshold = DEFAULT_THRESHOLD
) => {
  const distance = distanceToSegment(point, line);

  if (distance === 0) {
    return true;
  }

  return distance < threshold;
};

export const cubicBezierEquation = (curve: Curve) => {
  const [p0, p1, p2, p3] = curve;
  // B(t) = p0 * (1-t)^3 + 3p1 * t * (1-t)^2 + 3p2 * t^2 * (1-t) + p3 * t^3
  return (t: number, idx: number) =>
    Math.pow(1 - t, 3) * p3[idx] +
    3 * t * Math.pow(1 - t, 2) * p2[idx] +
    3 * Math.pow(t, 2) * (1 - t) * p1[idx] +
    p0[idx] * Math.pow(t, 3);
};

export const polyLineFromCurve = (curve: Curve, segments = 10): Polyline => {
  const equation = cubicBezierEquation(curve);
  let startingPoint = [equation(0, 0), equation(0, 1)] as Point;
  const lineSegments: Polyline = [];
  let t = 0;
  const increment = 1 / segments;

  for (let i = 0; i < segments; i++) {
    t += increment;
    if (t <= 1) {
      const nextPoint: Point = [equation(t, 0), equation(t, 1)];
      lineSegments.push([startingPoint, nextPoint]);
      startingPoint = nextPoint;
    }
  }

  return lineSegments;
};

export const pointOnCurve = (
  point: Point,
  curve: Curve,
  threshold = DEFAULT_THRESHOLD
) => {
  return pointOnPolyline(point, polyLineFromCurve(curve), threshold);
};

export const pointOnPolycurve = (
  point: Point,
  polycurve: Polycurve,
  threshold = DEFAULT_THRESHOLD
) => {
  return polycurve.some((curve) => pointOnCurve(point, curve, threshold));
};

export const isClosed = (polygon: Point[]) => {
  const first = polygon[0];
  const last = polygon[polygon.length - 1];
  return first[0] === last[0] && first[1] === last[1];
};

export const close = (polygon: Point[]) => {
  return isClosed(polygon) ? polygon : [...polygon, polygon[0]];
};

export const pointOnPolygon = (
  point: Point,
  polygon: Point[],
  threshold = DEFAULT_THRESHOLD
) => {
  let on = false;
  const closed = close(polygon);

  for (let i = 0, l = closed.length - 1; i < l; i++) {
    if (pointOnLine(point, [closed[i], closed[i + 1]], threshold)) {
      on = true;
      break;
    }
  }

  return on;
};

export const distanceToSegment = (point: Point, line: Line) => {
  const [x, y] = point;
  const [[x1, y1], [x2, y2]] = line;

  const A = x - x1;
  const B = y - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  const dot = A * C + B * D;
  const len_sq = C * C + D * D;
  let param = -1;
  if (len_sq !== 0) {
    param = dot / len_sq;
  }

  let xx;
  let yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  const dx = x - xx;
  const dy = y - yy;
  return Math.sqrt(dx * dx + dy * dy);
};

export const polylineFromPoints = (points: Point[]) => {
  let previousPoint = points[0];
  const polyline: Polyline = [];

  for (let i = 1; i < points.length; i++) {
    const nextPoint = points[i];
    polyline.push([previousPoint, nextPoint]);
    previousPoint = nextPoint;
  }

  return polyline;
};

export const getTransformRectFromOnePoint = (
  originalRect: Rect,
  diff: Point,
  pointIndex: number
) => {
  const rect = [...originalRect] as Rect;
  if (pointIndex === 0) {
    rect[0] += diff[0];
    rect[1] += diff[1];
    rect[2] -= diff[0];
    rect[3] -= diff[1];
  } else if (pointIndex === 1) {
    rect[1] += diff[1];
    rect[2] += diff[0];
    rect[3] -= diff[1];
  } else if (pointIndex === 2) {
    rect[0] += diff[0];
    rect[2] -= diff[0];
    rect[3] += diff[1];
  } else if (pointIndex === 3) {
    rect[2] += diff[0];
    rect[3] += diff[1];
  }

  return rect;
};

export const getTransformedBoundingRect = (
  boundingRect: Rect,
  diff: Point,
  updatePoint: {
    type: string;
    index: number;
    point: Point;
  }
) => {
  const updatedBoundRect = [...boundingRect] as Rect;

  let [cx, cy] = [
    updatedBoundRect[0] + updatedBoundRect[2] / 2,
    updatedBoundRect[1] + updatedBoundRect[3] / 2,
  ];

  if (updatePoint.index === -1) {
    cx += diff[0];
    cy += diff[1];
  } else if (
    updatePoint.index === 0 ||
    updatePoint.index === 1 ||
    updatePoint.index === 2 ||
    updatePoint.index === 3
  ) {
    cx += diff[0] / 2;
    cy += diff[1] / 2;

    updatedBoundRect[2] +=
      updatePoint.index === 1 || updatePoint.index === 3 ? diff[0] : -diff[0];
    updatedBoundRect[3] +=
      updatePoint.index === 2 || updatePoint.index === 3 ? diff[1] : -diff[1];
  } else if (updatePoint.index === 4 || updatePoint.index === 5) {
    const normalVector = rotatePoint([0, 0], [0, -1], 0);
    const projectionPoint = projection(normalVector, diff);

    cy += (normalVector[1] * projectionPoint) / 2;
    updatedBoundRect[3] += updatePoint.index === 5 ? diff[1] : -diff[1];
  } else if (updatePoint.index === 6 || updatePoint.index === 7) {
    const normalVector = rotatePoint([0, 0], [1, 0], 0);
    const projectionPoint = projection(normalVector, diff);

    cx += (normalVector[0] * projectionPoint) / 2;
    updatedBoundRect[2] += updatePoint.index === 7 ? diff[0] : -diff[0];
  }

  updatedBoundRect[0] = cx - updatedBoundRect[2] / 2;
  updatedBoundRect[1] = cy - updatedBoundRect[3] / 2;

  return updatedBoundRect;
};

export const internalDivisionPoint = (
  A: Point,
  B: Point,
  m: number,
  n: number
): Point => {
  const x = (n * A[0] + m * B[0]) / (m + n);
  const y = (n * A[1] + m * B[1]) / (m + n);
  return [x, y];
};

export const externalDivisionPoint = (
  A: Point,
  B: Point,
  m: number,
  n: number
): Point => {
  const x = (n * A[0] - m * B[0]) / (n - m);
  const y = (n * A[1] - m * B[1]) / (n - m);
  return [x, y];
};

export const rotatePoint = (
  center: Point,
  point: Point,
  radians: number
): Point => {
  return [
    (point[0] - center[0]) * Math.cos(radians) -
      (point[1] - center[1]) * Math.sin(radians) +
      center[0],
    (point[0] - center[0]) * Math.sin(radians) +
      (point[1] - center[1]) * Math.cos(radians) +
      center[1],
  ];
};

export const projection = (p1: Point, p2: Point) => {
  const distance = Math.sqrt(p1[0] * p1[0] + p1[1] * p1[1]);
  const dotProduct = p1[0] * p2[0] + p1[1] * p2[1];

  return dotProduct / distance;
};

export const transformPointsByRectangleSize = (
  points: Point[],
  originalWidth: number,
  originalHeight: number,
  newWidth: number,
  newHeight: number,
  refPoint?: Point,
  refPoint2?: Point
): Point[] => {
  const transformedPoints = points.map(([x, y]) => {
    const newX = ((x - (refPoint?.[0] || 0)) / originalWidth) * newWidth;
    const newY = ((y - (refPoint?.[1] || 0)) / originalHeight) * newHeight;
    return [
      newX + (refPoint2?.[0] || 0),
      newY + (refPoint2?.[1] || 0),
    ] as Point;
  });

  return transformedPoints;
};

export const calculateRotationAngle = (center: Point, point: Point): number => {
  const dx = point[0] - center[0];
  const dy = point[1] - center[1];
  const angleInRadians = Math.atan2(dy, dx);

  return angleInRadians + Math.PI / 2;
};

export const pointOnEllipse = (
  point: Point,
  ellipse: Ellipse,
  threshold = DEFAULT_THRESHOLD
) => {
  return distanceToEllipse(point, ellipse) <= threshold;
};

export const isPointInEllipse = (point: Point, ellipse: Ellipse): boolean => {
  const dx = point[0] - ellipse.center[0];
  const dy = point[1] - ellipse.center[1];

  const normX = dx / ellipse.halfWidth;
  const normY = dy / ellipse.halfHeight;

  return normX * normX + normY * normY <= 1;
};

const distanceToEllipse = (point: Point, ellipse: Ellipse) => {
  const { angle, halfWidth, halfHeight, center } = ellipse;
  const a = halfWidth;
  const b = halfHeight;
  const [rotatedPointX, rotatedPointY] = pointRelativeToCenter(
    point,
    center,
    angle
  );

  const px = Math.abs(rotatedPointX);
  const py = Math.abs(rotatedPointY);

  let tx = 0.707;
  let ty = 0.707;

  for (let i = 0; i < 3; i++) {
    const x = a * tx;
    const y = b * ty;

    const ex = ((a * a - b * b) * tx ** 3) / a;
    const ey = ((b * b - a * a) * ty ** 3) / b;

    const rx = x - ex;
    const ry = y - ey;

    const qx = px - ex;
    const qy = py - ey;

    const r = Math.hypot(ry, rx);
    const q = Math.hypot(qy, qx);

    tx = Math.min(1, Math.max(0, ((qx * r) / q + ex) / a));
    ty = Math.min(1, Math.max(0, ((qy * r) / q + ey) / b));
    const t = Math.hypot(ty, tx);
    tx /= t;
    ty /= t;
  }

  const [minX, minY] = [
    a * tx * Math.sign(rotatedPointX),
    b * ty * Math.sign(rotatedPointY),
  ];

  return distanceToPoint([rotatedPointX, rotatedPointY], [minX, minY]);
};

export const distanceToPoint = (p1: Point, p2: Point) => {
  return distance2d(...p1, ...p2);
};

export const distance2d = (x1: number, y1: number, x2: number, y2: number) => {
  const xd = x2 - x1;
  const yd = y2 - y1;
  return Math.hypot(xd, yd);
};

export const pointRelativeToCenter = (
  point: Point,
  center: Point,
  angle: number
): Point => {
  const translated = pointAdd(point, pointInverse(center));
  const rotated = rotatePoint([0, 0], translated, -angle);

  return rotated;
};

export const pointInverse = (point: Point) => {
  return [-point[0], -point[1]] as Point;
};

export const pointAdd = (pointA: Point, pointB: Point): Point => {
  return [pointA[0] + pointB[0], pointA[1] + pointB[1]];
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const isPointOnElement = (point: Point, element: Element) => {
  const generator = new RoughGenerator();

  switch (element.type) {
    case EditorTool.FreeDraw: {
      const polyline = polylineFromPoints(
        element.points.map((point) =>
          rotatePoint([element.cx, element.cy], point, element.angle)
        )
      );

      const isOnElement = pointOnPolyline(point, polyline, DISTANCE_THRESHOLD);

      if (isOnElement) {
        return element;
      }
      break;
    }
    case EditorTool.PolyLine: {
      const _shape = [
        generator.curve([...element.points], element.roughOptions),
      ];

      const shape = getCurveShape(
        _shape[0],
        [element.cx, element.cy],
        element.angle
      );

      if (shape.type === 'polycurve') {
        const isOnElement = pointOnPolycurve(
          point,
          shape.data,
          DISTANCE_THRESHOLD
        );

        if (isOnElement) {
          return element;
        }
      }
      break;
    }
    case EditorTool.Rectangle: {
      const p = rotatePoint([element.cx, element.cy], point, -element.angle);

      if (
        inRange(p[0], element.x, element.x + element.width) &&
        inRange(p[1], element.y, element.y + element.height)
      ) {
        return element;
      }

      break;
    }
    case EditorTool.Ellipse: {
      const p = rotatePoint([element.cx, element.cy], point, -element.angle);
      const boundingRect = getElementBoundingRect(element);

      const shape = {
        type: 'ellipse',
        data: {
          center: [
            boundingRect[0] + boundingRect[2] / 2,
            boundingRect[1] + boundingRect[3] / 2,
          ] as Point,
          angle: element.angle,
          halfWidth: boundingRect[2] / 2,
          halfHeight: boundingRect[3] / 2,
        },
      };

      const isInEllipse = isPointInEllipse(p, shape.data);

      if (isInEllipse) {
        return element;
      }

      break;
    }
    case EditorTool.Text:
    case EditorTool.Image: {
      const p = rotatePoint([element.cx, element.cy], point, -element.angle);

      if (
        inRange(p[0], element.x, element.x + element.width) &&
        inRange(p[1], element.y, element.y + element.height)
      ) {
        return element;
      }

      break;
    }
    default:
      break;
  }
};
