import { Element } from "../atoms/frameAtoms";

type HistoryAction =
  | { action: "add"; element: Element }
  | { action: "add-multiple"; elements: Element[] }
  | { action: "update"; element: Element; previousElement: Element }
  | {
      action: "update-multiple";
      elements: Element[];
      previousElements: Element[];
    }
  | { action: "delete"; element: Element }
  | { action: "delete-multiple"; elements: Element[] };

export class HistoryManager {
  private history: HistoryAction[] = [];
  private redoStack: HistoryAction[] = [];

  addAction(action: HistoryAction) {
    this.history.push(action);
    this.redoStack = [];
  }

  undo(): HistoryAction | undefined {
    const action = this.history.pop();
    if (action) {
      this.redoStack.push(action);
    }
    return action;
  }

  redo(): HistoryAction | undefined {
    const action = this.redoStack.pop();
    if (action) {
      this.history.push(action);
    }
    return action;
  }
}
