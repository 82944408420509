import { atom } from 'jotai';

export enum EditorTool {
  Select = 'Select',
  Crop = 'Crop',
  Rectangle = 'Rectangle',
  Ellipse = 'Ellipse',
  PolyLine = 'PolyLine',
  FreeDraw = 'FreeDraw',
  Text = 'Text',
  Image = 'Image',
  Emoticon = 'Emoticon',
  Eraser = 'Eraser',
  Clock = 'Clock',
}

export const activeToolAtom = atom<EditorTool>(EditorTool.Select);

export const mainMenuStatusAtom = atom<boolean>(false);

export const selectedVideoFileAtom = atom<File | null>(null);

export const loadingStatusAtom = atom<boolean>(false);

export const isPreviewOpenAtom = atom<boolean>(false);

export const isMyQustionOpenAtom = atom<boolean>(false);

export const isSelectCropAtom = atom<boolean>(false);
