import { useAtomValue } from "jotai";
import "../styles/Loading.scss";
import { loadingStatusAtom } from "../atoms/appAtoms";

const Loading = () => {
  const loadingStatus = useAtomValue(loadingStatusAtom);

  if (!loadingStatus) {
    return null;
  }

  return (
    <div className="loader_wrapper">
      <div className="loader" />
      <div id="load-message" className="load-message"></div>
    </div>
  );
};

export default Loading;
