export const DEFAULT_PROPORTIONAL_RADIUS = 0.25;
export const DEFAULT_ADAPTIVE_RADIUS = 32;

export const getCornerRadius = (x: number) => {
  const fixedRadiusSize = DEFAULT_ADAPTIVE_RADIUS;

  const CUTOFF_SIZE = fixedRadiusSize / DEFAULT_PROPORTIONAL_RADIUS;

  if (x <= CUTOFF_SIZE) {
    return x * DEFAULT_PROPORTIONAL_RADIUS;
  }

  return fixedRadiusSize;
};

export const getTransform = (width: number, height: number, scale: number) => {
  const translateX = (width * (scale - 1)) / 2;
  const translateY = (height * (scale - 1)) / 2;

  return `translate(${translateX}px, ${translateY}px) scale(${scale})`;
};
