import { atom } from 'jotai';
import { EditorTool } from './appAtoms';
import { EditorFontFamily, EditorFontSize, Roundness } from './optionAtom';
import { Options, Point, Rect } from '../utils/shape';

export type CanvasOptions = {
  fillStyle?: string;
  strokeStyle?: string;
  globalAlpha?: number;
  globalCompositeOperation?: GlobalCompositeOperation;
  lineCap?: CanvasLineCap;
  lineJoin?: CanvasLineJoin;
  lineWidth?: number;
  dashed?: number[];
};

export type BasicElement = {
  id: string;

  x: number;
  y: number;
  cx: number;
  cy: number;
  width: number;
  height: number;

  angle: number;
  canvasOptions: CanvasOptions;
};

export type FreeDrawElement = BasicElement & {
  type: EditorTool.FreeDraw;

  points: Point[];
};

export type PolyLineElement = BasicElement & {
  type: EditorTool.PolyLine;

  points: Point[];
  roughOptions: Options;
};

export type BoundingRectElement = BasicElement & {
  type: EditorTool.Rectangle | EditorTool.Ellipse;

  roughOptions: Options;
  round: Roundness;
};

export type TextElement = BasicElement & {
  type: EditorTool.Text;

  text: string;
  font: EditorFontFamily;
  fontSize: EditorFontSize;
};

export type ImageElement = Omit<BasicElement, 'option'> & {
  type: EditorTool.Image | EditorTool.Emoticon;

  image: HTMLImageElement;
};

export type Element =
  | FreeDrawElement
  | PolyLineElement
  | BoundingRectElement
  | TextElement
  | ImageElement;

export type FrameInfo = {
  id: string;
  canvas: HTMLCanvasElement;
  elements: Element[];
  cropInfo?: Rect | null;
  disabled: boolean;
  delay: number;
};

export const frameInfosAtom = atom<FrameInfo[]>([]);

export const frameIndexAtom = atom<number>(0);

export const selectedElementsAtom = atom<Element[]>([]);
