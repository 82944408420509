import { useEffect } from "react";

type KeyHandler = (event: KeyboardEvent) => void;

function useKeyEvent(key: string, handler: KeyHandler) {
  useEffect(() => {
    const handleKeyEvent = (event: KeyboardEvent) => {
      if (
        (event.target as any).tagName === "INPUT" ||
        (event.target as any).tagName === "TEXTAREA"
      ) {
        return;
      }

      if (event.key === key) {
        handler(event);
      }
    };

    window.addEventListener("keydown", handleKeyEvent);
    return () => {
      window.removeEventListener("keydown", handleKeyEvent);
    };
  }, [key, handler]);
}

export default useKeyEvent;
