import { StrokeOptions, getStroke } from 'perfect-freehand';

export function generateFreeDrawShape(
  points: [number, number][],
  lineWidth: number
) {
  const svgPathData = getFreeDrawSvgPath(points, lineWidth);
  const path = new Path2D(svgPathData);
  return path;
}

export function getFreeDrawSvgPath(
  points: [number, number][],
  lineWidth: number
) {
  // If input points are empty (should they ever be?) return a dot
  const inputPoints = points.map((point) => [point[0], point[1], 0.1]);

  // Consider changing the options for simulated pressure vs real pressure
  const options: StrokeOptions = {
    simulatePressure: true,
    size: Number(lineWidth) * 4.25,
    thinning: 0.6,
    smoothing: 1,
    streamline: 0.5,
    easing: (t) => Math.sin((t * Math.PI) / 2), // https://easings.net/#easeOutSine
    last: false, // LastCommittedPoint is added on pointerup
  };

  return getSvgPathFromStroke(getStroke(inputPoints as number[][], options));
}

function med(A: number[], B: number[]) {
  return [(A[0] + B[0]) / 2, (A[1] + B[1]) / 2];
}

const TO_FIXED_PRECISION = /(\s?[A-Z]?,?-?[0-9]*\.[0-9]{0,2})(([0-9]|e|-)*)/g;

function getSvgPathFromStroke(points: number[][]): string {
  if (!points.length) {
    return '';
  }

  const max = points.length - 1;

  return points
    .reduce(
      (acc, point, i, arr) => {
        if (i === max) {
          acc.push(point, med(point, arr[0]), 'L', arr[0], 'Z');
        } else {
          acc.push(point, med(point, arr[i + 1]));
        }
        return acc;
      },
      ['M', points[0], 'Q']
    )
    .join(' ')
    .replace(TO_FIXED_PRECISION, '$1');
}
