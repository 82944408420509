import { Button } from '@mui/material';
import '../styles/NewDocument.scss';
import { useSetAtom } from 'jotai';
import { newWorkspaceDialogAtom } from '../atoms/dialogAtom';
import { useCallback } from 'react';
import { openFile } from '../utils/file';

const DocumentItem = ({
  thumbnailUrl,
  title,
  description,
  buttonTitle,
  onClickButton,
}: {
  thumbnailUrl: string;
  title: string;
  description: string;
  buttonTitle: string;
  onClickButton: VoidFunction;
}) => {
  return (
    <div className='document-item'>
      <div className='document-thmbnail'>
        <img src={thumbnailUrl} alt='' />
      </div>
      <div className='document-title'>
        <span>{title}</span>
      </div>
      <div className='document-description'>
        <span>{description}</span>
      </div>
      <div className='document-action'>
        <Button variant='contained' onClick={onClickButton}>
          {buttonTitle}
        </Button>
      </div>
    </div>
  );
};

const NewDocument = () => {
  const setNewWorkspaceDialog = useSetAtom(newWorkspaceDialogAtom);

  const handleClickNew = useCallback(() => {
    setNewWorkspaceDialog(true);
  }, [setNewWorkspaceDialog]);

  const handleClickOpen = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*, image/gif';
    input.onchange = openFile;
    input.click();
  }, []);

  return (
    <div className='wrapper'>
      <div className='main-content'>
        <div>
          <img src='./assets/logo.png' alt='' width={200} />
        </div>
        <div>
          <DocumentItem
            thumbnailUrl='./assets/create.png'
            title='New Workspace'
            description='Create your own GIF by creating a workspace for the purpose'
            buttonTitle='Create'
            onClickButton={handleClickNew}
          />
          <DocumentItem
            thumbnailUrl='./assets/open.png'
            title='Open Image or Video'
            description='Make a GIF using the images and videos you have'
            buttonTitle='Select file'
            onClickButton={handleClickOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default NewDocument;
