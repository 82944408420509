import { useCallback, useEffect, useRef, useState } from 'react';
import { frameInfosAtom } from '../atoms/frameAtoms';
import { useAtom } from 'jotai';
import { isPreviewOpenAtom } from '../atoms/appAtoms';

import { drawElement, getFitImageStatus } from '../utils/canvas';
import SystemModal from './SystemModal';

import '../styles/PreviewDialog.scss';

const CANVAS_WIDTH = 600 - 48;
const CANVAS_HEIGHT = (CANVAS_WIDTH * 9) / 16;

let imageInfo = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  scale: 100,
};
const PreviewDialog = () => {
  const [frameInfos] = useAtom(frameInfosAtom);
  const [isPreviewOpen, setPreviewOpen] = useAtom(isPreviewOpenAtom);

  const [isPlay, setPlay] = useState<boolean>(true);

  const intervalIdRef = useRef<number>(0);
  const drawIndexRef = useRef<number>(0);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);

  const handleClosePreview = useCallback(() => {
    setPreviewOpen(false);
  }, []);

  const drawFrameInfo = useCallback(() => {
    if (
      frameInfos.length > 0 &&
      canvasRef.current &&
      ctxRef.current &&
      isPlay
    ) {
      const frameInfo = frameInfos[drawIndexRef.current % frameInfos.length];

      const { canvas, delay, disabled, cropInfo, elements } = frameInfo;

      if (disabled) {
        drawIndexRef.current += 1;

        intervalIdRef.current = window.setTimeout(() => {
          drawFrameInfo();
        }, 0);
        return;
      }

      const cloneCanvas = document.createElement('canvas');
      const cloneCtx = cloneCanvas.getContext('2d');

      cloneCanvas.width = canvas.width;
      cloneCanvas.height = canvas.height;

      if (cloneCtx) {
        cloneCtx.drawImage(canvas, 0, 0);
        elements.forEach((element) => {
          drawElement(cloneCanvas, cloneCtx, element);
        });
      }

      const width = cropInfo ? cropInfo[2] : canvas.width;
      const height = cropInfo ? cropInfo[3] : canvas.height;

      imageInfo.width = width;
      imageInfo.height = height;

      const fitImageStatus = getFitImageStatus(
        cropInfo
          ? ({ width: cropInfo[2], height: cropInfo[3] } as HTMLCanvasElement)
          : cloneCanvas,
        canvasRef.current
      );

      imageInfo = { ...imageInfo, ...fitImageStatus };

      ctxRef.current.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      if (cropInfo) {
        ctxRef.current.drawImage(
          cloneCanvas,
          cropInfo[0],
          cropInfo[1],
          cropInfo[2],
          cropInfo[3],
          imageInfo.x,
          imageInfo.y,
          imageInfo.width * imageInfo.scale * 0.01,
          imageInfo.height * imageInfo.scale * 0.01
        );
      } else {
        ctxRef.current.drawImage(
          cloneCanvas,
          imageInfo.x,
          imageInfo.y,
          imageInfo.width * imageInfo.scale * 0.01,
          imageInfo.height * imageInfo.scale * 0.01
        );
      }

      drawIndexRef.current += 1;

      intervalIdRef.current = window.setTimeout(() => {
        drawFrameInfo();
      }, delay);
    } else {
      setPlay((prev) => !prev);
    }
  }, [frameInfos, isPreviewOpen]);

  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      ctxRef.current = canvasRef.current.getContext('2d');
    }
  }, [isPreviewOpen]);

  useEffect(() => {
    if (isPreviewOpen) {
      intervalIdRef.current = window.setTimeout(() => {
        drawFrameInfo();
      }, 0);
    } else {
      clearTimeout(intervalIdRef.current);
    }
    return () => {
      clearTimeout(intervalIdRef.current);
    };
  }, [drawFrameInfo, isPreviewOpen]);

  const width = Math.min(window.innerWidth - 48 - 28, 600);

  return (
    <SystemModal
      open={isPreviewOpen}
      title='Preview'
      onClickConfirm={handleClosePreview}
      onClose={handleClosePreview}
      confirmText='Close'
      width={600}
    >
      <div className='canvas-wrapper'>
        <canvas ref={canvasRef} width={width} height={CANVAS_HEIGHT} />
      </div>
    </SystemModal>
  );
};

export default PreviewDialog;
