import { Button } from '@mui/material';
import {
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from 'semantic-ui-react';

import '../styles/SystemModal.scss';

type SystemModalProps = {
  open: boolean;
  title: string;
  onClickConfirm: VoidFunction;
  onClickCancel?: VoidFunction;
  onClose: VoidFunction;
  cancelText?: string;
  confirmText?: string;
  width?: number;
};

const SystemModal = (props: React.PropsWithChildren<SystemModalProps>) => {
  const {
    open,
    title,
    onClickConfirm,
    onClickCancel,
    onClose,
    cancelText,
    confirmText,
    children,
  } = props;

  let { width = 900 } = props;

  const isMobileSize = window.innerWidth < 712;

  if (isMobileSize) {
    width = window.innerWidth - 48;
  }

  return (
    <Modal open={open} onClose={onClose} style={{ width: `${width}px` }}>
      <ModalHeader>{title}</ModalHeader>
      <ModalContent>{children}</ModalContent>
      <ModalActions>
        {onClickCancel && (
          <Button
            className='btn-close'
            variant='outlined'
            onClick={onClickCancel}
            onTouchEnd={onClickCancel}
          >
            {cancelText ?? 'Close'}
          </Button>
        )}
        <Button
          variant='contained'
          onClick={onClickConfirm}
          onTouchEnd={onClickConfirm}
          style={{ marginLeft: '8px' }}
        >
          {confirmText ?? 'Confirm'}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default SystemModal;
