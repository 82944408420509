import { Random } from 'roughjs/bin/math';
import { Element } from '../atoms/frameAtoms';
import { rotatePoint } from './geometry';
import { max, min } from 'lodash';
import { generateFreeDrawShape } from './render';
import {
  selectedBowingAtom,
  selectedFillColorAtom,
  selectedFillStyleAtom,
  selectedFillWeightAtom,
  selectedHachureAngleAtom,
  selectedHachureGapAtom,
  selectedLineWidthAtom,
  selectedRoughnessAtom,
  selectedStrokeColorAtom,
} from '../atoms/optionAtom';
import { RoughGenerator } from 'roughjs/bin/generator';
import { getDefaultStore } from 'jotai';

export const DISTANCE_THRESHOLD = 10;

export type Point = [number, number];

export type Line = [Point, Point];

export type Polyline = Line[];

export type Polygon = Point[];

// export type Ellipse = [number, number, number, number];

export type Rect = [number, number, number, number];

// a line (segment) is defined by two endpoints

// a polyline (made up term here) is a line consisting of other line segments
// this corresponds to a straight line element in the editor but it could also
// be used to model other elements

// a polygon is a closed shape by connecting the given points
// rectangles and diamonds are modelled by polygons

// an ellipse is specified by its center, angle, and its major and minor axes
// but for the sake of simplicity, we've used halfWidth and halfHeight instead
// in replace of semi major and semi minor axes
export type Ellipse = {
  center: Point;
  angle: number;
  halfWidth: number;
  halfHeight: number;
};

export interface Options {
  maxRandomnessOffset?: number;
  roughness?: number;
  bowing?: number;
  stroke?: string;
  strokeWidth?: number;
  curveFitting?: number;
  curveTightness?: number;
  curveStepCount?: number;
  fill?: string;
  fillStyle?: string;
  fillWeight?: number;
  hachureAngle?: number;
  hachureGap?: number;
  simplification?: number;
  dashOffset?: number;
  dashGap?: number;
  zigzagOffset?: number;
  seed?: number;
  strokeLineDash?: number[];
  strokeLineDashOffset?: number;
  fillLineDash?: number[];
  fillLineDashOffset?: number;
  disableMultiStroke?: boolean;
  disableMultiStrokeFill?: boolean;
  preserveVertices?: boolean;
  fixedDecimalPlaceDigits?: number;
  fillShapeRoughnessGain?: number;
}

export interface ResolvedOptions extends Options {
  maxRandomnessOffset: number;
  roughness: number;
  bowing: number;
  stroke: string;
  strokeWidth: number;
  curveFitting: number;
  curveTightness: number;
  curveStepCount: number;
  fillStyle: string;
  fillWeight: number;
  hachureAngle: number;
  hachureGap: number;
  dashOffset: number;
  dashGap: number;
  zigzagOffset: number;
  seed: number;
  randomizer?: Random;
  disableMultiStroke: boolean;
  disableMultiStrokeFill: boolean;
  preserveVertices: boolean;
  fillShapeRoughnessGain: number;
}

export declare type OpType = 'move' | 'bcurveTo' | 'lineTo';
export declare type OpSetType = 'path' | 'fillPath' | 'fillSketch';
export interface Op {
  op: OpType;
  data: number[];
}

export interface OpSet {
  type: OpSetType;
  ops: Op[];
  size?: Point;
  path?: string;
}

export interface Drawable {
  shape: string;
  options: ResolvedOptions;
  sets: OpSet[];
}

export type GeometricShape =
  | {
      type: 'line';
      data: Line;
    }
  | {
      type: 'polygon';
      data: Polygon;
    }
  | {
      type: 'curve';
      data: Curve;
    }
  | {
      type: 'ellipse';
      data: Ellipse;
    }
  | {
      type: 'polyline';
      data: Polyline;
    }
  | {
      type: 'polycurve';
      data: Polycurve;
    };

export type Curve = [Point, Point, Point, Point];
export type Polycurve = Curve[];

const store = getDefaultStore();

export const generateRoughOptions = (): Options => {
  const selectedRoughness = store.get(selectedRoughnessAtom);
  const selectedBowing = store.get(selectedBowingAtom);
  const selectedFillStyle = store.get(selectedFillStyleAtom);
  const selectedFillWeight = store.get(selectedFillWeightAtom);
  const selectedHachureAngle = store.get(selectedHachureAngleAtom);
  const selectedHachureGap = store.get(selectedHachureGapAtom);
  const selectedLineWidth = store.get(selectedLineWidthAtom);
  const selectedStrokeColor = store.get(selectedStrokeColorAtom);
  const selectedFillColor = store.get(selectedFillColorAtom);

  return {
    disableMultiStroke: false,
    preserveVertices: true,
    seed: 1016936050,
    stroke: selectedStrokeColor,
    strokeWidth: selectedLineWidth,
    roughness: selectedRoughness,
    bowing: selectedBowing,
    fillStyle: selectedFillStyle,
    fillWeight: selectedFillWeight,
    hachureAngle: selectedHachureAngle,
    hachureGap: selectedHachureGap,
    fill: selectedFillColor,
  };
};

export const getRoundRectanglePath = (rect: Rect, round: number) => {
  return `M ${rect[0] + round} ${rect[1]} 
            L ${rect[0] + rect[2] - round} ${rect[1]} 
            Q ${rect[0] + rect[2]} ${rect[1]}, ${rect[0] + rect[2]} ${rect[1] + round} 
            L ${rect[0] + rect[2]} ${rect[1] + rect[3] - round} 
            Q ${rect[0] + rect[2]} ${rect[1] + rect[3]}, ${rect[0] + rect[2] - round} ${rect[1] + rect[3]} 
            L ${rect[0] + round} ${rect[1] + rect[3]} 
            Q ${rect[0]} ${rect[1] + rect[3]}, ${rect[0]} ${rect[1] + rect[3] - round} 
            L ${rect[0]} ${rect[1] + round} 
            Q ${rect[0]} ${rect[1]}, ${rect[0] + round} ${rect[1]}`;
};

export const getCurvePathOps = (shape: Drawable): Op[] => {
  for (const set of shape.sets) {
    if (set.type === 'path') {
      return set.ops;
    }
  }
  return shape.sets[0].ops;
};

export const getCurveShape = (
  roughShape: Drawable,
  center: Point,
  angleInRadian: number
): GeometricShape => {
  const transform = (p: Point) =>
    rotatePoint(center, [p[0], p[1]], angleInRadian);

  const ops = getCurvePathOps(roughShape);
  const polycurve: Polycurve = [];
  let p0: Point = [0, 0];

  for (const op of ops) {
    if (op.op === 'move') {
      p0 = transform(op.data as Point);
    }
    if (op.op === 'bcurveTo') {
      const p1: Point = transform([op.data[0], op.data[1]]);
      const p2: Point = transform([op.data[2], op.data[3]]);
      const p3: Point = transform([op.data[4], op.data[5]]);
      polycurve.push([p0, p1, p2, p3]);
      p0 = p3;
    }
  }

  return {
    type: 'polycurve',
    data: polycurve,
  };
};

export const getPolygonShape = (element: Element): GeometricShape => {
  const { angle, width, height, x, y, cx, cy } = element;

  const center: Point = [cx, cy];

  let data: Point[] = [];

  data = [
    rotatePoint(center, [x, y], angle),
    rotatePoint(center, [x + width, y], angle),
    rotatePoint(center, [x + width, y + height], angle),
    rotatePoint(center, [x, y + height], angle),
  ] as Polygon;

  return {
    type: 'polygon',
    data,
  };
};

export const generateEllipsePath = (
  center: Point,
  radius: Point,
  rotation: number,
  steps = 100
) => {
  const pathData: number[][] = [];
  for (let i = 0; i <= steps; i++) {
    const theta = (i / steps) * 2 * Math.PI;
    const x = center[0] + radius[0] * Math.cos(theta);
    const y = center[1] + radius[1] * Math.sin(theta);
    const [rotatedX, rotatedY] = rotatePoint(
      [center[0], center[1]],
      [x, y],
      rotation
    );
    pathData.push([rotatedX, rotatedY]);
  }
  return pathData;
};

export const getBoundingRectOnPoints = (points: Point[]) => {
  const x = points.map((point) => point[0]);
  const y = points.map((point) => point[1]);

  const minX = min(x) || 0;
  const minY = min(y) || 0;
  const maxX = max(x) || 0;
  const maxY = max(y) || 0;

  return [minX, minY, maxX - minX, maxY - minY];
};

export const getFreeDrawShape = (points: Point[], lineWidth: number) => {
  return generateFreeDrawShape(points, lineWidth);
};

export const getPolyLineShape = (points: Point[], options: Options) => {
  const generator = new RoughGenerator();

  return [generator.curve(points, options)];
};

export const getRectangleShape = (
  rect: Rect,
  round: number,
  options: Options
) => {
  const generator = new RoughGenerator();

  const path = getRoundRectanglePath(rect, round);

  return generator.path(path, options);
};

export const getEllipseShape = (rect: Rect, options: Options) => {
  const generator = new RoughGenerator();

  return generator.ellipse(
    rect[0] + rect[2] / 2,
    rect[1] + rect[3] / 2,
    rect[2],
    rect[3],
    options
  );
};
