export const getLineHeightInPx = (fontSize: number, lineHeight: number) => {
  return fontSize * lineHeight;
};

export const getVerticalOffset = (fontSize: number, lineHeightPx: number) => {
  // const [unitsPerEm, ascender, descender] = [2048, 1577, -471];
  const [unitsPerEm, ascender, descender] = [1000, 1160, -288];

  const fontSizeEm = fontSize / unitsPerEm;
  const lineGap = lineHeightPx - fontSizeEm * ascender + fontSizeEm * descender;

  const verticalOffset = fontSizeEm * ascender + lineGap;
  return verticalOffset;
};

export const measureText = (text: string, font: string, lineHeight: number) => {
  text = text
    .split("\n")
    // replace empty lines with single space because leading/trailing empty
    // lines would be stripped from computation
    .map((x) => x || " ")
    .join("\n");
  const fontSize = parseFloat(font);
  const height = getTextHeight(text, fontSize, lineHeight);
  const width = getTextWidth(text, font);
  return { width, height };
};

export const getTextHeight = (
  text: string,
  fontSize: number,
  lineHeight: number
) => {
  const lineCount = splitIntoLines(text).length;
  return getLineHeightInPx(fontSize, lineHeight) * lineCount;
};

export const getTextWidth = (text: string, font: string) => {
  const lines = splitIntoLines(text);
  let width = 0;
  lines.forEach((line) => {
    width = Math.max(width, getLineWidth(line, font));
  });
  return width;
};

const splitIntoLines = (text: string) => {
  return normalizeText(text).split("\n");
};

const getLineWidth = (text: string, font: string) => {
  let canvas: HTMLCanvasElement | undefined;

  if (!canvas) {
    canvas = document.createElement("canvas");
  }
  const canvas2dContext = canvas.getContext("2d")!;
  canvas2dContext.font = font;
  const width = canvas2dContext.measureText(text).width;

  // since in test env the canvas measureText algo
  // doesn't measure text and instead just returns number of
  // characters hence we assume that each letteris 10px

  return width;
};

export const normalizeText = (text: string) => {
  return (
    normalizeEOL(text)
      // replace tabs with spaces so they render and measure correctly
      .replace(/\t/g, "        ")
  );
};

export const normalizeEOL = (str: string) => {
  return str.replace(/\r?\n|\r/g, "\n");
};

export const getTextOpenType = {
  NanumPenScript: {
    unitsPerEm: 1000,
    ascender: 920,
    descender: -230,
    additional: 0.2,
  },
  DoHyeon: {
    unitsPerEm: 1000,
    ascender: 800,
    descender: -200,
    additional: 0.7,
  },
  NotoSans: {
    unitsPerEm: 1000,
    ascender: 1160,
    descender: -288,
    additional: -0.2,
  },
  Stylish: {
    unitsPerEm: 1000,
    ascender: 821,
    descender: -179,
    additional: 0.9,
  },
};
