import { useAtom } from 'jotai';

import { selectedVideoFileAtom } from '../atoms/appAtoms';
import { videoFileOpenDialogAtom } from '../atoms/dialogAtom';
import {
  MouseEvent,
  TouchEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import '../styles/VideoSectionDialog.scss';
import VideoSectionCanvas from './VideoSectionCanvas';
import { convertVideoFrame } from '../utils/frame';
import SystemModal from './SystemModal';
import { Select } from 'semantic-ui-react';

const VideoSectionDialog = () => {
  const [videoFile, setVideoFile] = useAtom(selectedVideoFileAtom);
  const [videoFileOpenDialog, setVideoFileOpenDialogAtom] = useAtom(
    videoFileOpenDialogAtom
  );

  const videoRef = useRef<HTMLVideoElement>(null);

  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(0);
  const [isPlay, setPlay] = useState<boolean>(false);
  const [, setLoadVideo] = useState<boolean>(false);
  const [fps, setFps] = useState<number>(5);

  const url = useMemo(() => {
    return videoFile ? URL.createObjectURL(videoFile) : '';
  }, [videoFile]);

  const handleClickConfirm = useCallback(() => {
    if (videoFile) {
      convertVideoFrame({
        file: videoFile,
        start,
        end,
        fps,
      });
    }
  }, [videoFile, start, end, fps]);

  const handleClickCancel = useCallback(() => {
    setVideoFile(null);
    setVideoFileOpenDialogAtom(false);
  }, []);

  const handleChangeFps = useCallback((e: any, data: any) => {
    setFps(data.value);
  }, []);

  const handleLoadedVideo = useCallback(() => {
    setLoadVideo((prev) => !prev);
    setEnd(videoRef.current?.duration || 0);
    videoRef.current?.pause();
  }, []);

  const handleToggleVideo = useCallback(
    (evt: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      if (isPlay) {
        videoRef.current?.pause();
      } else {
        if (videoRef.current) {
          videoRef.current.volume = 1;
        }
        videoRef.current?.play();
      }

      setPlay((prev) => !prev);
    },
    [isPlay]
  );

  const handleUpdateSection = useCallback((start: number, end: number) => {
    setPlay(false);
    setStart(start);
    setEnd(end);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
      // videoRef.current.pause();
    }
  }, []);

  if (!videoFile) {
    return <></>;
  }

  return (
    <SystemModal
      open={videoFileOpenDialog && !!videoFile}
      title='Select Video Section'
      onClickConfirm={handleClickConfirm}
      onClickCancel={handleClickCancel}
      onClose={handleClickCancel}
    >
      <div className='video-section-wrapper'>
        <video
          ref={videoRef}
          src={url}
          onLoadedData={handleLoadedVideo}
          muted
          autoPlay
        />
        <div className='selected-result'>
          <div>
            <Select
              defaultValue={fps}
              className='frame-select'
              onChange={handleChangeFps}
              options={[
                { text: '5 fps', value: 5, key: 5 },
                { text: '10 fps', value: 10, key: 10 },
                { text: '15 fps', value: 15, key: 15 },
                { text: '20 fps', value: 20, key: 20 },
                { text: '24 fps', value: 24, key: 24 },
                { text: '30 fps', value: 30, key: 30 },
              ]}
            />
          </div>
          <span>{`${start.toFixed(2)}s ~ ${end.toFixed(2)}s`}</span>
        </div>
        <div className='select-section-wrapper'>
          <div className='video-control'>
            <button onClick={handleToggleVideo} onTouchEnd={handleToggleVideo}>
              <img
                src={
                  isPlay ? './icons/pause_video.svg' : './icons/play_video.svg'
                }
                alt={isPlay ? 'pause' : 'play'}
              />
            </button>
          </div>
          {videoRef.current && (
            <VideoSectionCanvas
              video={videoRef.current}
              start={start}
              end={end}
              onUpdateSection={handleUpdateSection}
            />
          )}
        </div>
      </div>
    </SystemModal>
  );
};

export default VideoSectionDialog;
