import { getDefaultStore } from "jotai";
import { Point, Rect } from "./shape";
import { canvasStatusAtom } from "../atoms/canvasAtoms";
import { max, min } from "lodash";
import {
  externalDivisionPoint,
  getDistancePoints,
  rotatePoint,
} from "./geometry";
import { AnchorPointType } from "./canvas";

const store = getDefaultStore();

export const getAbsolutePosition = (point: Point): Point => {
  const canvasStatusInfo = store.get(canvasStatusAtom);

  return [
    (point[0] - canvasStatusInfo.x) / (canvasStatusInfo.scale * 0.01),
    (point[1] - canvasStatusInfo.y) / (canvasStatusInfo.scale * 0.01),
  ];
};

export const getDiff = (point1: Point, point2: Point) => {
  return [point2[0] - point1[0], point2[1] - point1[1]];
};

export const getRotationRadian = (center: Point, point: Point) => {
  const [dx, dy] = getDiff(center, point);

  return Math.atan2(dy, dx) + Math.PI / 2;
};

export const getBoundingRect = (points: Point[]) => {
  const x = points.map((point) => point[0]);
  const y = points.map((point) => point[1]);

  const minX = min(x) || 0;
  const maxX = max(x) || 0;
  const minY = min(y) || 0;
  const maxY = max(y) || 0;

  return [minX, minY, maxX - minX, maxY - minY] as Rect;
};

export const getAdjustPoint = (
  rect: Rect,
  angle: number,
  anchorPointType: AnchorPointType
) => {
  if (anchorPointType === AnchorPointType.NoneType) {
    return [];
  }

  const _points = [
    [rect[0], rect[1]],
    [rect[0] + rect[2], rect[1]],
    [rect[0], rect[1] + rect[3]],
    [rect[0] + rect[2], rect[1] + rect[3]],
  ] as Point[];

  if (anchorPointType === AnchorPointType.FullType) {
    _points.push([rect[0] + rect[2] / 2, rect[1]]);
    _points.push([rect[0] + rect[2] / 2, rect[1] + rect[3]]);
    _points.push([rect[0], rect[1] + rect[3] / 2]);
    _points.push([rect[0] + rect[2], rect[1] + rect[3] / 2]);
  }

  const center = [rect[0] + rect[2] / 2, rect[1] + rect[3] / 2] as Point;

  const points = _points.map((point) => rotatePoint(center, point, angle));

  if (anchorPointType !== AnchorPointType.NoneRotate) {
    const point = [
      (points[0][0] + points[1][0]) / 2,
      (points[0][1] + points[1][1]) / 2,
    ] as Point;

    points.push(
      externalDivisionPoint(
        center,
        point,
        getDistancePoints(center, point) + 30,
        30
      )
    );
  }
  return points;
};
