import { useAtom } from 'jotai';
import '../styles/FrameList.scss';
import { frameIndexAtom, frameInfosAtom } from '../atoms/frameAtoms';
import FrameInfo from './FrameInfo';
import useKeyEvent from '../hooks/useKeyEvent';
import { clamp } from 'lodash';
import { WheelEvent, useCallback, useRef } from 'react';

const FrameList = () => {
  const [frameInfos] = useAtom(frameInfosAtom);

  const [frameIndex, setFrameIndex] = useAtom(frameIndexAtom);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === ',') {
        setFrameIndex(clamp(frameIndex - 1, 0, frameInfos.length - 1));
      } else if (event.key === '.') {
        setFrameIndex(clamp(frameIndex + 1, 0, frameInfos.length - 1));
      }
    },
    [frameInfos, frameIndex]
  );

  useKeyEvent(',', handleKeyEvent);
  useKeyEvent('.', handleKeyEvent);

  const handleScroll = (event: WheelEvent<HTMLDivElement>) => {
    if (event.shiftKey) {
      if (event.currentTarget) {
        (event.currentTarget as HTMLElement).scrollLeft += event.deltaY;
      }
    } else {
      (event.currentTarget as HTMLElement).scrollTo({
        left:
          (event.currentTarget as HTMLElement).scrollLeft + event.deltaY * 8,
        behavior: 'smooth',
      });
    }
  };

  if (frameInfos.length === 0) {
    return null;
  }

  return (
    <div ref={wrapperRef} className='frame-list-wrapper' onWheel={handleScroll}>
      {frameInfos.map((frameInfo, index) => (
        <FrameInfo key={frameInfo.id} index={index} frameInfo={frameInfo} />
      ))}
    </div>
  );
};

export default FrameList;
