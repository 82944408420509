import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';
import { canvasStatusAtom } from '../atoms/canvasAtoms';
import { useCanvasWindowResizeEvent } from '../hooks/useCanvasWindowResizeEvent';
import {
  frameIndexAtom,
  frameInfosAtom,
  selectedElementsAtom,
} from '../atoms/frameAtoms';
import {
  AnchorPointType,
  clearCanvas,
  drawAdjustHandle,
  drawAdjustHandleForCrop,
  drawElement,
  drawPolyLineAnchorPoint,
  fillRectCanvas,
  strokeRectCanvas,
} from '../utils/canvas';
import { EditorTool, isSelectCropAtom } from '../atoms/appAtoms';
import { removeElements } from '../utils/element';
import useKeyEvent from '../hooks/useKeyEvent';

const StaticCanvas = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);

  const canvasStatusInfo = useAtomValue(canvasStatusAtom);
  const frameInfos = useAtomValue(frameInfosAtom);
  const frameIndex = useAtomValue(frameIndexAtom);
  const selectedElements = useAtomValue(selectedElementsAtom);
  const isSelectCrop = useAtomValue(isSelectCropAtom);

  const draw = useCallback(() => {
    if (canvasRef.current && ctxRef.current && frameInfos.length > 0) {
      const selectedElementIds = selectedElements.map((el) => el.id);

      ctxRef.current.beginPath();

      clearCanvas(canvasRef.current, ctxRef.current);

      ctxRef.current.globalAlpha = 1;

      ctxRef.current.drawImage(frameInfos[frameIndex].canvas, 0, 0);

      const { cropInfo, elements } = frameInfos[frameIndex];

      elements.forEach((element) => {
        if (canvasRef.current && ctxRef.current) {
          drawElement(canvasRef.current, ctxRef.current, element);

          const anchorPointType = [EditorTool.Image, EditorTool.Text].includes(
            element.type
          )
            ? AnchorPointType.HalfType
            : AnchorPointType.FullType;

          if (ctxRef.current && selectedElementIds.includes(element.id)) {
            drawAdjustHandle(
              ctxRef.current,
              [
                element.x - 10,
                element.y - 10,
                element.width + 20,
                element.height + 20,
              ],
              element.angle || 0,
              anchorPointType
            );

            if (
              element.type === EditorTool.PolyLine &&
              selectedElementIds.length === 1
            ) {
              drawPolyLineAnchorPoint(ctxRef.current, element);
            }
          }
        }
      });

      if (cropInfo) {
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = frameInfos[frameIndex].canvas.width;
        tempCanvas.height = frameInfos[frameIndex].canvas.height;

        if (tempCanvas && tempCtx) {
          fillRectCanvas(
            tempCtx,
            [
              0,
              0,
              frameInfos[frameIndex].canvas.width,
              frameInfos[frameIndex].canvas.height,
            ],
            {
              globalCompositeOperation: 'source-over',
              fillStyle: 'rgba(0, 0, 0, 0.6)',
            }
          );

          fillRectCanvas(tempCtx, cropInfo, {
            globalCompositeOperation: 'destination-out',
          });

          ctxRef.current.drawImage(tempCanvas, 0, 0);
        }

        strokeRectCanvas(ctxRef.current, cropInfo, {
          dashed: [],
          lineWidth: 2,
          strokeStyle: '#fff',
        });
        if (isSelectCrop) {
          drawAdjustHandleForCrop(
            ctxRef.current,
            cropInfo,
            0,
            AnchorPointType.NoneRotate
          );
        }
      }

      ctxRef.current.lineWidth = 1;
      ctxRef.current.strokeStyle = 'rgba(255, 255, 255, 0.1)';
      ctxRef.current.strokeRect(
        0,
        0,
        frameInfos[frameIndex].canvas.width,
        frameInfos[frameIndex].canvas.height
      );
    }
  }, [
    canvasStatusInfo,
    frameInfos,
    frameIndex,
    selectedElements,
    isSelectCrop,
  ]);

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        removeElements(selectedElements.map((el) => el.id));
      }
    },
    [selectedElements]
  );

  useKeyEvent('Delete', handleKeyEvent);
  useKeyEvent('Backspace', handleKeyEvent);

  useEffect(() => {
    if (canvasRef.current) {
      ctxRef.current = canvasRef.current.getContext('2d');
    }
  }, []);

  useEffect(() => {
    if (ctxRef.current) {
      ctxRef.current.setTransform(
        canvasStatusInfo.scale * 0.01,
        0,
        0,
        canvasStatusInfo.scale * 0.01,
        canvasStatusInfo.x,
        canvasStatusInfo.y
      );
    }
    draw();
  }, [draw, canvasStatusInfo]);

  useEffect(() => {
    if (frameInfos.length === 0 && canvasRef.current && ctxRef.current) {
      clearCanvas(canvasRef.current, ctxRef.current);
    }
  }, [frameInfos]);

  useCanvasWindowResizeEvent({
    canvas: canvasRef.current,
    ctx: ctxRef.current,
    canvasStatusInfo,
    rerender: draw,
  });

  return (
    <canvas
      ref={canvasRef}
      className='main'
      width={window.innerWidth}
      height={window.innerHeight}
    />
  );
};

export default StaticCanvas;
