import { useAtom, useSetAtom } from 'jotai';

import { mainMenuStatusAtom } from '../atoms/appAtoms';

import '../styles/MainMenu.scss';
import { useCallback, useEffect, useState } from 'react';
import { openFile } from '../utils/file';
import {
  exportGifDialogAtom,
  guideDialogAtom,
  newWorkspaceDialogAtom,
} from '../atoms/dialogAtom';

const MainMenu = () => {
  const [mainMenuStatus, setMainMenuStatus] = useAtom(mainMenuStatusAtom);
  const setNewWorkspaceDialog = useSetAtom(newWorkspaceDialogAtom);
  const setExportGifDialogAtom = useSetAtom(exportGifDialogAtom);
  const setGuideDialog = useSetAtom(guideDialogAtom);

  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const handleClickNew = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      setMainMenuStatus(false);
      setNewWorkspaceDialog(true);
    },
    [setMainMenuStatus, setNewWorkspaceDialog]
  );

  const handleClickOpen = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      setMainMenuStatus(false);
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'video/*, image/gif';
      input.onchange = openFile;
      input.click();
    },
    [setMainMenuStatus]
  );

  const handleClickExport = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      setMainMenuStatus(false);
      setExportGifDialogAtom(true);
    },
    [setMainMenuStatus, setExportGifDialogAtom]
  );

  const handleClickClear = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      setMainMenuStatus(false);
    },
    [setMainMenuStatus]
  );

  const handleClickGuide = useCallback(
    (
      evt:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      evt.preventDefault();

      setMainMenuStatus(false);
      setGuideDialog(true);
    },
    [setMainMenuStatus, setGuideDialog]
  );

  useEffect(() => {
    if (isAnimating) return;

    // 상태가 변경된 후 애니메이션을 시작하도록 설정
    if (mainMenuStatus) {
      setIsAnimating(true);
    }
  }, [mainMenuStatus, isAnimating]);

  return (
    <div
      className={`main-menu-wrapper ${isAnimating ? (mainMenuStatus ? 'open' : 'close') : ''}`}
    >
      <button
        className='menu-item'
        onClick={handleClickNew}
        onTouchEnd={handleClickNew}
      >
        <img src='./icons/file.svg' alt='' />
        <span>New Workspace</span>
      </button>
      <button
        className='menu-item'
        onClick={handleClickOpen}
        onTouchEnd={handleClickOpen}
      >
        <img src='./icons/folder.svg' alt='' />
        <span>Open Image or Video</span>
      </button>
      <button
        className='menu-item'
        onClick={handleClickExport}
        onTouchEnd={handleClickExport}
      >
        <img src='./icons/file-down.svg' alt='' />
        <span>Export to Gif</span>
      </button>
      <button
        className='menu-item'
        onClick={handleClickClear}
        onTouchEnd={handleClickClear}
      >
        <img src='./icons/trash.svg' alt='' />
        <span>Clear Canvas</span>
      </button>
      <button
        className='menu-item'
        onClick={handleClickGuide}
        onTouchEnd={handleClickGuide}
      >
        <img src='./icons/info.svg' alt='' />
        <span>Guide</span>
      </button>
    </div>
  );
};

export default MainMenu;
