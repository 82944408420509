import { Point } from 'roughjs/bin/geometry';
import {
  BoundingRectElement,
  CanvasOptions,
  Element,
  FreeDrawElement,
  ImageElement,
  PolyLineElement,
  TextElement,
} from '../atoms/frameAtoms';
import { CanvasStatusInfo } from '../atoms/canvasAtoms';
import {
  getDistancePoints,
  pointOnPolycurve,
  pointOnPolygon,
  pointOnPolyline,
  polylineFromPoints,
  rotatePoint,
} from './geometry';
import { max, min } from 'lodash';
import { EditorTool } from '../atoms/appAtoms';
import { RoughGenerator } from 'roughjs/bin/generator';
import { POINT_RADIUS } from '../components/InteractiveCanvas';
import {
  Rect,
  getCurveShape,
  getFreeDrawShape,
  getPolyLineShape,
  getPolygonShape,
  getRectangleShape,
} from './shape';
import rough from 'roughjs/bin/rough';
import { Drawable } from 'roughjs/bin/core';
import { getElementBoundingRect } from './element';
import { getCornerRadius } from './math';
import { getLineHeightInPx, getTextOpenType } from './text';
import {
  EditorColor,
  EditorFontFamily,
  EditorFontSize,
  Roundness,
} from '../atoms/optionAtom';
import { getAdjustPoint } from './coordinate';

export const cropStrokeOption = {
  strokeStyle: '#fff',
  globalCompositeOperation: 'source-over',
  lineWidth: 2,
  dashed: [],
};

export const getFitImageStatus = (
  source: HTMLCanvasElement,
  target: HTMLCanvasElement
) => {
  const { width: sw, height: sh } = source;
  const { width: tw, height: th } = target;

  const imageInfo = { x: 0, y: 0, scale: 100 };

  if (sw > tw && sh < th) {
    imageInfo.scale = (tw / sw) * 100;
    imageInfo.y = (th - sh * imageInfo.scale * 0.01) / 2;
  } else if (sw < tw && sh > th) {
    imageInfo.scale = (th / sh) * 100;
    imageInfo.x = (tw - sw * imageInfo.scale * 0.01) / 2;
  } else if (sw > tw && sh > th) {
    imageInfo.scale = Math.min((tw / sw) * 100, (th / sh) * 100);
    imageInfo.x = (tw - sw * imageInfo.scale * 0.01) / 2;
    imageInfo.y = (th - sh * imageInfo.scale * 0.01) / 2;
  } else {
    imageInfo.x = (tw - sw) / 2;
    imageInfo.y = (th - sh) / 2;
  }

  return imageInfo;
};

// const store = getDefaultStore();

export const clearCanvas = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D
) => {
  // const canvasStatus = store.get(canvasStatusAtom);
  ctx.save();
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.restore();
  // ctx.clearRect(
  //   -canvasStatus.x / (canvasStatus.scale * 0.01),
  //   -canvasStatus.y / (canvasStatus.scale * 0.01),
  //   (canvasStatus.x + canvas.width) / (canvasStatus.scale * 0.01),
  //   (canvasStatus.y + canvas.height) / (canvasStatus.scale * 0.01)
  // );
};

export const fillRectCanvas = (
  ctx: CanvasRenderingContext2D,
  rect: Rect,
  options?: {
    globalCompositeOperation?: GlobalCompositeOperation;
    fillStyle?: string;
    angle?: number;
  }
) => {
  ctx.beginPath();

  if (options?.globalCompositeOperation) {
    console.log(options.globalCompositeOperation);
    ctx.globalCompositeOperation = options.globalCompositeOperation;
  }

  if (options?.fillStyle) {
    ctx.fillStyle = options.fillStyle;
  }

  if (options?.angle) {
    const center = [rect[0] + rect[2] / 2, rect[1] + rect[3] / 2] as Point;
    const points = [
      rotatePoint(center, [rect[0], rect[1]], options.angle),
      rotatePoint(center, [rect[0] + rect[2], rect[1]], options.angle),
      rotatePoint(center, [rect[0], rect[1] + rect[3]], options.angle),
      rotatePoint(
        center,
        [rect[0] + rect[2], rect[1] + rect[3]],
        options.angle
      ),
    ];

    ctx.moveTo(points[0][0], points[0][1]);
    ctx.lineTo(points[1][0], points[1][1]);
    ctx.lineTo(points[3][0], points[3][1]);
    ctx.lineTo(points[2][0], points[2][1]);

    ctx.closePath();
  } else {
    ctx.rect(rect[0], rect[1], rect[2], rect[3]);
  }
  ctx.fill();
};

export const strokeRectCanvas = (
  ctx: CanvasRenderingContext2D,
  rect: Rect,
  options?: {
    globalCompositeOperation?: string;
    strokeStyle?: string;
    dashed?: number[];
    lineWidth?: number;
    angle?: number;
  }
) => {
  ctx.beginPath();

  if (options?.globalCompositeOperation) {
    ctx.globalCompositeOperation =
      options.globalCompositeOperation as GlobalCompositeOperation;
  }

  if (options?.strokeStyle) {
    ctx.strokeStyle = options.strokeStyle;
  }

  if (options?.dashed) {
    ctx.setLineDash(options.dashed);
  }

  if (options?.lineWidth) {
    ctx.lineWidth = options.lineWidth;
  }

  if (options?.angle) {
    const center = [rect[0] + rect[2] / 2, rect[1] + rect[3] / 2] as Point;
    const points = [
      rotatePoint(center, [rect[0], rect[1]], options.angle),
      rotatePoint(center, [rect[0] + rect[2], rect[1]], options.angle),
      rotatePoint(center, [rect[0], rect[1] + rect[3]], options.angle),
      rotatePoint(
        center,
        [rect[0] + rect[2], rect[1] + rect[3]],
        options.angle
      ),
    ];

    ctx.moveTo(points[0][0], points[0][1]);
    ctx.lineTo(points[1][0], points[1][1]);
    ctx.lineTo(points[3][0], points[3][1]);
    ctx.lineTo(points[2][0], points[2][1]);

    ctx.closePath();
  } else {
    ctx.rect(rect[0], rect[1], rect[2], rect[3]);
  }
  ctx.stroke();

  ctx.setLineDash([]);
  ctx.globalCompositeOperation = 'source-over';
};

export const fillCircleCanvas = (
  ctx: CanvasRenderingContext2D,
  point: Point,
  radius: number,
  options?: {
    globalCompositeOperation?: GlobalCompositeOperation;
    fillStyle?: string;
  }
) => {
  ctx.beginPath();

  if (options?.globalCompositeOperation) {
    ctx.globalCompositeOperation = options.globalCompositeOperation;
  }

  if (options?.fillStyle) {
    ctx.fillStyle = options.fillStyle;
  }

  ctx.arc(point[0], point[1], radius, 0, Math.PI * 2);
  ctx.fill();
};

export const strokeCircleCanvas = (
  ctx: CanvasRenderingContext2D,
  point: Point,
  radius: number,
  options?: {
    globalCompositeOperation?: GlobalCompositeOperation;
    strokeStyle?: string;
    dashed?: number[];
    lineWidth?: number;
  }
) => {
  ctx.beginPath();

  if (options?.globalCompositeOperation) {
    ctx.globalCompositeOperation = options.globalCompositeOperation;
  }

  if (options?.strokeStyle) {
    ctx.strokeStyle = options.strokeStyle;
  }

  if (options?.dashed) {
    ctx.setLineDash(options.dashed);
  }

  if (options?.lineWidth) {
    ctx.lineWidth = options.lineWidth;
  }

  ctx.arc(point[0], point[1], radius, 0, Math.PI * 2);
  ctx.stroke();
};

export const getRectInfo = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
): Rect => {
  return [
    Math.min(x1, x2),
    Math.min(y1, y2),
    Math.abs(x2 - x1),
    Math.abs(y2 - y1),
  ];
};

export const getDataPointInfo = (
  point: Point,
  canvasStatusInfo: CanvasStatusInfo
): Point => {
  return [
    (point[0] - canvasStatusInfo.x) / (canvasStatusInfo.scale * 0.01),
    (point[1] - canvasStatusInfo.y) / (canvasStatusInfo.scale * 0.01),
  ];
};

export const getDataRectInfo = (
  rectInfo: Rect,
  canvasStatusInfo: CanvasStatusInfo
): Rect => {
  return [
    (rectInfo[0] - canvasStatusInfo.x) / (canvasStatusInfo.scale * 0.01),
    (rectInfo[1] - canvasStatusInfo.y) / (canvasStatusInfo.scale * 0.01),
    rectInfo[2] / (canvasStatusInfo.scale * 0.01),
    rectInfo[3] / (canvasStatusInfo.scale * 0.01),
  ];
};

export const getDataPointsInfo = (
  points: Point[],
  canvasStatusInfo: CanvasStatusInfo
): Point[] => {
  return points.map((point) => [
    (point[0] - canvasStatusInfo.x) / (canvasStatusInfo.scale * 0.01),
    (point[1] - canvasStatusInfo.y) / (canvasStatusInfo.scale * 0.01),
  ]);
};

export const getRectIndexPoint = (rect: Rect, index: number): Point => {
  if (index === 0) {
    return [rect[0], rect[1]];
  } else if (index === 1) {
    return [rect[0] + rect[2], rect[1]];
  } else if (index === 2) {
    return [rect[0], rect[1] + rect[3]];
  } else {
    return [rect[0] + rect[2], rect[1] + rect[3]];
  }
};

export const getRectInfoFromPoints = (points: Point[]): Rect => {
  const x = points.map((point) => point[0]);
  const y = points.map((point) => point[1]);

  const minX = min(x) || 0;
  const minY = min(y) || 0;
  const maxX = max(x) || 0;
  const maxY = max(y) || 0;

  return [minX, minY, maxX - minX, maxY - minY];
};

export const getHoverElement = (elements: Element[], position: Point) => {
  for (let i = 0; i < elements.length; i++) {
    switch (elements[i].type) {
      case EditorTool.FreeDraw: {
        const element = elements[i] as PolyLineElement;
        const polyline = polylineFromPoints(
          element.points.map((point) =>
            rotatePoint([element.cx, element.cy], point, element.angle)
          )
        );
        const isOn = pointOnPolyline(position, polyline, 10);
        if (isOn) {
          return element;
        }
        break;
      }
      case EditorTool.PolyLine: {
        const element = elements[i] as PolyLineElement;

        const generator = new RoughGenerator();

        const shape = [
          generator.curve([...element.points], element.roughOptions),
        ];

        const shape2 = getCurveShape(
          shape[0],
          [element.cx, element.cy],
          element.angle
        );

        if (shape2.type === 'polycurve') {
          const isOn = pointOnPolycurve(position, shape2.data, 10);
          if (isOn) {
            return element;
          }
        }
        break;
      }
      case EditorTool.Rectangle: {
        // const element = elements[i] as BoundingRectElement;

        // const generator = new RoughGenerator();

        // const width = element.width;
        // const height = element.height;

        // const r = 32;

        // const x = element.x;
        // const y = element.y;

        // const shape = generator.path(
        //   `M ${x + r} ${y}
        //   L ${x + width - r} ${y}
        //   Q ${x + width} ${y}, ${x + width} ${y + r}
        //   L ${x + width} ${y + height - r}
        //   Q ${x + width} ${y + height}, ${x + width - r} ${y + height}
        //   L ${x + r} ${y + height}
        //   Q ${x} ${y + height}, ${x} ${y + height - r}
        //   L ${x} ${y + r}
        //   Q ${x} ${y}, ${x + r} ${y}`,
        //   element.roughOptions
        // );

        const shape2 = getPolygonShape(elements[i]);

        if (shape2.type === 'polygon') {
          const isOn = pointOnPolygon(position, shape2.data, 10);
          if (isOn) {
            return elements[i];
          }
        }
        break;
      }
      case EditorTool.Ellipse: {
        // const element = elements[i] as BoundingRectElement;

        // const generator = new RoughGenerator();

        // const width = elements[i].width;
        // const height = elements[i].height;

        // const x = elements[i].x;
        // const y = elements[i].y;

        // const shape = generator.ellipse(
        //   x + width / 2,
        //   y + height / 2,
        //   width,
        //   height,
        //   element.roughOptions
        // );

        // const shape2 = getEllipseShape(elements[i]);

        // if (shape2.type === "ellipse") {
        //   const isOn = pointOnEllipse(position, shape2.data, 10);
        //   if (isOn) {
        //     return elements[i];
        //   }
        // }

        break;
      }
      default:
        break;
    }
  }
  return null;
};

export enum AnchorPointType {
  FullType = 'FullType',
  NoneType = 'NoneType',
  HalfType = 'HalfType',
  NoneRotate = 'NoneRotate',
}

export const drawAdjustHandle = (
  ctx: CanvasRenderingContext2D,
  rect: Rect,
  angle: number,
  anchorPoint: AnchorPointType
) => {
  ctx.beginPath();

  strokeRectCanvas(ctx, rect, {
    strokeStyle: '#80deea',
    lineWidth: 1,
    dashed: [],
    angle,
  });

  if (anchorPoint) {
    const points = getAdjustPoint(rect, angle, anchorPoint);

    points.forEach((point) => {
      fillCircleCanvas(ctx, point, POINT_RADIUS / 2, {
        fillStyle: '#80deea',
      });

      strokeCircleCanvas(ctx, point, POINT_RADIUS / 2, {
        strokeStyle: '#607d8b',
        lineWidth: 1,
        dashed: [],
      });
    });
  }
};

export const drawAdjustHandleForCrop = (
  ctx: CanvasRenderingContext2D,
  rect: Rect,
  angle: number,
  anchorPoint: AnchorPointType
) => {
  ctx.beginPath();

  if (anchorPoint) {
    const points = getAdjustPoint(rect, angle, anchorPoint);

    const width =
      (max(points.map((p) => p[0])) || 0) - (min(points.map((p) => p[0])) || 0);
    const height =
      (max(points.map((p) => p[1])) || 0) - (min(points.map((p) => p[1])) || 0);

    const availableWidth = min([width, 20]) || 0;
    const availableHeight = min([height, 20]) || 0;

    points.forEach((point, index) => {
      if (index === 0) {
        ctx.beginPath();
        ctx.strokeStyle = '#fff';
        ctx.lineWidth = 5;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.moveTo(point[0], point[1]);
        ctx.lineTo(point[0] + availableWidth, point[1]);
        ctx.moveTo(point[0], point[1]);
        ctx.lineTo(point[0], point[1] + availableHeight);
        ctx.stroke();
      } else if (index === 1) {
        ctx.beginPath();
        ctx.strokeStyle = '#fff';
        ctx.lineWidth = 5;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.moveTo(point[0], point[1]);
        ctx.lineTo(point[0] - availableWidth, point[1]);
        ctx.moveTo(point[0], point[1]);
        ctx.lineTo(point[0], point[1] + availableHeight);
        ctx.stroke();
      } else if (index === 2) {
        ctx.beginPath();
        ctx.strokeStyle = '#fff';
        ctx.lineWidth = 5;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.moveTo(point[0], point[1]);
        ctx.lineTo(point[0] + availableWidth, point[1]);
        ctx.moveTo(point[0], point[1]);
        ctx.lineTo(point[0], point[1] - availableHeight);
        ctx.stroke();
      } else if (index === 3) {
        ctx.beginPath();
        ctx.strokeStyle = '#fff';
        ctx.lineWidth = 5;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.moveTo(point[0], point[1]);
        ctx.lineTo(point[0] - availableWidth, point[1]);
        ctx.moveTo(point[0], point[1]);
        ctx.lineTo(point[0], point[1] - availableHeight);
        ctx.stroke();
      }
    });
  }
};

export const drawPolyLineAnchorPoint = (
  ctx: CanvasRenderingContext2D,
  element: Element
) => {
  ctx.beginPath();

  const points = (element as PolyLineElement).points.map((point) =>
    rotatePoint([element.cx, element.cy], point, element.angle)
  );

  points.forEach((point) => {
    fillCircleCanvas(ctx, point, POINT_RADIUS / 2, {
      fillStyle: '#80deea',
    });

    strokeCircleCanvas(ctx, point, POINT_RADIUS / 2, {
      strokeStyle: '#607d8b',
      lineWidth: 1,
      dashed: [],
    });
  });
};

export const getHoverPointOnPoints = (
  point: Point,
  points: Point[],
  threshold: number
) => {
  for (let i = 0; i < points.length; i++) {
    if (getDistancePoints(point, points[i]) < threshold) {
      return {
        index: i,
        point: points[i],
      };
    }
  }

  return null;
};

export const getHoverPointOnAdjustHandle = (
  point: Point,
  rect: Rect,
  angle: number,
  threshold: number,
  anchorPointType: AnchorPointType
) => {
  const points = getAdjustPoint(rect, angle, anchorPointType);

  return getHoverPointOnPoints(point, points, threshold);
};

export const drawRoundRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.arcTo(x + width, y, x + width, y + radius, radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
  ctx.lineTo(x + radius, y + height);
  ctx.arcTo(x, y + height, x, y + height - radius, radius);
  ctx.lineTo(x, y + radius);
  ctx.arcTo(x, y, x + radius, y, radius);
  ctx.closePath();
  ctx.stroke();
};

export const getHoverPointOnPolylineAnchorPoint = (
  point: Point,
  element: Element,
  threshold: number
) => {
  const points = (element as PolyLineElement).points.map((point) =>
    rotatePoint([element.cx, element.cy], point, element.angle)
  );

  return getHoverPointOnPoints(point, points, threshold);
};

export const drawSVGPathOnCanvas = (
  ctx: CanvasRenderingContext2D,
  path: Path2D,
  options?: CanvasOptions
) => {
  ctx.beginPath();

  if (options?.globalAlpha) {
    ctx.globalAlpha = options?.globalAlpha;
  }
  if (options?.fillStyle) {
    ctx.fillStyle = options?.fillStyle;
  }

  ctx.fill(path);
};

export const drawShapeOnRoughCanvas = (
  canvas: HTMLCanvasElement,
  shape: Drawable
) => {
  const rc = rough.canvas(canvas);
  rc.draw(shape);
};

export const drawShapesOnRoughCanvas = (
  canvas: HTMLCanvasElement,
  shapes: Drawable[]
) => {
  const rc = rough.canvas(canvas);

  shapes.forEach((shape) => {
    rc.draw(shape);
  });
};

const setCanvasOptions = (
  ctx: CanvasRenderingContext2D,
  canvasOptions: CanvasOptions
) => {
  ctx.fillStyle = canvasOptions.fillStyle || '#000';
  ctx.strokeStyle = canvasOptions.strokeStyle || '#000';
  ctx.globalAlpha = canvasOptions.globalAlpha || 1;
  ctx.globalCompositeOperation =
    canvasOptions.globalCompositeOperation || 'source-over';
  ctx.lineCap = canvasOptions.lineCap || 'round';
  ctx.lineJoin = canvasOptions.lineJoin || 'round';
  ctx.lineWidth = canvasOptions.lineWidth || 4;
  ctx.setLineDash(canvasOptions.dashed || []);
};

export const drawFreeDrawShape = (
  ctx: CanvasRenderingContext2D,
  canvasOptions: CanvasOptions,
  shape: any
) => {
  ctx.beginPath();

  setCanvasOptions(ctx, canvasOptions);
  ctx.fillStyle = canvasOptions.strokeStyle || '#000';

  ctx.fill(shape);
};

export const drawPolyLineShape = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  canvasOptions: CanvasOptions,
  shapes: Drawable[]
) => {
  const rc = rough.canvas(canvas);

  ctx.globalAlpha = canvasOptions.globalAlpha || 1;

  shapes.forEach((shape) => {
    rc.draw(shape);
  });
};

export const drawRectangleShape = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  canvasOptions: CanvasOptions,
  boundRect: Rect,
  shape: Drawable,
  angle: number
) => {
  const width = boundRect[2];
  const height = boundRect[3];

  const maxValue = Math.max(width, height);

  const draw = (canvas: HTMLCanvasElement) => {
    const rc = rough.canvas(canvas);
    rc.draw(shape);
  };

  const rotatedCanvas = getRotatedCanvas(
    boundRect,
    angle,
    draw,
    RotateMode.Mode_1
  );

  ctx.globalAlpha = canvasOptions.globalAlpha || 1;

  ctx.drawImage(
    rotatedCanvas,
    boundRect[0] + boundRect[2] / 2 - maxValue,
    boundRect[1] + boundRect[3] / 2 - maxValue
  );
};

export const drawEllipseShape = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  canvasOptions: CanvasOptions,
  boundRect: Rect,
  shape: Drawable,
  angle: number
) => {
  const draw = (canvas: HTMLCanvasElement) => {
    const rc = rough.canvas(canvas);
    rc.draw(shape);
  };

  const rotatedCanvas = getRotatedCanvas(
    boundRect,
    angle,
    draw,
    RotateMode.Mode_2
  );

  ctx.globalAlpha = canvasOptions.globalAlpha || 1;

  ctx.drawImage(
    rotatedCanvas,
    boundRect[0] - boundRect[2] / 2,
    boundRect[1] - boundRect[3] / 2
  );
};

export const drawTextShape = (
  ctx: CanvasRenderingContext2D,
  canvasOptions: CanvasOptions,
  boundRect: Rect,
  text: string,
  fontFamily: EditorFontFamily,
  fontSize: EditorFontSize,
  angle: number
) => {
  const width = boundRect[2];
  const height = boundRect[3];

  const maxValue = Math.max(width, height);

  const lineText = text.split('\n');

  // const metrics = measureText(text, `${fontSize}px ${fontFamily}`, 1.2);
  // const lineHeight = metrics.height / lineText.length;
  const lineHeightPx = getLineHeightInPx(Number(fontSize), 1.2);
  // const verticalOffset = getVerticalOffset(Number(fontSize), lineHeightPx);

  const draw = (_canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
    if (canvasOptions.fillStyle === EditorColor.Transparent) {
      canvasOptions.fillStyle = EditorColor.Black;
    }
    setCanvasOptions(ctx, canvasOptions);
    ctx.font = `${fontSize}px ${fontFamily}`;

    const textOpenType = getTextOpenType[fontFamily];

    const scale = Number(fontSize) / 1000;
    const ascender = Math.round(textOpenType.ascender * scale);
    const descender = Math.round(textOpenType.descender * scale);

    lineText.forEach((text, index) => {
      if (ctx) {
        ctx.fillText(
          text,
          Math.round(maxValue - width / 2),
          Math.round(
            maxValue -
              height / 2 +
              ascender -
              Math.round(descender * textOpenType.additional) +
              lineHeightPx * index
          )
        );
      }
    });
  };

  const rotatedCanvas = getRotatedCanvas(
    boundRect,
    angle,
    draw,
    RotateMode.Mode_1
  );

  ctx.drawImage(
    rotatedCanvas,
    boundRect[0] + boundRect[2] / 2 - maxValue,
    boundRect[1] + boundRect[3] / 2 - maxValue
  );
};

export const drawImageShape = (
  ctx: CanvasRenderingContext2D,
  canvasOptions: CanvasOptions,
  boundRect: Rect,
  image: HTMLImageElement,
  angle: number
) => {
  const width = boundRect[2];
  const height = boundRect[3];

  const maxValue = Math.max(width, height);

  const draw = (_canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
    ctx.drawImage(
      image,
      maxValue - width / 2,
      maxValue - height / 2,
      width,
      height
    );
  };

  const rotatedCanvas = getRotatedCanvas(
    boundRect,
    angle,
    draw,
    RotateMode.Mode_1
  );

  setCanvasOptions(ctx, canvasOptions);

  ctx.drawImage(
    rotatedCanvas,
    boundRect[0] + boundRect[2] / 2 - maxValue,
    boundRect[1] + boundRect[3] / 2 - maxValue
  );
};

export const drawFreeDrawElement = (
  ctx: CanvasRenderingContext2D,
  element: FreeDrawElement
) => {
  const rotatePoints = element.points.map((point) =>
    rotatePoint([element.cx, element.cy], point, element.angle)
  );

  const shape = getFreeDrawShape(
    rotatePoints,
    element.canvasOptions.lineWidth || 1
  );

  drawFreeDrawShape(ctx, element.canvasOptions, shape);
};

export const drawPolyLineElement = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  element: PolyLineElement
) => {
  const rotatePoints = element.points.map((point) =>
    rotatePoint([element.cx, element.cy], point, element.angle)
  );

  const shapes = getPolyLineShape(rotatePoints, element.roughOptions);

  drawPolyLineShape(canvas, ctx, element.canvasOptions, shapes);
};

export const drawRectangleElement = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  element: BoundingRectElement
) => {
  const boundRect = getElementBoundingRect(element);

  const maxValue = Math.max(boundRect[2], boundRect[3]);

  const round =
    element.round === Roundness.None
      ? 0
      : getCornerRadius(Math.min(boundRect[2], boundRect[3]));

  const shape = getRectangleShape(
    [
      maxValue - boundRect[2] / 2,
      maxValue - boundRect[3] / 2,
      boundRect[2],
      boundRect[3],
    ],
    round,
    element.roughOptions
  );

  drawRectangleShape(
    canvas,
    ctx,
    element.canvasOptions,
    boundRect,
    shape,
    element.angle
  );
};

export const drawEllipseElement = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  element: BoundingRectElement
) => {
  const boundRect = getElementBoundingRect(element);

  const generator = new RoughGenerator();

  const shape = generator.ellipse(
    element.width,
    element.height,
    element.width,
    element.height,
    element.roughOptions
  );

  drawEllipseShape(
    canvas,
    ctx,
    element.canvasOptions,
    boundRect,
    shape,
    element.angle
  );
};

export const drawTextElement = (
  ctx: CanvasRenderingContext2D,
  element: TextElement
) => {
  const boundRect = getElementBoundingRect(element);

  drawTextShape(
    ctx,
    element.canvasOptions,
    boundRect,
    element.text,
    element.font,
    element.fontSize,
    element.angle
  );
};

export const drawImageElement = (
  ctx: CanvasRenderingContext2D,
  element: ImageElement
) => {
  const boundRect = getElementBoundingRect(element);

  drawImageShape(
    ctx,
    element.canvasOptions,
    boundRect,
    element.image,
    element.angle
  );
};

export const drawElement = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  element: Element
) => {
  switch (element.type) {
    case EditorTool.FreeDraw:
      drawFreeDrawElement(ctx, element);
      break;
    case EditorTool.PolyLine:
      drawPolyLineElement(canvas, ctx, element);
      break;
    case EditorTool.Rectangle:
      drawRectangleElement(canvas, ctx, element);
      break;
    case EditorTool.Ellipse:
      drawEllipseElement(canvas, ctx, element);
      break;
    case EditorTool.Text:
      drawTextElement(ctx, element);
      break;
    case EditorTool.Image:
      drawImageElement(ctx, element);
      break;
    default:
      break;
  }
};

enum RotateMode {
  Mode_1 = 'Mode_1',
  Mode_2 = 'Mode_2',
}

const getRotatedCanvas = (
  boundRect: Rect,
  angle: number,
  draw: (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => void,
  mode?: RotateMode
) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const width = boundRect[2];
  const height = boundRect[3];

  const maxValue = Math.max(width, height);

  canvas.width = maxValue * 2;
  canvas.height = maxValue * 2;

  const rotateX = mode === RotateMode.Mode_1 ? maxValue : width;
  const rotateY = mode === RotateMode.Mode_1 ? maxValue : height;

  if (ctx) {
    ctx.save();

    ctx.translate(rotateX, rotateY);
    ctx.rotate(angle);
    ctx.translate(-rotateX, -rotateY);

    draw(canvas, ctx);

    ctx.restore();
  }

  return canvas;
};
