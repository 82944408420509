import { atom } from "jotai";

export type CanvasStatusInfo = {
  x: number;
  y: number;
  scale: number;
};

export const canvasStatusAtom = atom<CanvasStatusInfo>({
  x: 0,
  y: 0,
  scale: 100,
});
