import { getDefaultStore } from 'jotai';
import {
  BoundingRectElement,
  Element,
  PolyLineElement,
  frameIndexAtom,
  frameInfosAtom,
} from '../atoms/frameAtoms';
import {
  EditorColor,
  selectedStrokeColorAtom,
  selectedLineWidthAtom,
  selectedOpacityAtom,
  selectedFillColorAtom,
  EditorFontSize,
  Roundness,
} from '../atoms/optionAtom';
import { generateUUID } from './uuid';
import { EditorTool } from '../atoms/appAtoms';
import {
  getDistancePoints,
  isPointOnElement,
  projection,
  rotatePoint,
  transformPointsByRectangleSize,
} from './geometry';
import { DISTANCE_THRESHOLD, Point, Rect, generateRoughOptions } from './shape';
import { inRange } from 'lodash';
import { getAdjustPoint, getBoundingRect } from './coordinate';
import { AnchorPointType } from './canvas';
import { measureText } from './text';
import { FrameInfoManager } from '../modules/FrameInfoManager';

const store = getDefaultStore();

export const createElement = (props: Partial<Element>) => {
  const strokeColor = store.get(selectedStrokeColorAtom);
  const fillColor = store.get(selectedFillColorAtom);
  // const color = store.get(selectedStrokeColorAtom);
  const lineWidth = store.get(selectedLineWidthAtom);
  // const lineBoarder = store.get(selectedLineBoarderAtom);
  // const fontFamily = store.get(selectedFontFamilyAtom);
  // const fontSize = store.get(selectedFontSizeAtom);
  // const textAlign = store.get(selectedTextAlignAtom);
  const opacity = store.get(selectedOpacityAtom);

  const canvasOptions = {
    strokeStyle: strokeColor,
    fillStyle: fillColor,
    globalAlpha: opacity,
    lineCap: 'round',
    lineJoin: 'round',
    lineWidth: lineWidth,
    dashed: [],
  };

  const roughOptions = generateRoughOptions();

  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  const element = {
    ...props,
    canvasOptions,
    roughOptions,
  } as Element;

  element.id = generateUUID();

  element.angle = 0;

  FrameInfoManager.addElement([element]);
  updatedFrameInfos[frameIndex].elements.push(element);

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const createElements = (elements: Element[]) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  FrameInfoManager.addElement(elements);

  updatedFrameInfos[frameIndex].elements =
    updatedFrameInfos[frameIndex].elements.concat(elements);

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementPosition = (id: string[], diff: Point) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (id.includes(element.id)) {
      if (
        element.type === EditorTool.FreeDraw ||
        element.type === EditorTool.PolyLine ||
        element.type === EditorTool.Rectangle ||
        element.type === EditorTool.Ellipse ||
        element.type === EditorTool.Text ||
        element.type === EditorTool.Image
      ) {
        if ((element as PolyLineElement).points) {
          (element as PolyLineElement).points.forEach((point) => {
            point[0] += diff[0];
            point[1] += diff[1];
          });
        }

        element.x += diff[0];
        element.y += diff[1];
        element.cx += diff[0];
        element.cy += diff[1];
      }
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementBoundingRect = (
  id: string,
  diff: Point,
  adjustPoint: {
    index: number;
    point: Point;
  },
  isMaintainAspectRatio: boolean
) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (id.includes(element.id)) {
      const originalX = element.x;
      const originalY = element.y;
      const originalWidth = element.width;
      const originalHeight = element.height;

      const originalAspectRatio = originalWidth / originalHeight;

      if (
        isMaintainAspectRatio &&
        (adjustPoint.index === 0 ||
          adjustPoint.index === 1 ||
          adjustPoint.index === 2 ||
          adjustPoint.index === 3)
      ) {
        diff = rotatePoint([0, 0], diff, -element.angle);
        diff[1] =
          (diff[0] / originalAspectRatio) *
          (adjustPoint.index === 0 || adjustPoint.index === 3 ? 1 : -1);
        diff = rotatePoint([0, 0], diff, element.angle);
      }

      const rotateD = rotatePoint([0, 0], diff, -element.angle);

      const el = element;

      if (
        adjustPoint.index === 0 ||
        adjustPoint.index === 1 ||
        adjustPoint.index === 2 ||
        adjustPoint.index === 3
      ) {
        el.cx += diff[0] / 2;
        el.cy += diff[1] / 2;
        el.width +=
          adjustPoint.index === 1 || adjustPoint.index === 3
            ? rotateD[0]
            : -rotateD[0];
        el.height +=
          adjustPoint.index === 2 || adjustPoint.index === 3
            ? rotateD[1]
            : -rotateD[1];
      } else if (adjustPoint.index === 4 || adjustPoint.index === 5) {
        const normalVector = rotatePoint([0, 0], [0, -1], el.angle);
        const projectionPoint = projection(normalVector, diff);

        el.cx += (normalVector[0] * projectionPoint) / 2;
        el.cy += (normalVector[1] * projectionPoint) / 2;
        el.height += adjustPoint.index === 5 ? rotateD[1] : -rotateD[1];
      } else if (adjustPoint.index === 6 || adjustPoint.index === 7) {
        const normalVector = rotatePoint(
          [0, 0],
          [0, -1],
          el.angle + Math.PI * 0.5
        );
        const projectionPoint = projection(normalVector, diff);

        el.cx += (normalVector[0] * projectionPoint) / 2;
        el.cy += (normalVector[1] * projectionPoint) / 2;
        el.width += adjustPoint.index === 7 ? rotateD[0] : -rotateD[0];
      }

      el.x = el.cx - el.width / 2;
      el.y = el.cy - el.height / 2;

      if (el.type === EditorTool.FreeDraw || el.type === EditorTool.PolyLine) {
        el.points = transformPointsByRectangleSize(
          el.points,
          originalWidth,
          originalHeight,
          el.width,
          el.height,
          [originalX, originalY],
          [el.x, el.y]
        );
      } else if (el.type === EditorTool.Text) {
        el.fontSize = String(
          (Number(el.fontSize) * el.height) / originalHeight
        ) as EditorFontSize;
        const metrics = measureText(
          el.text,
          `${el.fontSize}px ${el.font}`,
          1.2
        );

        el.width = metrics.width;
        el.cx = el.x + metrics.width / 2;
      }

      element = el;

      return false;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementAngle = (id: string, angle: number) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (id === element.id) {
      element.angle = angle;
      return false;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementStrokeColor = (ids: string[], color: EditorColor) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (ids.includes(element.id)) {
      if (
        element.type === EditorTool.FreeDraw ||
        element.type === EditorTool.Text
      ) {
        element.canvasOptions.strokeStyle = color;
      } else if (
        element.type === EditorTool.PolyLine ||
        element.type === EditorTool.Rectangle ||
        element.type === EditorTool.Ellipse
      ) {
        element.roughOptions.stroke = color;
      }
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementFillColor = (ids: string[], color: EditorColor) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (ids.includes(element.id)) {
      if (
        element.type === EditorTool.FreeDraw ||
        element.type === EditorTool.Text
      ) {
        element.canvasOptions.fillStyle = color;
      } else if (
        element.type === EditorTool.PolyLine ||
        element.type === EditorTool.Rectangle ||
        element.type === EditorTool.Ellipse
      ) {
        element.roughOptions.fill = color;
      }
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementLineWidth = (ids: string[], lineWIdth: number) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (ids.includes(element.id)) {
      if (element.type === EditorTool.FreeDraw) {
        element.canvasOptions.lineWidth = lineWIdth;
      } else if (
        element.type === EditorTool.PolyLine ||
        element.type === EditorTool.Rectangle ||
        element.type === EditorTool.Ellipse
      ) {
        element.roughOptions.strokeWidth = lineWIdth;
      }
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementOpacity = (ids: string[], opacity: number) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (ids.includes(element.id)) {
      element.canvasOptions.globalAlpha = opacity;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementRoughness = (ids: string[], roughness: number) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (ids.includes(element.id)) {
      (element as BoundingRectElement).roughOptions.roughness = roughness;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementBowing = (ids: string[], bowing: number) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (ids.includes(element.id)) {
      (element as BoundingRectElement).roughOptions.bowing = bowing;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementFillWeight = (ids: string[], fillWeight: number) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (ids.includes(element.id)) {
      (element as BoundingRectElement).roughOptions.fillWeight = fillWeight;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementHachureAngle = (
  ids: string[],
  hachureAngle: number
) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (ids.includes(element.id)) {
      (element as BoundingRectElement).roughOptions.hachureAngle = hachureAngle;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementHachureGap = (ids: string[], hachureGap: number) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (ids.includes(element.id)) {
      (element as BoundingRectElement).roughOptions.hachureGap = hachureGap;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementFillStyle = (ids: string[], fillStyle: string) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (ids.includes(element.id)) {
      (element as BoundingRectElement).roughOptions.fillStyle = fillStyle;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementRoundness = (ids: string[], roundness: Roundness) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (ids.includes(element.id)) {
      (element as BoundingRectElement).round = roundness;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const removeElements = (ids: string[]) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements = updatedFrameInfos[
    frameIndex
  ].elements.filter((el) => !ids.includes(el.id));

  FrameInfoManager.deleteElement(ids);

  store.set(frameInfosAtom, updatedFrameInfos);
};

export const updateElementPoint = (
  id: string,
  diff: Point,
  updatePoint: {
    index: number;
    point: Point;
  }
) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (id === element.id) {
      const el = element as PolyLineElement;

      el.points[updatePoint.index] = [
        el.points[updatePoint.index][0] + diff[0],
        el.points[updatePoint.index][1] + diff[1],
      ];

      const boundingRect = getBoundingRect(el.points);

      el.x = boundingRect[0];
      el.y = boundingRect[1];
      el.width = boundingRect[2];
      el.height = boundingRect[3];
      el.cx = boundingRect[0] + boundingRect[2] / 2;
      el.cy = boundingRect[1] + boundingRect[3] / 2;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getHoveredElement = (point: Point): Element | null => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const frameInfo = frameInfos[frameIndex];

  const { elements } = frameInfo;

  for (let i = elements.length - 1; i >= 0; i--) {
    const hoveredElement = isPointOnElement(point, elements[i]);

    if (hoveredElement) {
      return hoveredElement;
    }
  }

  return null;
};

export const getHoveredPointOnElement = (
  point: Point,
  element: Element,
  anchorPointType: AnchorPointType
) => {
  const rect = getElementBoundingRect(element);
  const points = getAdjustPoint(
    [rect[0] - 10, rect[1] - 10, rect[2] + 20, rect[3] + 20],
    element.angle,
    anchorPointType
  );

  for (let i = 0; i < points.length; i++) {
    if (getDistancePoints(point, points[i]) < DISTANCE_THRESHOLD) {
      return {
        type:
          (anchorPointType === AnchorPointType.FullType && i === 8) ||
          (anchorPointType === AnchorPointType.HalfType && i === 4)
            ? 'rotate'
            : 'anchor',
        index: i,
        point: points[i],
      };
    }
  }

  if (element.type === EditorTool.PolyLine) {
    for (let i = 0; i < element.points.length; i++) {
      if (getDistancePoints(point, element.points[i]) < DISTANCE_THRESHOLD) {
        return {
          type: 'point',
          index: i,
          point: points[i],
        };
      }
    }
  }
};

export const getElementBoundingRect = (element: Element): Rect => {
  return [element.x, element.y, element.width, element.height];
};

export const getHoveredPointOnCrop = (point: Point, rect: Rect) => {
  const points = [
    [rect[0], rect[1]],
    [rect[0] + rect[2], rect[1]],
    [rect[0], rect[1] + rect[3]],
    [rect[0] + rect[2], rect[1] + rect[3]],
    [rect[0] + rect[2] / 2, rect[1]],
    [rect[0] + rect[2] / 2, rect[1] + +rect[3]],
    [rect[0], rect[1] + rect[3] / 2],
    [rect[0] + rect[2], rect[1] + rect[3] / 2],
  ] as Point[];

  for (let i = 0; i < points.length; i++) {
    const distance = getDistancePoints(points[i], point);

    if (distance < DISTANCE_THRESHOLD) {
      return {
        type: 'anchor',
        index: i,
        point: points[i],
      };
    }
  }

  if (
    inRange(point[0], rect[0], rect[0] + rect[2]) &&
    inRange(point[1], rect[1], rect[1] + rect[3])
  ) {
    return {
      type: 'crop',
      index: -1,
      point: [-1, -1],
    };
  }
};

export const getElementTemplate = (
  type: EditorTool,
  boundingRect: Rect,
  points?: Point[]
) => {
  return {
    type,
    x: boundingRect[0],
    y: boundingRect[1],
    cx: boundingRect[0] + boundingRect[2] / 2,
    cy: boundingRect[1] + boundingRect[3] / 2,
    width: boundingRect[2],
    height: boundingRect[3],
    points,
  } as Partial<Element>;
};

export const updatePolylinePointUpdate = (
  id: string,
  index: number,
  point: Point
) => {
  const frameInfos = store.get(frameInfosAtom);
  const frameIndex = store.get(frameIndexAtom);

  const updatedFrameInfos = [...frameInfos];

  updatedFrameInfos[frameIndex].elements.forEach((element) => {
    if (id === element.id && element.type === EditorTool.PolyLine) {
      element.points[index] = point;
      return false;
    }
  });

  store.set(frameInfosAtom, updatedFrameInfos);
};
