import { useCallback, useEffect } from 'react';
import { CanvasStatusInfo } from '../atoms/canvasAtoms';

type CanvasWindowResizeEventProps = {
  canvas: HTMLCanvasElement | null;
  ctx?: CanvasRenderingContext2D | null;
  rerender?: VoidFunction;
  canvasStatusInfo?: CanvasStatusInfo;
};

export const useCanvasWindowResizeEvent = (
  props: CanvasWindowResizeEventProps
) => {
  const { canvas, ctx, rerender, canvasStatusInfo } = props;

  const handleWindowResizeEvent = useCallback(() => {
    // const isOriented = Math.abs(orientation) === 90;

    if (canvas) {
      // const dpr = window.devicePixelRatio || 1;

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      if (ctx && canvasStatusInfo) {
        ctx.setTransform(
          canvasStatusInfo.scale * 0.01,
          0,
          0,
          canvasStatusInfo.scale * 0.01,
          canvasStatusInfo.x,
          canvasStatusInfo.y
        );
      }

      rerender?.();
    }
  }, [canvas, ctx, rerender, canvasStatusInfo]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResizeEvent);
    window.addEventListener('orientationchange', handleWindowResizeEvent);

    return () => {
      window.removeEventListener('resize', handleWindowResizeEvent);
      window.removeEventListener('orientationchange', handleWindowResizeEvent);
    };
  }, [handleWindowResizeEvent]);
};
