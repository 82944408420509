import { useAtom } from 'jotai';
import SystemModal from './SystemModal';
import { isMyQustionOpenAtom } from '../atoms/appAtoms';
import { useCallback } from 'react';

const MyQuestionDialog = () => {
  const [isMyQuestionOpen, setMyQuestionOpen] = useAtom(isMyQustionOpenAtom);

  const handleConfirmMyQuestion = useCallback(() => {
    window.location.reload();
  }, []);

  const handleCloseMyQuestion = useCallback(() => {
    setMyQuestionOpen(false);
  }, [setMyQuestionOpen]);

  return (
    <SystemModal
      open={isMyQuestionOpen}
      title='Are you sure you want to leave this Page?'
      onClickConfirm={handleConfirmMyQuestion}
      onClickCancel={handleCloseMyQuestion}
      onClose={handleCloseMyQuestion}
      confirmText={`Confirm`}
      cancelText={`Close`}
      width={400}
    >
      <div
        style={{
          paddingBottom: '32px',
          color: '#c1c1c1',
          fontSize: '14px',
        }}
      >
        Are you sure you want to leave this Page?
      </div>
    </SystemModal>
  );
};

export default MyQuestionDialog;
