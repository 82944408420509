import { useCallback, useEffect } from 'react';
import './App.scss';
import ExportGifDialog from './components/ExportGifDialog';
import FrameList from './components/FrameList';
// import GuideDialog from './components/GuideDialog';

import InteractiveCanvas from './components/InteractiveCanvas';
import Loading from './components/Loading';
import MainMenu from './components/MainMenu';
import Navigation from './components/Navigation';
import NewWorkspaceDialog from './components/NewWorkspaceDialog';
import OptionDialog from './components/OptionDialog';
import PreviewDialog from './components/PreviewDialog';
import StaticCanvas from './components/StaticCanvas';
import VideoSectionDialog from './components/VideoSectionDialog';
import { handleAttachImageFile } from './utils/file';
import { useAtomValue } from 'jotai';
import { frameInfosAtom } from './atoms/frameAtoms';
import NewDocument from './components/NewDocument';
import MyQuestionDialog from './components/MyQuestionDialog';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  const frameInfos = useAtomValue(frameInfosAtom);

  const handlePreventTouch = useCallback((evt: any) => {
    if (
      !evt.target.closest('.frame-list-wrapper') &&
      !evt.target.closest('.option-wrapper') &&
      !evt.target.closest('.main-content')
    ) {
      evt.preventDefault();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('touchmove', handlePreventTouch, {
      passive: false,
    });

    return () => document.removeEventListener('touchmove', handlePreventTouch);
  }, [handlePreventTouch]);

  useEffect(() => {
    const handleDropFile = (evt: any) => {
      evt.preventDefault();
      evt.stopPropagation();

      const dt = evt.dataTransfer;
      const files = dt.files;

      // 드롭된 파일 처리
      for (const file of files) {
        if (file.type.startsWith('image/')) {
          handleAttachImageFile(file);
        } else {
          alert('Please drop only image files.');
        }
      }
    };

    const handleDragOver = (evt: DragEvent) => {
      evt.preventDefault(); // 기본 동작 방지
      evt.stopPropagation();
    };

    window.addEventListener('dragover', handleDragOver, false);
    window.addEventListener('drop', handleDropFile, false);

    return () => {
      window.removeEventListener('dragover', handleDragOver, false);
      window.removeEventListener('drop', handleDropFile, false);
    };
  }, []);

  return (
    <div className='app'>
      <ErrorBoundary>
        <Navigation />
        <MainMenu />
        <StaticCanvas />
        <InteractiveCanvas />
        {frameInfos.length === 0 && <NewDocument />}
        <OptionDialog />
        <FrameList />
        <NewWorkspaceDialog />
        <VideoSectionDialog />
        <PreviewDialog />
        <MyQuestionDialog />
        {/* <PreviewButton />
      <HistoryButton /> */}
        <ExportGifDialog />
        {/*
      <GuideDialog /> */}
        <Loading />
      </ErrorBoundary>
    </div>
  );
}

export default App;
