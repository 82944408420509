import {
  MouseEvent,
  PointerEvent,
  TouchEvent,
  WheelEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { canvasStatusAtom } from '../atoms/canvasAtoms';
import { clamp, cloneDeep, mean } from 'lodash';
import { useCanvasWindowResizeEvent } from '../hooks/useCanvasWindowResizeEvent';
import {
  BoundingRectElement,
  Element,
  TextElement,
  frameIndexAtom,
  frameInfosAtom,
  selectedElementsAtom,
} from '../atoms/frameAtoms';
import {
  EditorTool,
  activeToolAtom,
  isSelectCropAtom,
} from '../atoms/appAtoms';
import {
  AnchorPointType,
  clearCanvas,
  cropStrokeOption,
  drawSVGPathOnCanvas,
  drawShapeOnRoughCanvas,
  drawShapesOnRoughCanvas,
  fillRectCanvas,
  getDataPointInfo,
  getRectInfoFromPoints,
  strokeRectCanvas,
} from '../utils/canvas';
import {
  getDistancePoints,
  getTransformRectFromOnePoint,
  getTransformedBoundingRect,
} from '../utils/geometry';
import {
  getFrameCropInfo,
  getHoveredPointOnRect,
  updateCropInfo,
  updateElements,
} from '../utils/frame';
import {
  selectedStrokeColorAtom,
  selectedLineWidthAtom,
  selectedOpacityAtom,
  synchronizedCropScaleAtom,
  selectedFillColorAtom,
  EditorColor,
  selectedFontFamilyAtom,
  selectedFontSizeAtom,
  selectedRoundnessAtom,
  Roundness,
} from '../atoms/optionAtom';
import { generateFreeDrawShape } from '../utils/render';
import {
  createElement,
  createElements,
  getElementTemplate,
  getHoveredElement,
  getHoveredPointOnCrop,
  getHoveredPointOnElement,
  removeElements,
  updateElementAngle,
  updateElementBoundingRect,
  updateElementPoint,
  updateElementPosition,
  updatePolylinePointUpdate,
} from '../utils/element';
import useKeyEvent from '../hooks/useKeyEvent';
import {
  getAbsolutePosition,
  getBoundingRect,
  getDiff,
  getRotationRadian,
} from '../utils/coordinate';
import {
  DISTANCE_THRESHOLD,
  Point,
  Rect,
  generateRoughOptions,
  getEllipseShape,
  getPolyLineShape,
  getRectangleShape,
} from '../utils/shape';
import { getCornerRadius, getTransform } from '../utils/math';
import { measureText } from '../utils/text';
import { generateUUID } from '../utils/uuid';
import { FrameInfoManager } from '../modules/FrameInfoManager';

export const POINT_RADIUS = 8;

const InteractiveCanvas = () => {
  const [frameInfos] = useAtom(frameInfosAtom);
  const [canvasStatusInfo, setCanvasStatusInfo] = useAtom(canvasStatusAtom);
  const frameIndex = useAtomValue(frameIndexAtom);
  const activeTool = useAtomValue(activeToolAtom);
  const synchronizedCropScale = useAtomValue(synchronizedCropScaleAtom);
  const selectedLineWidth = useAtomValue(selectedLineWidthAtom);
  const selectedColor = useAtomValue(selectedStrokeColorAtom);
  const selectedFillColor = useAtomValue(selectedFillColorAtom);
  const selectedOpacity = useAtomValue(selectedOpacityAtom);
  const selectedFontFamily = useAtomValue(selectedFontFamilyAtom);
  const selectedFontSize = useAtomValue(selectedFontSizeAtom);
  const [selectedElements, setSelectedElements] = useAtom(selectedElementsAtom);
  const [isSelectCrop, setSelectCrop] = useAtom(isSelectCropAtom);
  const selectedRoundness = useAtomValue(selectedRoundnessAtom);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);

  const isPointerDown = useRef<boolean>(false);
  const isCropSelect = useRef<boolean>(false);
  const selectedHoveredPointInfo = useRef<any>(null);

  const isTextEditor = useRef<boolean>(false);
  const textAreaRef = useRef<[number, number]>([0, 0]);

  const downedMousePointer = useRef<Point | null>(null);
  const _downedMousePointer = useRef<Point | null>(null);
  const _touchedMousePointer = useRef<Point | null>(null);

  const pointsRef = useRef<Point[]>([]);
  const polylineRef = useRef<Point[]>([]);

  const copiedElements = useRef<Element[]>([]);

  const handlePointerDown = useCallback(
    (evt: PointerEvent) => {
      // evt.stopPropagation();
      // evt.preventDefault();

      const { offsetX, offsetY, shiftKey } = evt.nativeEvent;

      if (!canvasRef.current || !ctxRef.current) return;
      if (frameInfos.length === 0) return;

      const mousePoint = getAbsolutePosition([offsetX, offsetY]);

      isPointerDown.current = true;
      downedMousePointer.current = mousePoint;
      _downedMousePointer.current = [offsetX, offsetY];

      if (activeTool === EditorTool.Select) {
        const hoveredElement = getHoveredElement(mousePoint);

        if (hoveredElement) {
          setSelectedElements(
            shiftKey ? [...selectedElements, hoveredElement] : [hoveredElement]
          );
        }

        if (selectedElements.length === 1) {
          const anchorPointType = [EditorTool.Image, EditorTool.Text].includes(
            selectedElements[0].type
          )
            ? AnchorPointType.HalfType
            : AnchorPointType.FullType;

          const hoveredPoint = getHoveredPointOnElement(
            mousePoint,
            selectedElements[0],
            anchorPointType
          );

          selectedHoveredPointInfo.current = hoveredPoint;
        }

        if (!hoveredElement && !selectedHoveredPointInfo.current) {
          setSelectedElements([]);
        }
      } else if (activeTool === EditorTool.Crop) {
        const { cropInfo } = frameInfos?.[frameIndex] || {};

        if (cropInfo) {
          const hoveredPoint = getHoveredPointOnCrop(mousePoint, cropInfo);
          selectedHoveredPointInfo.current = hoveredPoint;

          setSelectCrop(!!hoveredPoint);
        }
      } else if (activeTool === EditorTool.FreeDraw) {
        pointsRef.current = [mousePoint];
      } else if (activeTool === EditorTool.PolyLine) {
        if (polylineRef.current.length > 1) {
          const firstPoint = polylineRef.current[0];

          const distance = getDistancePoints(mousePoint, firstPoint);

          if (distance < DISTANCE_THRESHOLD) {
            polylineRef.current.push([...polylineRef.current[0]]);

            const boundingRect = getRectInfoFromPoints(polylineRef.current);

            const element = getElementTemplate(activeTool, boundingRect, [
              ...polylineRef.current,
            ]);

            createElement(element);

            clearCanvas(canvasRef.current, ctxRef.current);
            polylineRef.current = [];

            return;
          }
        }
        polylineRef.current.push(mousePoint);
      } else if (activeTool === EditorTool.Text) {
        if (!isTextEditor.current) {
          const textEditorWrapper = document.getElementById('textEditor');
          const textarea = document.createElement('textarea');

          const metrics = measureText(
            '  ',
            `${selectedFontSize}px ${selectedFontFamily}`,
            1.2
          );

          textarea.style.width = `${metrics.width}px`;
          textarea.style.height = `${metrics.height + 10}px`;

          textarea.style.color =
            selectedFillColor === EditorColor.Transparent
              ? 'black'
              : selectedFillColor;

          textarea.style.fontFamily = selectedFontFamily;
          textarea.style.fontSize = `${selectedFontSize}px`;

          textarea.style.transform = getTransform(
            metrics.width,
            metrics.height + 10,
            canvasStatusInfo.scale * 0.01
          );

          const handleInputTextArea = (evt: any) => {
            const value = evt.currentTarget.value;
            const metrics = measureText(
              `${value}   `,
              `${selectedFontSize}px ${selectedFontFamily}`,
              1.2
            );
            textarea.style.width = `${metrics.width}px`;
            textarea.style.height = `${metrics.height + 10}px`;
            textarea.style.transform = getTransform(
              metrics.width,
              metrics.height + 10,
              canvasStatusInfo.scale * 0.01
            );
          };

          textarea.addEventListener('input', handleInputTextArea);

          if (textEditorWrapper) {
            textAreaRef.current = [
              (offsetX - canvasStatusInfo.x) / (canvasStatusInfo.scale * 0.01),
              (offsetY - canvasStatusInfo.y) / (canvasStatusInfo.scale * 0.01),
            ];
            textEditorWrapper.style.top = `${offsetY}px`;
            textEditorWrapper.style.left = `${offsetX}px`;

            textEditorWrapper.replaceChildren(textarea);
            textarea.focus();
            isTextEditor.current = true;
          }
        } else {
          const textEditorWrapper = document.getElementById('textEditor');

          if (textEditorWrapper) {
            const textarea = textEditorWrapper.querySelector('textarea');
            const text = textarea?.value || '';
            textEditorWrapper.innerHTML = '';
            isTextEditor.current = false;

            const top = Number(textEditorWrapper.style.top.replace('px', ''));
            const left = Number(textEditorWrapper.style.left.replace('px', ''));
            const width = Number(textarea?.style.width.replace('px', ''));
            const height = Number(textarea?.style.height.replace('px', ''));

            const offset = getDataPointInfo([left, top], canvasStatusInfo);

            const boundingRect = [offset[0], offset[1], width, height] as Rect;

            const element = getElementTemplate(EditorTool.Text, boundingRect);

            (element as TextElement).text = text;
            (element as TextElement).font = selectedFontFamily;
            (element as TextElement).fontSize = selectedFontSize;

            createElement(element);
          }
        }
      }
    },
    [
      activeTool,
      frameInfos,
      frameIndex,
      canvasStatusInfo,
      selectedElements,
      selectedFontFamily,
      selectedFontSize,
      setSelectedElements,
      getHoveredPointOnRect,
    ]
  );

  const handlePointerMove = useCallback(
    (evt: PointerEvent) => {
      evt.stopPropagation();
      evt.preventDefault();

      const { offsetX, offsetY, shiftKey, altKey } = evt.nativeEvent;

      if (!canvasRef.current || !ctxRef.current) return;
      if (frameInfos.length === 0) return;
      if (isTextEditor.current) return;

      const mousePoint = getAbsolutePosition([offsetX, offsetY]);
      const downedMousePoint = downedMousePointer.current || [0, 0];

      if (activeTool === EditorTool.Select || altKey) {
        if (isPointerDown.current) {
          const [dx, dy] = getDiff(downedMousePoint, mousePoint);

          if (
            selectedElements.length === 1 &&
            selectedHoveredPointInfo.current
          ) {
            const { id, cx, cy } = selectedElements[0];

            if (selectedHoveredPointInfo.current.type === 'rotate') {
              updateElementAngle(id, getRotationRadian([cx, cy], mousePoint));
            } else if (selectedHoveredPointInfo.current.type === 'anchor') {
              updateElementBoundingRect(
                id,
                [dx, dy],
                selectedHoveredPointInfo.current,
                shiftKey ||
                  selectedElements[0].type === EditorTool.Image ||
                  selectedElements[0].type === EditorTool.Text
              );
            } else if (selectedHoveredPointInfo.current.type === 'point') {
              updateElementPoint(
                id,
                [dx, dy],
                selectedHoveredPointInfo.current
              );
            }
          } else if (selectedElements.length > 0) {
            updateElementPosition(
              selectedElements.map((el) => el.id),
              [dx, dy]
            );
          } else {
            if (window.innerWidth < 712 && _downedMousePointer.current) {
              const [dx, dy] = getDiff(_downedMousePointer.current, [
                offsetX,
                offsetY,
              ]);

              setCanvasStatusInfo({
                ...canvasStatusInfo,
                x: canvasStatusInfo.x + dx,
                y: canvasStatusInfo.y + dy,
              });
              _downedMousePointer.current = [offsetX, offsetY];
            }
          }

          if (!_downedMousePointer.current) {
            _downedMousePointer.current = [offsetX, offsetY];
          }
        } else if (!isPointerDown.current) {
          const hoveredElement = getHoveredElement(mousePoint);
          canvasRef.current.style.cursor = hoveredElement ? 'move' : 'default';

          if (selectedElements.length === 1) {
            const anchorPointType = [
              EditorTool.Image,
              EditorTool.Text,
            ].includes(selectedElements[0].type)
              ? AnchorPointType.HalfType
              : AnchorPointType.FullType;

            const hoveredPoint = getHoveredPointOnElement(
              mousePoint,
              selectedElements[0],
              anchorPointType
            );

            canvasRef.current.style.cursor = hoveredPoint
              ? 'pointer'
              : 'default';
          }
        }

        downedMousePointer.current = mousePoint;
        return;
      } else if (activeTool === EditorTool.Crop) {
        const cropInfo = getFrameCropInfo();

        if (cropInfo) {
          if (isPointerDown.current) {
            const [dx, dy] = getDiff(downedMousePoint, mousePoint);

            if (selectedHoveredPointInfo.current) {
              const transformedCropInfo = getTransformedBoundingRect(
                cropInfo,
                [dx, dy],
                selectedHoveredPointInfo.current
              );

              updateCropInfo(transformedCropInfo, synchronizedCropScale);

              downedMousePointer.current = mousePoint;
            }
          } else {
            const isPointOnCrop = getHoveredPointOnCrop(mousePoint, cropInfo);

            canvasRef.current.style.cursor = isPointOnCrop
              ? isPointOnCrop.type === 'anchor'
                ? 'pointer'
                : 'move'
              : 'default';
          }
        } else {
          if (isPointerDown.current) {
            clearCanvas(canvasRef.current, ctxRef.current);

            const boundRect = getBoundingRect([downedMousePoint, mousePoint]);

            const tempCanvas = document.createElement('canvas');
            const tempCtx = tempCanvas.getContext('2d');
            tempCanvas.width = frameInfos[frameIndex].canvas.width;
            tempCanvas.height = frameInfos[frameIndex].canvas.height;

            if (tempCanvas && tempCtx) {
              fillRectCanvas(
                tempCtx,
                [
                  0,
                  0,
                  frameInfos[frameIndex].canvas.width,
                  frameInfos[frameIndex].canvas.height,
                ],
                {
                  globalCompositeOperation: 'source-over',
                  fillStyle: 'rgba(0, 0, 0, 0.6)',
                }
              );

              fillRectCanvas(tempCtx, boundRect, {
                globalCompositeOperation: 'destination-out',
              });

              ctxRef.current.drawImage(tempCanvas, 0, 0);
            }

            strokeRectCanvas(ctxRef.current, boundRect, cropStrokeOption);
          }
        }
        return;
      } else if (activeTool === EditorTool.Eraser) {
        if (isPointerDown.current) {
          const hoveredElement = getHoveredElement(mousePoint);
          if (hoveredElement) {
            removeElements([hoveredElement.id]);
          }
        }
      }

      if (
        activeTool !== EditorTool.PolyLine &&
        (!isPointerDown.current || !downedMousePoint)
      )
        return;

      clearCanvas(canvasRef.current, ctxRef.current);

      const boundingRect = getBoundingRect([downedMousePoint, mousePoint]);

      if (activeTool === EditorTool.FreeDraw) {
        if (isPointerDown.current) {
          pointsRef.current.push(mousePoint);

          const path = generateFreeDrawShape(
            pointsRef.current,
            Number(selectedLineWidth)
          );

          drawSVGPathOnCanvas(ctxRef.current, path, {
            globalAlpha: selectedOpacity,
            fillStyle: selectedColor,
          });
        }
      } else if (activeTool === EditorTool.PolyLine) {
        if (polylineRef.current.length) {
          const points = [
            ...polylineRef.current,
            getDataPointInfo([offsetX, offsetY], canvasStatusInfo),
          ];

          drawShapesOnRoughCanvas(
            canvasRef.current,
            getPolyLineShape(points, generateRoughOptions())
          );
        }
      } else if (activeTool === EditorTool.Rectangle) {
        if (isPointerDown.current && downedMousePointer.current) {
          const [, , width, height] = getBoundingRect([
            downedMousePoint,
            mousePoint,
          ]);
          const round =
            selectedRoundness === Roundness.None
              ? 0
              : getCornerRadius(Math.min(width, height));

          drawShapeOnRoughCanvas(
            canvasRef.current,
            getRectangleShape(boundingRect, round, generateRoughOptions())
          );
        }
      } else if (activeTool === EditorTool.Ellipse) {
        if (isPointerDown.current && downedMousePointer.current) {
          drawShapeOnRoughCanvas(
            canvasRef.current,
            getEllipseShape(boundingRect, generateRoughOptions())
          );
        }
      }
    },
    [
      activeTool,
      frameInfos,
      frameIndex,
      isSelectCrop,
      selectedLineWidth,
      selectedOpacity,
      selectedColor,
      selectedElements,
      canvasStatusInfo,
      synchronizedCropScale,
      selectedRoundness,
      setCanvasStatusInfo,
      getTransformRectFromOnePoint,
    ]
  );

  const handlePointerUp = useCallback(
    (evt: PointerEvent) => {
      evt.stopPropagation();
      evt.preventDefault();

      const { offsetX, offsetY } = evt.nativeEvent;

      if (!canvasRef.current || !ctxRef.current) return;

      if (
        !isPointerDown.current ||
        !downedMousePointer.current ||
        frameInfos.length === 0
      )
        return;

      const mousePoint = getAbsolutePosition([offsetX, offsetY]);
      const downedMousePoint = downedMousePointer.current || [0, 0];

      if (activeTool === EditorTool.Select) {
        if (selectedElements.length > 0) {
          FrameInfoManager.updateElement(selectedElements);
        }
      }

      if (activeTool === EditorTool.Select) {
        if (
          selectedElements.length === 1 &&
          selectedElements[0].type === EditorTool.PolyLine &&
          selectedHoveredPointInfo.current &&
          selectedHoveredPointInfo.current.type
        ) {
          if (selectedHoveredPointInfo.current.index === 0) {
            const distance = getDistancePoints(
              selectedElements[0].points[0],
              selectedElements[0].points[selectedElements[0].points.length - 1]
            );
            if (distance < DISTANCE_THRESHOLD) {
              updatePolylinePointUpdate(selectedElements[0].id, 0, [
                ...selectedElements[0].points[
                  selectedElements[0].points.length - 1
                ],
              ]);
            }
          } else if (
            selectedHoveredPointInfo.current.index ===
            selectedElements[0].points.length - 1
          ) {
            const distance = getDistancePoints(
              selectedElements[0].points[0],
              selectedElements[0].points[selectedElements[0].points.length - 1]
            );

            if (distance < DISTANCE_THRESHOLD) {
              updatePolylinePointUpdate(
                selectedElements[0].id,
                selectedElements[0].points.length - 1,
                [...selectedElements[0].points[0]]
              );
            }
          }
        }
      } else if (activeTool === EditorTool.Crop) {
        const cropInfo = getFrameCropInfo();

        if (!cropInfo && !isCropSelect.current) {
          const cropInfo = getBoundingRect([downedMousePoint, mousePoint]);
          updateCropInfo(cropInfo, synchronizedCropScale);
          setSelectCrop(true);
        }
      } else if (activeTool === EditorTool.FreeDraw) {
        if (pointsRef.current.length > 1) {
          const boundingRect = getBoundingRect(pointsRef.current);

          createElement(
            getElementTemplate(activeTool, boundingRect, [...pointsRef.current])
          );
        }
      } else if (
        activeTool === EditorTool.Rectangle ||
        activeTool === EditorTool.Ellipse
      ) {
        const boundingRect = getBoundingRect([downedMousePoint, mousePoint]);

        const element = getElementTemplate(activeTool, boundingRect);

        (element as BoundingRectElement).round = selectedRoundness;

        createElement(element);
      }

      if (activeTool !== EditorTool.PolyLine) {
        clearCanvas(canvasRef.current, ctxRef.current);
      }

      isPointerDown.current = false;
      isCropSelect.current = false;
      selectedHoveredPointInfo.current = null;
      downedMousePointer.current = null;
      _downedMousePointer.current = null;
      _touchedMousePointer.current = null;
      pointsRef.current = [];
    },
    [
      activeTool,
      frameInfos,
      frameIndex,
      selectedRoundness,
      canvasStatusInfo,
      synchronizedCropScale,
    ]
  );

  const handleWheel = useCallback(
    (evt: WheelEvent) => {
      const { offsetX, offsetY, deltaY, metaKey, ctrlKey } = evt.nativeEvent;

      if (frameInfos.length == 0) return;

      if (isTextEditor.current) return;

      if (metaKey || ctrlKey || activeTool === EditorTool.Select) {
        const isZoom = deltaY < 0;
        const dScale = canvasStatusInfo.scale * 0.1 * (isZoom ? 1 : -1);

        const widthRate =
          (offsetX - canvasStatusInfo.x) /
          (1280 * (canvasStatusInfo.scale * 0.01));
        const heightRate =
          (offsetY - canvasStatusInfo.y) /
          (720 * (canvasStatusInfo.scale * 0.01));

        const dx = 1280 * (Math.abs(dScale) * 0.01) * widthRate;
        const dy = 720 * (Math.abs(dScale) * 0.01) * heightRate;

        const scale = clamp(canvasStatusInfo.scale + dScale, 10, 3200);

        setCanvasStatusInfo({
          x: canvasStatusInfo.x + (isZoom ? -dx : dx),
          y: canvasStatusInfo.y + (isZoom ? -dy : dy),
          scale,
        });
      }
    },
    [activeTool, canvasStatusInfo, setCanvasStatusInfo]
  );

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      if (!canvasRef.current || !ctxRef.current) return;
      if (event.key === 'Escape') {
        if (activeTool === EditorTool.PolyLine) {
          if (polylineRef.current.length > 1) {
            const boundingRect = getBoundingRect(polylineRef.current);

            createElement(
              getElementTemplate(activeTool, boundingRect, [
                ...polylineRef.current,
              ])
            );
          }
          polylineRef.current = [];
        } else if (activeTool === EditorTool.Text) {
          const textEditorWrapper = document.getElementById('textEditor');

          if (textEditorWrapper) {
            textEditorWrapper.innerHTML = '';
            isTextEditor.current = false;
          }
        }

        clearCanvas(canvasRef.current, ctxRef.current);

        isPointerDown.current = false;
        isCropSelect.current = false;
        selectedHoveredPointInfo.current = null;
        downedMousePointer.current = null;
        pointsRef.current = [];
      } else if (event.key === 'c' && (event.metaKey || event.ctrlKey)) {
        if (isTextEditor.current) return;

        copiedElements.current = selectedElements;
      } else if (event.key === 'v' && (event.metaKey || event.ctrlKey)) {
        if (isTextEditor.current) return;

        if (copiedElements.current.length > 0) {
          const sourceElements = cloneDeep(copiedElements.current);
          const pastElements = sourceElements.map((element) => {
            while (true) {
              const existSamePosition = frameInfos[frameIndex].elements.some(
                (el) => el.x === element.x && el.y === element.y
              );
              if (existSamePosition) {
                element.x += 30;
                element.y += 30;

                if (
                  element.type === EditorTool.FreeDraw ||
                  element.type === EditorTool.PolyLine
                ) {
                  element.points.forEach((el) => {
                    el[0] += 30;
                    el[1] += 30;
                  });
                }
              } else {
                break;
              }
            }

            return {
              ...element,
              id: generateUUID(),
            };
          });

          createElements(pastElements);
        }
      } else if (event.key === 'z') {
        if ((event.metaKey || event.ctrlKey) && event.shiftKey) {
          FrameInfoManager.redo();
        } else if (event.metaKey || event.ctrlKey) {
          FrameInfoManager.undo();
        }
        const frameInfo = FrameInfoManager.getFrameInfo();

        updateElements(frameInfo.elements);
      }
    },
    [activeTool, frameInfos, frameIndex, selectedElements]
  );

  useKeyEvent('Escape', handleKeyEvent);
  useKeyEvent('c', handleKeyEvent);
  useKeyEvent('v', handleKeyEvent);
  useKeyEvent('z', handleKeyEvent);

  useEffect(() => {
    if (canvasRef.current) {
      ctxRef.current = canvasRef.current.getContext('2d');
    }
  }, []);

  useEffect(() => {
    if (ctxRef.current) {
      ctxRef.current.setTransform(
        canvasStatusInfo.scale * 0.01,
        0,
        0,
        canvasStatusInfo.scale * 0.01,
        canvasStatusInfo.x,
        canvasStatusInfo.y
      );
    }
  }, [canvasStatusInfo]);

  useEffect(() => {
    setSelectedElements([]);
    FrameInfoManager.init(frameInfos[frameIndex]);
  }, [frameIndex]);

  useCanvasWindowResizeEvent({
    canvas: canvasRef.current,
    ctx: ctxRef.current,
    canvasStatusInfo,
  });

  const handlePointerLeave = useCallback(() => {
    if (!canvasRef.current || !ctxRef.current) return;

    clearCanvas(canvasRef.current, ctxRef.current);

    isPointerDown.current = false;
    isCropSelect.current = false;
    selectedHoveredPointInfo.current = null;
    downedMousePointer.current = null;
    pointsRef.current = [];
  }, []);

  const handleMouseDown = useCallback((evt: MouseEvent) => {
    const { offsetX, offsetY } = evt.nativeEvent;

    _downedMousePointer.current = [offsetX, offsetY];
  }, []);

  const handleMouseMove = useCallback(
    (evt: MouseEvent) => {
      const { offsetX, offsetY, altKey } = evt.nativeEvent;

      if (!altKey && activeTool !== EditorTool.Select) return;
      if (!isPointerDown.current) return;
      if (selectedElements.length === 1 && selectedHoveredPointInfo.current)
        return;
      if (selectedElements.length > 0 && !altKey) return;

      if (_downedMousePointer.current) {
        const [dx, dy] = getDiff(_downedMousePointer.current, [
          offsetX,
          offsetY,
        ]);

        setCanvasStatusInfo({
          ...canvasStatusInfo,
          x: canvasStatusInfo.x + dx,
          y: canvasStatusInfo.y + dy,
        });
        _downedMousePointer.current = [offsetX, offsetY];
      }
    },
    [activeTool, canvasStatusInfo]
  );

  const handleMouseUp = useCallback(() => {}, []);

  const handleTouchStart = useCallback((evt: TouchEvent) => {
    const { touches } = evt.nativeEvent;

    if (touches.length === 2) {
      _touchedMousePointer.current = [
        mean([touches.item(0)?.clientX, touches.item(1)?.clientX]),
        mean([touches.item(0)?.clientY, touches.item(1)?.clientY]),
      ];
    }
  }, []);

  const handleTouchMove = useCallback(
    (evt: TouchEvent) => {
      const { touches } = evt.nativeEvent;

      if (touches.length === 2) {
        if (_touchedMousePointer.current) {
          const [dx, dy] = getDiff(_touchedMousePointer.current, [
            mean([touches.item(0)?.clientX, touches.item(1)?.clientX]),
            mean([touches.item(0)?.clientY, touches.item(1)?.clientY]),
          ]);

          setCanvasStatusInfo({
            ...canvasStatusInfo,
            x: canvasStatusInfo.x + dx,
            y: canvasStatusInfo.y + dy,
          });

          _touchedMousePointer.current = [
            mean([touches.item(0)?.clientX, touches.item(1)?.clientX]),
            mean([touches.item(0)?.clientY, touches.item(1)?.clientY]),
          ];
        }
      }
    },
    [canvasStatusInfo]
  );

  const handleTouchUp = useCallback(() => {}, []);

  return (
    <>
      <canvas
        ref={canvasRef}
        className='main'
        width={window.innerWidth}
        height={window.innerHeight}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onPointerLeave={handlePointerLeave}
        onWheel={handleWheel}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchUp}
      />
      <div
        id='textEditor'
        style={{
          position: 'absolute',
          background: 'transparent',
        }}
      />
    </>
  );
};

export default InteractiveCanvas;
