import React from 'react';

interface Props {
  children: React.ReactNode; // 자식 요소의 타입 지정
}

interface State {
  hasError: boolean; // 오류 발생 여부
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // 오류가 발생했을 때 호출
  static getDerivedStateFromError(error: Error): State {
    console.log(error);
    // 다음 렌더링에서 fallback UI를 표시하도록 state 업데이트
    return { hasError: true };
  }

  // 오류 정보를 로그에 기록
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
    // 에러를 로깅 서비스에 전송할 수 있습니다.
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI를 표시
      return <h1>죄송합니다. 문제가 발생했습니다.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
